var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "situation-random" },
    [
      _c("ButtonBack"),
      _vm._v(" "),
      _c("div", { staticClass: "situation-perso__header" }, [
        _c("p", [_vm._v(_vm._s(_vm._("Comparaison des couleurs")))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "situation-random-wrapper" }, [
        _c("div", { ref: "canvasContainer", staticClass: "canvas-container" }, [
          _c("canvas", { ref: "situationAutoCanvas" }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "palette-wrapper" },
          [
            _c(
              "router-link",
              {
                staticClass: "action btn-border",
                attrs: { to: { name: "color_menu" } },
              },
              [
                _c("MIconCamera48", { staticClass: "mc-button__icon" }),
                _vm._v(" "),
                _c("span", { staticClass: "mc-button__label" }, [
                  _vm._v(_vm._s(_vm._("Tester sur une autre image"))),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("color-palette", { attrs: { "open-by-default": "" } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }