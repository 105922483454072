<template>
    <div class="main">
        <div class="admin">
            <router-view />
        </div>
    </div>
</template>

<script>
import self from "../../../index";

const {
    modules,
} = self.app;

export default {
    name: "Admin",

    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
    },
};
</script>
