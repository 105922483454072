<template>
    <div>
        <h2>{{ _("Modification de l'image") }} {{ picture.title }} - {{ _(PICTURE_ATMOSPHERES[picture.atmosphere]) }}</h2>

        <div class="input-group">
            <label for="room-selector">
                {{ _("Catégorie de la pièce") }}
            </label>
            <v-select
                id="room-selector"
                :options="categoriesArray"
                :value="category"
                :reduce="category => category.value"
                label="label"
                @input="updateCategory($event)"
            />
        </div>

        <div class="input-group">
            <label for="atmosphere-selector">
                {{ _("Ambiance de la pièce") }}
            </label>
            <v-select
                id="atmosphere-selector"
                :options="atmospheresArray"
                :value="picture.atmosphere"
                :reduce="atmoshpere => atmoshpere.value"
                label="label"
                @input="updateAtmosphere($event)"
            />
        </div>
        <h4>{{ _("Couleurs associées") }}</h4>
        <div
            v-for="(color, index) in pictureColors"
            :key="index"
            class="input-group"
        >
            <label
                for="colors-selector"
                class="color-label"
            >

                <span
                    v-if="color.rgbhex"
                    class="color-preview"
                    :style="`background-color: ${color.rgbhex}`"
                />
                <span>{{ _(`Couleur ${index + 1}`) }}</span>
            </label>
            <v-select
                id="color-selector"
                :options="colors"
                :value="`${color.name || ''}`"
                class="color-selector"
                label="name"
                @input="updateColor($event, index)"
            >
                <template #option="color">
                    <div class="color-option">
                        <span
                            class="color-preview"
                            :style="`background-color: ${color.rgbhex}`"
                        />
                        <span style="color: black;">{{ color.name }}</span>
                    </div>
                </template>
            </v-select>
        </div>
    </div>
</template>

<script>
import self from "../../../index";
import {
    PICTURE_CATEGORIES,
    PICTURE_ATMOSPHERES,
} from "../../helpers/constants";

const {
    modules,
} = self.app;

export default {
    name: "PictureEdit",
    props: {
        picture: {
            type: Object,
            required: false,
            default: () => {},
        },
        category: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            PICTURE_CATEGORIES,
            PICTURE_ATMOSPHERES,
        };
    },
    computed: {
        colors() {
            const colors = this.$store.getters["colors/colors"];
            return Object.keys(colors).map((key) => {
                const color = { ...colors[key], id: key };
                return color;
            });
        },
        pictureColors() {
            return ["", "", "", ""].map((baseArray, index) => this.picture.colors[index] || "")
                .map((pictureColor) => {
                    const foundColor = this.colors.find((color) => color.id === pictureColor);
                    return foundColor || {};
                });
        },
        categoriesArray() {
            return Object.entries(this.PICTURE_CATEGORIES).map(([value, label]) => ({ label: this._(label), value }));
        },
        atmospheresArray() {
            return Object.entries(this.PICTURE_ATMOSPHERES).map(([value, label]) => ({ label: this._(label), value }));
        },
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        updateCategory(value) {
            this.$emit("update-picture-category", { picture: this.picture, categoryName: value });
        },
        updateAtmosphere(value) {
            this.$emit("update-picture", { ...this.picture, atmosphere: value });
        },
        updateColor(color, colorIndex) {
            const colors = [...this.picture.colors];
            if (color) {
                colors[colorIndex] = color.id;
            } else {
                colors.splice(colorIndex, 1);
            }
            this.$emit("update-picture", { ...this.picture, colors });
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.input-group {
  margin-bottom: $mu200;

  label {
    margin-bottom: $mu050;
    display: flex;
  }
}

.color-option,
.color-label {
  display: flex;
  align-items: center;

  span {
    &:first-child {
      margin-right: $mu100;
    }
  }

  .color-preview {
    margin-right: $mu100;
    display: block;
    width: 60px;
    height: 30px;
    border-radius: 6px;
    border: solid 1px white;
    background-color: red;
  }
}

.color-option {
  .color-preview {
    margin-right: 5px;
  }
}
</style>
