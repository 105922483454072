<template>
    <m-modal
        :open="isMailModalOpen"
        modal-title=""
        class="share-modal"
        @update:open="closeMailModal"
    >
        <template>
            <button
                class="btn-modal__close"
                @click="closeMailModal"
            >
                <MIconControlCross24 />
            </button>
            <h1 class="font-size--06">
                {{ _("Envoyer par mail") }}
            </h1>

            <div
                class="form"
                @keypress.enter="shareMail"
            >
                <m-text-input
                    id="name-input"
                    v-model="name"
                    label=""
                    v-bind:placeholder="_('votre prénom')"
                    required
                    :is-invalid="isNameInvalid"
                    :is-valid="isNameValid"
                    type="text"
                    @input="onNameInput"
                />

                <m-text-input
                    id="email-input"
                    v-model="email"
                    label=""
                    v-bind:placeholder="_('nom.prénom@mail.com')"
                    required
                    :is-invalid="isEmailInvalid"
                    :is-valid="isEmailValid"
                    type="email"
                    @input="onEmailInput"
                />
            </div>
        </template>

        <template #footer>
            <div class="btn-group">
                <m-button
                    :disabled="!isValid"
                    :label="_('Envoyer')"
                    @click="shareMail"
                />
            </div>
        </template>
    </m-modal>
</template>

<script>
import { MIconControlCross24 } from "@mozaic-ds/icons/vue/index.vue";
import EventBus from "../../helpers/event-bus";
import self from "../../../index";

const {
    modules,
} = self.app;

export default {
    name: "ModalMail",
    components: {
        MIconControlCross24,
    },
    data() {
        return {
            email: "",
            name: "",
            isMailModalOpen: false,
            isEmailInvalid: false,
            isEmailValid: false,
            isNameInvalid: false,
            isNameValid: false,
            mode: "",
        };
    },
    computed: {
        checkEmailValid() {
            // eslint-disable-next-line
            const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const regex = RegExp(pattern);

            return regex.test(this.email);
        },
        checkNameValid() {
            return this.name.length > 0 && this.name.length <= 50;
        },
        isValid() {
            return this.checkEmailValid && this.checkNameValid;
        },
    },
    mounted() {
        EventBus.$on("open-modal-mail", this.openMailModal);
    },
    beforeDestroy() {
        EventBus.$off("open-modal-mail", this.openMailModal);
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        openMailModal(mode) {
            this.isMailModalOpen = true;
            this.mode = mode;
        },
        closeMailModal() {
            this.isMailModalOpen = false;
            this.mode = "";
            EventBus.$emit("close-modal-mail");
        },
        onEmailInput() {
            this.isEmailInvalid = !this.checkEmailValid;
            this.isEmailValid = !this.isEmailInvalid;
        },
        onNameInput() {
            this.isNameInvalid = !this.checkNameValid;
            this.isNameValid = !this.isNameInvalid;
        },
        shareMail() {
            if (!this.isValid) { return; }

            if (this.mode === "SHARE_PICTURE") {
                EventBus.$emit("share-link-mail", (url, id) => {
                    this.sendMail({
                        id,
                        type: "SHARE_PICTURE",
                    });
                });
            } else if (this.mode === "SHARE_PALETTE") {
                EventBus.$emit("share-link-palette", (url, fullPath) => {
                    this.sendMail({
                        link: fullPath,
                        type: "SHARE_PALETTE",
                    });
                });
            } else if (this.mode === "SHARE_COLOR") {
                this.sendMail({
                    link: this.$route.fullPath,
                    type: "SHARE_COLOR",
                });
            }
        },
        sendMail(payload) {
            const locale = modules.stonejs.getLocale();
            fetch(`/mail?locale=${locale}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(({
                    ...payload,
                    to: this.email,
                    name: this.name,
                })),
            })
                .then((res) => {
                    if (res.status === 200) {
                        const messageSuccess = {
                            title: this._("Envoyé"),
                            text: this._("Le mail a bien été envoyé"),
                            status: "success",
                            type: "MAIL",
                        };
                        EventBus.$emit("toaster-message", messageSuccess);

                        this.closeMailModal();
                    } else {
                        throw new Error();
                    }
                })
                .catch(() => {
                    const messageError = {
                        title: this._("Erreur"),
                        text: this._("Impossible d'envoyer le mail"),
                        status: "error",
                        type: "ERROR",
                    };
                    EventBus.$emit("toaster-message", messageError);
                    this.closeMailModal();
                });
        },
    },
};

</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.btn-modal__close {
  width: 40px;
  height: 40px;
  background: white;
  position: absolute;
  right: 0;
  top: -50px;
  border: none;
  display:none;

  @include set-border-radius('m');

  &:hover {
    cursor: pointer;
  }
}

.btn-group {
  display: flex;
  grid-column-gap: $mu100;
  margin: 0;
}

.share-modal {
  .mc-modal__header {
    display: none;
  }

  .mc-modal__dialog {
    padding: $mu100;

    #email-input {
      margin-top: $mu100;
    }
  }
}

h1 {
  text-align: center;
}
</style>
