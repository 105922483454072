var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.messages.length
      ? _c(
          "div",
          { staticClass: "toaster" },
          _vm._l(_vm.messages, function (message, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "toaster__item",
                class: message.status,
              },
              [
                _c(
                  "div",
                  { staticClass: "toaster__icon" },
                  [
                    message.type === "LINK"
                      ? _c("MIconDisplayExternalLink48")
                      : _vm._e(),
                    _vm._v(" "),
                    message.type === "MAIL"
                      ? _c("MIconContactMail48")
                      : _vm._e(),
                    _vm._v(" "),
                    message.type === "ERROR"
                      ? _c("MIconControlCircleCross48")
                      : _vm._e(),
                    _vm._v(" "),
                    message.type === "INFO"
                      ? _c("MIconNotificationWarning48")
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "h3",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: message.title,
                          expression: "message.title",
                        },
                      ],
                      staticClass: "toaster__title",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(message.title) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(message.text) +
                        "\n                "
                    ),
                  ]),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }