const threadify = require("threadify");

/**
 * The worker part of the paintPicture function.
 *
 * @private
 *
 * @param {ImageData} photoData - The photo to paint
 * @param {ImageData} maskData - The paint mask
 * @param {String[]} colorList - The colors to paint, as hexadecimal RGB strings
 * @param {Number} brightnessAdjustment - Adjustement if the brightness (from -10 to 10, default: 0)
 *
 * @return {ImageData} - The painted picture
 */
const _paintPictureWorker = threadify(
    // eslint-disable-next-line prefer-arrow-callback
    function _paintPictureWorker(photoData, maskData, colorList, preComputedAverage, brightnessAdjustment = 0) {

        function rgb2lab(rgb) {
            let r = rgb[0] / 255;
            let g = rgb[1] / 255;
            let b = rgb[2] / 255;
            let x;
            let y;
            let z;

            r = (r > 0.04045) ? ((r + 0.055) / 1.055) ** 2.4 : r / 12.92;
            g = (g > 0.04045) ? ((g + 0.055) / 1.055) ** 2.4 : g / 12.92;
            b = (b > 0.04045) ? ((b + 0.055) / 1.055) ** 2.4 : b / 12.92;

            x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
            y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
            z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

            x = (x > 0.008856) ? x ** (1 / 3) : (7.787 * x) + 16 / 116;
            y = (y > 0.008856) ? y ** (1 / 3) : (7.787 * y) + 16 / 116;
            z = (z > 0.008856) ? z ** (1 / 3) : (7.787 * z) + 16 / 116;

            return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)];
        }

        function lab2rgb(lab) {
            let y = (lab[0] + 16) / 116;
            let x = lab[1] / 500 + y;
            let z = y - lab[2] / 200;
            let r;
            let g;
            let b;

            x = 0.95047 * ((x * x * x > 0.008856) ? x * x * x : (x - 16 / 116) / 7.787);
            y = 1.00000 * ((y * y * y > 0.008856) ? y * y * y : (y - 16 / 116) / 7.787);
            z = 1.08883 * ((z * z * z > 0.008856) ? z * z * z : (z - 16 / 116) / 7.787);

            r = x * 3.2406 + y * -1.5372 + z * -0.4986;
            g = x * -0.9689 + y * 1.8758 + z * 0.0415;
            b = x * 0.0557 + y * -0.2040 + z * 1.0570;

            r = (r > 0.0031308) ? (1.055 * (r ** (1 / 2.4)) - 0.055) : 12.92 * r;
            g = (g > 0.0031308) ? (1.055 * (g ** (1 / 2.4)) - 0.055) : 12.92 * g;
            b = (b > 0.0031308) ? (1.055 * (b ** (1 / 2.4)) - 0.055) : 12.92 * b;

            return [Math.max(0, Math.min(1, r)) * 255,
                Math.max(0, Math.min(1, g)) * 255,
                Math.max(0, Math.min(1, b)) * 255,
            ];
        }

        function hex2lab(hexadecimalRGBString) {
            const r = parseInt(hexadecimalRGBString.substring(1, 3), 16);
            const g = parseInt(hexadecimalRGBString.substring(3, 5), 16);
            const b = parseInt(hexadecimalRGBString.substring(5, 7), 16);
            const labColor = rgb2lab([r, g, b]);
            const labObject = {
                L: labColor[0],
                a: labColor[1],
                b: labColor[2],
            };
            return labObject;
        }

        function correctionFactors(min, max, med, target) {
            let lFactor = 1;
            let rFactor = 1;

            if (min - med + target < 0) {
                lFactor = target / (med - min);
            } else if (max - med + target > 100) {
                rFactor = (100 - target) / (max - med);
            }

            return { lFactor: lFactor, rFactor: rFactor };
        }

        function correction(base, target, med, factors) {
            // const applyPercent = 1;
            // const corrected = base + ((target - base) * applyPercent);
            let corrected = target;

            if (base < med) {
                corrected += (base - med) * factors.lFactor;
            } else if (base > med) {
                corrected += (base - med) * factors.rFactor;
            }
            return corrected;
        }

        const photoDataLab = [];
        for (let i = 0, c = photoData.data.length; i < c; i += 4) {
            photoDataLab.push(...(rgb2lab([photoData.data[i], photoData.data[i + 1], photoData.data[i + 2]])));
        }

        let colorListLab = colorList.map((hexadecimalRGBString) => hex2lab(hexadecimalRGBString));
        colorListLab = colorListLab.map((v) => ({ L: Math.max(0, Math.min(100, Math.round(v.L + brightnessAdjustment))), a: v.a, b: v.b }));

        // Isolate L channel
        const photoDataLChannel = [];
        for (let i = 0, c = photoDataLab.length; i < c; i += 3) {
            photoDataLChannel.push(photoDataLab[i]);
        }

        // Min and max of x (so splitting for different colors is easy)
        const w = maskData.width;
        const h = maskData.height;

        let minX = w;
        let minY = h;
        let maxX = 0;
        let maxY = 0;

        for (let i = 0, c = maskData.data.length; i < c; i += 4) {
            if (maskData.data[i + 3] !== 0) {
                minX = Math.min(minX, (i / 4) % w);
                maxX = Math.max(maxX, (i / 4) % w);
                minY = Math.min(minY, Math.floor((i / 4) / w));
                maxY = Math.max(maxY, Math.floor((i / 4) / w));
            }
        }

        maxX += 1;
        maxY += 1;

        // Median, min and max
        let medL;
        if (preComputedAverage !== null) {
            medL = preComputedAverage;
        } else {
            // in case of user picture the idea is to get main luminosity on wall :
            // - first idea was to compute luminosity on detected part (zone clicked by user) but it would result in luminosity change
            // after each click which looks pretty confusing for user who wants to have a precise idea of how color will render at home.
            // - second idea was to compute median or average on whole picture but then you are dependent on the whole picture luminosity
            // (including part which are not painted) this approach was implemented and result was clamped (when paint is really light
            // or really black we had side effects). Results were acceptable but not so great mainly on dark colors.
            // Finally we used a far more simple approach which is approximating that user will always
            // paint walls with light grey luminosity (dont forget picture is converted to l*a*b format before)
            // Also those constraints are explained for user in guidelines part
            medL = 80; // 80 represent a light grey luminosity, 0 would be painting on black wall, 100 on full white
        }
        let minL = 100;
        let maxL = 0;

        for (let i = 0, c = photoDataLChannel.length; i < c; i++) {
            if (maskData.data[(i * 4) + 3] !== 0) {
                minL = Math.min(minL, photoDataLChannel[i]);
                maxL = Math.max(maxL, photoDataLChannel[i]);
            }
        }
        if (minL === 100 && maxL === 0) {
            // case mask is on all picture
            minL = 0;
            maxL = 100;
        }

        // Apply correction
        const n = colorListLab.length;
        for (let colorIndex = 0; colorIndex < n; colorIndex++) {

            const colorLab = colorListLab[colorIndex];
            const factorsL = correctionFactors(minL, maxL, medL, colorLab.L);

            const startX = Math.floor(minX + (colorIndex * (maxX - minX)) / n);
            const endX = Math.floor(minX + ((colorIndex + 1) * (maxX - minX)) / n);

            for (let x = startX; x < endX; x++) {
                for (let y = minY; y < maxY; y++) {
                    const pixelIndex = y * w + x;
                    if (maskData.data[4 * pixelIndex + 3] > 100) {
                        photoDataLab[3 * pixelIndex + 0] = correction(photoDataLChannel[pixelIndex], colorLab.L, medL, factorsL);
                        photoDataLab[3 * pixelIndex + 1] = colorLab.a;
                        photoDataLab[3 * pixelIndex + 2] = colorLab.b;
                    }
                }
            }
        }

        const paintedPictureData = new ImageData(photoData.width, photoData.height);

        // Reconvert to RGB
        /* eslint-disable prefer-destructuring */
        for (let i = 0, index = 0, c = photoDataLab.length; i < c; index++, i += 3) {
            const rgb = lab2rgb([photoDataLab[i], photoDataLab[i + 1], photoDataLab[i + 2]]);
            paintedPictureData.data[index * 4] = rgb[0];
            paintedPictureData.data[index * 4 + 1] = rgb[1];
            paintedPictureData.data[index * 4 + 2] = rgb[2];
            paintedPictureData.data[index * 4 + 3] = 255;
        }
        /* eslint-enable prefer-destructuring */

        return paintedPictureData;
    }
);

/**
 * This function paint an area on a canvas with one or multiple colors according to a mask
 *
 * @param {HTMLCanvasElement} photo - The photo to paint
 * @param {HTMLCanvasElement} mask - The paint mask
 * @param {String[]} colorList - The colors to paint, as hexadecimal RGB strings
 * @param {Number} brightnessAdjustment - Adjustement if the brightness (from -10 to 10, default: 0)
 * @return {Promise<HTMLCanvasElement>} - The painted picture
 */
function paintPicture(photo, mask, colorList, preComputedAverage = null, brightnessAdjustment = 0) {
    return new Promise((resolve, reject) => {
        const photoData = photo.getContext("2d").getImageData(0, 0, photo.width, photo.height);
        const maskData = mask.getContext("2d").getImageData(0, 0, mask.width, mask.height);

        const job = _paintPictureWorker(photoData, maskData, colorList, preComputedAverage, brightnessAdjustment);

        job.done = (paintedPhotoData) => {
            const paintedPhotoCanvas = document.createElement("canvas");
            paintedPhotoCanvas.width = photo.width;
            paintedPhotoCanvas.height = photo.height;
            paintedPhotoCanvas.getContext("2d").putImageData(paintedPhotoData, 0, 0);
            resolve(paintedPhotoCanvas);
        };

        job.failed = reject;
    }).catch((error) => {
        console.log(error);
    });
}

export default paintPicture;
