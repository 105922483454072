var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "home", attrs: { id: "app" } }, [
        _c("div", { staticClass: "home-title" }, [
          _c("h1", [
            _vm._v(
              _vm._s(
                _vm._(
                  "Projetez-vous dans votre choix de peinture couleur en quelques clics"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(
                _vm._("Pour démarrer, choisissez une des options ci-dessous")
              )
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-wrapper" }, [
          _c(
            "div",
            [
              _c("MainCard", {
                attrs: {
                  title: _vm._("A partir du nuancier de couleurs"),
                  description: _vm._(
                    "Explorez notre nuancier de peinture couleur et visualisez le rendu des teintes de votre choix."
                  ),
                  "data-cerberus": "from-palette",
                  "cover-webp":
                    "assets/images/accueil/mon_simulateur_couleur_nuancier.webp",
                  "cover-img":
                    "assets/images/accueil/mon_simulateur_couleur_nuancier.jpg",
                  link: "color_chart",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("MainCard", {
                attrs: {
                  title: _vm._("A partir d'une photo"),
                  description: _vm._(
                    "Prenez une photo de votre pièce ou choisissez une photo de notre galerie et testez le rendu de vos peintures couleurs préférées."
                  ),
                  "data-cerberus": "from-picture",
                  "cover-webp":
                    "assets/images/accueil/mon_simulateur_couleur_photo.webp",
                  "cover-img":
                    "assets/images/accueil/mon_simulateur_couleur_photo.jpg",
                  link: "color_menu",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("Footer", { attrs: { fixed: "" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }