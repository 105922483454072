var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.containerClass }, [
    _c("div", { staticClass: "link-container" }, [
      _c(
        "button",
        { staticClass: "simple-link", on: { click: _vm.onShowMoreClick } },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm._("En savoir plus sur l'aspect")) +
              "\n        "
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { class: ["aspect-info", { open: _vm.show }] },
      [
        _vm._l(_vm.getColorAspects(), function (aspect) {
          return _c("p", { key: aspect.name }, [
            _vm._v(
              "\n            " +
                _vm._s(aspect.name) +
                " : " +
                _vm._s(aspect.description) +
                "\n        "
            ),
          ])
        }),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn-close", on: { click: _vm.close } },
          [_c("MIconControlCross24")],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }