<template>
    <div class="color-suggestion">
        <div class="color-suggestion-wrapper">
            <div class="color-section">
                <h2 class="font-size--06 mu-mb-100">
                    {{ _("Couleurs suggérées") }}
                </h2>
                <div class="color-similar color-suggested">
                    <color-card
                        v-for="(item, index) in colorsObject"
                        :key="index"
                        class="color-similar__item color-suggested__item"
                        :uid="item"
                        :color="colors[item]"
                        can-interact
                    />
                </div>
            </div>
            <button
                class="close-btn"
                @click="closeModal"
            >
                <MIconControlCross32 color="#3c3738" />
            </button>
        </div>
    </div>
</template>

<script>
import { MIconControlCross32 } from "@mozaic-ds/icons/vue/index.vue";
import self from "../../../index";
import ColorCard from "../color-card/color-card.vue";

const {
    modules,
} = self.app;

export default {
    name: "ColorSuggestion",
    components: {
        ColorCard,
        MIconControlCross32,
    },
    props: {
        colorsObject: {
            type: Array,
            default: (() => []),
        },
    },
    computed: {
        colors() {
            return this.$store.getters["colors/colors"];
        },
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        closeModal() {
            this.$emit("close-modal");
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/Base/font.scss";
@import "../../../../../style/index.scss";

.color-suggestion {
  transition: 0.3s;
  border-radius: 4px;
  overflow: hidden;
  position: absolute;
  opacity: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $mu100;
  width: 100%;
  background: rgba(black, 0.6);
  transform: scale(0);
  transform-origin: center;
  left: 0;
  top: 0;
  height: 100vh;

  &.open {
    opacity: 1;
    transform: scale(1);
    padding: $mu100;
  }

  &-wrapper {
    transition: 0.3s;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(black, 0.2);
    background: white;
    padding: $mu100;
    width: 100%;
    position: relative;

    @include set-from-screen(m) {
      max-width: $palette-width;
      width: $palette-width;
    }
  }

  .color-section {
    &.divider {
      border-bottom: solid 1px $color-grey-200;
      margin-bottom: $mu150;
    }

    .color-similar {
      display: flex;
      flex-wrap: wrap;
      grid-column-gap: $mu100;

      &__item {
        flex: 1;
        margin-bottom: $mu050;
        padding: 0;
        min-width: 50px;

        @include set-from-screen(m) {
          max-width: 50%;
          min-width: 90px;
        }

        p {
          margin-top: $mu025;
        }

        .color {
          padding: $mu050;
        }
      }
    }

    .color-section:first-child {
      margin-bottom: $mu100;
    }
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(calc(-100% - #{$mu050}));
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 4px;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
