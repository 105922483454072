<template>
    <div class="situation-random">
        <ButtonBack />
        <div class="situation-perso__header">
            <p>{{ _("Comparaison des couleurs") }}</p>
        </div>
        <div class="situation-random-wrapper">
            <div
                ref="canvasContainer"
                class="canvas-container"
            >
                <canvas ref="situationAutoCanvas" />
            </div>
            <div class="palette-wrapper">
                <router-link
                    class="action btn-border"
                    :to="{ name: 'color_menu' }"
                >
                    <MIconCamera48 class="mc-button__icon" />

                    <span class="mc-button__label">{{ _("Tester sur une autre image") }}</span>
                </router-link>

                <color-palette open-by-default />
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import pica from "pica";
import { MIconCamera48 } from "@mozaic-ds/icons/vue/index.vue";
import ButtonBack from "../../components/button-back/button-back.vue";
import ColorPalette from "../../components/color-palette/color-palette.vue";
import self from "../../../index";
import { MOBILE_WIDTH } from "../../helpers/constants";

const {
    stonejs,
    paintinglib,
} = self.app.modules;

export default {
    name: "SituationRandom",
    components: {
        ButtonBack,
        ColorPalette,
        MIconCamera48,
    },
    computed: {
        image() {
            return "../../../../assets/images/comparaison/comparaison.jpg";
        },
        mask() {
            return "../../../../assets/images/comparaison/comparaison-masque.png";
        },
        colors() {
            return this.$store.getters["colors/colors"];
        },
        selectedColors() {
            return this.$store.getters["cartData/colors"];
        },
        isMobile() {
            return window.screen.availWidth <= MOBILE_WIDTH;
        },
    },
    watch: {
        selectedColors() {
            this.initCanvas();
        },
    },
    mounted() {
        this.initCanvas();
    },
    methods: {
        _(...args) {
            return stonejs.gettext(...args);
        },
        initCanvas() {
            const image = new Image();
            image.src = this.image;
            image.onload = () => {
                const imageCanvas = document.createElement("canvas");
                imageCanvas.width = image.width;
                imageCanvas.height = image.height;
                const imageCanvasCtx = imageCanvas.getContext("2d");
                imageCanvasCtx.drawImage(image, 0, 0, imageCanvas.width, imageCanvas.height);

                const canvasHandler = this.$refs.canvasContainer;
                const canvas = this.$refs.situationAutoCanvas;
                const maxHeight = canvasHandler.height;
                let canvasHeight = image.height;
                let canvasWidth = image.width;

                if (canvasHeight > maxHeight) {
                    const aspectRatio = (canvasWidth / canvasHeight);
                    canvasWidth = maxHeight * aspectRatio;
                    canvasHeight = maxHeight;
                }
                if (canvasWidth > canvasHandler.clientWidth) {
                    const aspectRatio = (canvasHeight / canvasWidth);
                    canvasWidth = canvasHandler.clientWidth;
                    canvasHeight = canvasHandler.clientWidth * aspectRatio;
                    canvasHandler.height = canvasHeight;
                }

                if (this.isMobile) {
                    canvas.width = canvasWidth * 2;
                    canvas.height = canvasHeight * 2;
                    canvas.style.width = `${canvasWidth}px`;
                    canvas.style.height = `${canvasHeight}px`;
                } else {
                    canvas.width = canvasWidth;
                    canvas.height = canvasHeight;
                }

                pica().resize(imageCanvas, canvas, {
                    quality: 3,
                    alpha: true,
                    unsharpAmount: 60,
                    unsharpRadius: 1.5,
                    unsharpThreshold: 170,
                    transferable: true,
                })
                    .then(() => {
                        this.colorize();
                    });
            };
        },
        colorize() {
            const canvas = this.$refs.situationAutoCanvas;
            const colors = this.selectedColors.map((uid) => this.colors[uid].rgbhex);

            return paintinglib.helpers.loadImage(this.mask)
                .then((image) => paintinglib.helpers.imageToCanvas(image, canvas.width, canvas.height))
                .then((maskCanvas) => paintinglib.paintPicture(
                    canvas,
                    maskCanvas,
                    colors
                ))
                .then((paintedPictureCanvas) => paintinglib.helpers.copyCanvas(
                    paintedPictureCanvas,
                    canvas
                ));
        },
    },
};

</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.situation-random {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - #{$nav-height});

  @media screen and (min-width: $medium-break-point) {
    height: calc(100vh - #{$nav-height});
  }

  @include set-from-screen(l-medium) {
    height: calc(100vh - #{$nav-height-desktop});
  }

  &-wrapper {
    overflow-y: auto;
    overflow-x: hidden;

    @include set-from-screen(xl) {
      height: calc(100vh - #{$nav-height-desktop + $nav-height * 2} - #{$footer-height});
      width: 100vw;
      display: flex;
      overflow-y: auto;

      & > * {
        &:first-child {
          width: auto;
          flex: 1;
        }

        &:nth-child(2) {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative !important;
          justify-content: flex-end;
          width: $palette-container-width;

          .cart-data {
            margin: 0;
            width: $palette-width;
          }
        }

        &:nth-child(3) {
          width: 100%;
        }
      }
    }

    .canvas-container {
      display: flex;
      padding: 0 $mu100;
      justify-content: center;
      overflow: hidden;

      canvas {
        width: 100%;
        height: max-content;

        @include set-from-screen(l) {
          width: initial;
          height: max-content;
        }

        @include set-from-screen(xl) {
          width: initial;
          height: auto;
        }

        @include set-from-screen(xl-large) {
          height: auto;
          width: auto;
        }
      }
    }

    .palette-wrapper {
      padding: $mu100;

      .btn-border {
        &.action {
          transition: 0.3s;

          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }

        width: 100%;
        max-width: 250px;
        background: white;
        color: $color-grey-900;
        border: solid 1px $color-grey-900;
        text-decoration: none;
        font-size: 14px;
        display: flex;
        align-items: center;
        position: relative;
        padding: $mu025 $mu050 $mu025 $mu300;
        text-align: left;
        margin: 0 auto $mu100;

        @include set-border-radius('m');
        @include set-font-weight('regular');

        svg {
          width: 20px;
          height: 20px;
          position: absolute;
          left: $mu100;
        }

        @include set-from-screen(m) {
          font-size: $mu100;
          padding: $mu100;
          width: 50%;
          max-width: 300px;
          margin: 0 auto $mu200;

          svg {
            width: 30px;
            height: 30px;
            position: relative;
            left: 0;
            margin-left: $mu100;
          }
        }
      }
    }
  }
}
</style>
