<template>
    <div class="color-chart">
        <ButtonBack />
        <div class="title">
            <h4>{{ _("Choisissez une ou plusieurs couleurs") }}</h4>
        </div>

        <div class="palettes-bar">
            <div class="palettes-sample-wrapper">
                <div
                    v-for="(palette, index) in palettes"
                    :key="index"
                    :class="['palettes__item', {active: selectedPaletteName === palette.name}]"
                    @click="onPaletteClick(palette, $event)"
                >
                    <p>{{ palette.name }}</p>
                    <div
                        class="sample-color"
                        :style="{ backgroundColor: palette.preview_rgbhex, borderColor: isColorLight(palette.preview_rgbhex) ? '#a19ba2' : 'transparent'}"
                    />
                </div>
            </div>

            <div
                class="search__container"
                :class="{active: searchBarIsActive}"
            >
                <div
                    class="search__toggle"
                    @click="toggleSearchBar"
                >
                    <MIconDisplaySearch32
                        :class="{active: !searchBarIsActive}"
                        color="#78be20"
                    />
                    <MIconControlCross32
                        class="search-close"
                        :class="{active: searchBarIsActive}"
                        color="#78be20"
                    />
                </div>
                <div
                    class="search__input"
                    :class="{active: searchBarIsActive}"
                >
                    <input
                        ref="searchInput"
                        v-model="searchTerm"
                        name="searchbar"
                        type="search"
                        :placeholder="_('Rechercher le nom ou la référence de votre teinte')"
                        @input="onSearchInput"
                        @change="onSearchChange"
                    >
                </div>
            </div>
        </div>

        <div
            ref="colors"
            class="colors-container"
            :class="{
                'open-palette': isPaletteOpen,
                'color-selected': selectedColor,
            }"
            @scroll="movePalette($event)"
        >
            <div
                class="colors-container-wrapper"
            >
                <template v-if="observer !== null">
                    <div
                        v-for="([uid, color], index) in colors"
                        :key="uid"
                    >
                        <Color
                            v-if="color !== null"
                            :ref="uid"
                            :uid="uid"
                            :color="color"
                            :observer="(index % COLORS_BY_ROW === 0) ? observer : null"
                        />
                        <div
                            v-else
                            class="blank"
                        />
                    </div>
                    <div class="custom-color-palette">
                        <color-palette open-by-default />
                    </div>
                </template>
                <Footer />
            </div>
        </div>
    </div>
</template>

<script>
import { MIconDisplaySearch32, MIconControlCross32 } from "@mozaic-ds/icons/vue/index.vue";
import self from "../../../index";
import ColorPalette from "../../components/color-palette/color-palette.vue";
import Color from "../../components/color/color.vue";
import { convertToRGB, isLight } from "../../helpers/colorHelper";
import { uuidv4 } from "../../helpers/uuid";
import ButtonBack from "../../components/button-back/button-back.vue";
import { sendEvent } from "../../services/marketing";

const {
    modules,
} = self.app;

export default {
    name: "ColorChart",
    components: {
        ColorPalette,
        Color,
        MIconDisplaySearch32,
        MIconControlCross32,
        ButtonBack,
    },
    data() {
        return {
            COLORS_BY_ROW: 6,
            observer: null,
            selectedPalette: null,
            searchBarIsActive: false,
            searchTerm: "",
            selectedPaletteName: "",
        };
    },
    computed: {
        // Transform columns to rows
        colors() {
            /* eslint-disable */
            const colors = new Map();
            // Loop through palette
            this.palettes.forEach((palette) => {
                // Loop through palette columns
                for (let i = 0; i < palette.colors[0].length; i++) {
                    // Loop through palette rows
                    for (const row of palette.colors) {
                        const uid = row[i];
                        if (uid) {
                            const color = this.filteredColors[uid];
                            if (color) {
                                colors.set(uid, color);
                                continue;
                            }
                        }
                        if (!this.searchBarIsActive) {
                          // We manage holes in the color chart only if we are not searching
                          colors.set(uuidv4(), null);
                        }
                    }
                }
            });
            return Array.from(colors);
            /* eslint-enable */
        },
        allColors() {
            return this.$store.getters["colors/colors"];
        },
        filteredColors() {
            return this.$store.getters["colors/colorsFiltered"];
        },
        aspects() {
            return this.$store.getters["colors/aspects"];
        },
        palettes() {
            return this.$store.getters["colors/palettes"];
        },
        isPaletteOpen() {
            return this.$store.getters["cartData/isPaletteOpen"];
        },
        selectedColor() {
            return this.$store.getters["cartData/color"];
        },
    },
    mounted() {
        this.observer = new IntersectionObserver(
            this.onColorObserved,
            {
                root: this.$refs.colors,
                threshold: 1.0,
                rootMargin: "16px 8px 138px 8px", // padding of div with colors ref
            }
        );
    },
    beforeDestroy() {
        this.observer.disconnect();
    },
    methods: {
        isColorLight(elem) {
            const rgb = convertToRGB(elem);

            return isLight(rgb);
        },
        toggleSearchBar(event) {
            if (!this.searchBarIsActive) {
                sendEvent("button.click", {
                    button_name: "search bar",
                    button_location: "color chart",
                }, event ? event.target : null);
            }

            this.searchBarIsActive = !this.searchBarIsActive;

            if (!this.searchBarIsActive) {
                this.$store.dispatch("cartData/openPalette");
                this.onSearchBarClose();
            } else {
                this.$store.dispatch("cartData/closePalette");
                this.$refs.searchInput.focus();
            }
        },
        onSearchBarClose() {
            this.$store.commit("colors/RESET_COLORS");
            this.searchTerm = "";
        },
        onSearchInput(e) {
            const { value } = e.target;

            this.$store.commit("colors/FILTER_COLORS", value);
        },
        onSearchChange(e) {
            const { value } = e.target;

            sendEvent("internal_search_result.display", {
                ise_keyword: value,
                ise_name: "color_chart",
            }, e ? e.target : null);
        },
        getPaletteOfColor(colorUID) {
            const palette = this.palettes.find((p) => {
                const colors = p.colors.flat();
                return colors.find((uid) => uid === colorUID);
            });

            if (palette) {
                return palette;
            }

            return undefined;
        },
        onPaletteClick(palette) {
            this.selectedPaletteName = palette.name;
            const firstColorUID = palette.colors[0][0];
            const firstColor = this.filteredColors[firstColorUID];
            if (firstColor) {
                this.scrollToColor(firstColorUID);
            }
            sendEvent("list.filter", {
                filter_name: "color chart",
                filter_value: palette.name,
            });
        },
        scrollToColor(colorUID) {
            const element = this.$refs[colorUID][0].$el;

            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        },
        onColorObserved(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // please refer to color component : id attribute value is set to color uid

                    // ! TEMP ! Disable palette select because of interferences with scrollToColor function
                    // this.selectPalette(entry.target.id);
                }
            });
        },
        selectPalette(colorUID) {
            const palette = this.getPaletteOfColor(colorUID);

            if (palette) {
                this.selectedPalette = palette;
            }
        },
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        movePalette(event) {
            const container = event.target.scrollHeight;
            const scrollPosition = event.target.scrollTop + event.target.offsetHeight;
            const palette = document.querySelector(".custom-color-palette");
            if (container === scrollPosition && window.innerWidth > 850) {
                palette.style.bottom = "30px";
            } else {
                palette.style.bottom = "0px";
            }

        },
    },
};

</script>

<style lang="scss">
@import "../../../../../style/index.scss";
$search-width: 66px;

.color-chart {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$nav-height});
  background: white;

  @include set-from-screen(l-medium) {
    height: calc(100vh - #{$nav-height-desktop});
  }

  .title {
    padding-left: $mu150;
    padding-right: $mu100;
    height: $nav-height;
    display: flex;
    align-items: center;

    @include set-font-scale('04');

    @include set-from-screen(m) {
      @include set-font-scale('05');
    }
  }

  .palettes {
    &-bar {
      display: flex;
      border-top: solid 1px $color-grey-200;
      position: relative;
      overflow: hidden;
      z-index: 1;
      height: 56px;
      box-shadow: 0 5px 5px rgba(grey, 0.3);
      scroll-snap-type: x mandatory;

      @include set-from-screen(m) {
        height: 68px;
      }
    }

    &-sample-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow-y: auto;
      position: relative;
      margin-right: $search-width;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;

      @media screen and (max-width: 1200px) {
        /* Hide scrollbar for IE, Edge add Firefox */
        -ms-overflow-style: none;
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &__item {
      scroll-snap-align: start;
      padding: 0 $mu075;
      text-align: center;
      flex-shrink: 0;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include set-from-screen(m) {
        padding: 0 $mu150;
      }

      &::before {
        transition: 0.3s ease-out;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background: transparent;
        width: 100%;
        height: 2px;
      }

      .sample-color {
        border-style: solid;
        border-width: 1px;
        position: relative;
        margin: 0.2rem auto 0;
        height: 22px;
        width: 40px;
        border-radius: 4px;

        @include set-from-screen(m) {
          margin: $mu025 auto 0;
          height: 30px;
          width: 70px;
        }
      }

      p {
        font-size: 12px;
        margin: 0;
      }

      &:hover {
        cursor: pointer;
      }

      &.active {
        p {
          @extend .font--semi-bold;

          color: $color-secondary-green-500;
        }

        &::before {
          background: $color-secondary-green-500;
        }
      }
    }
  }

  .search {
    &__container {
      position: absolute;
      background: white;
      height: 100%;
      right: 0;
      transition: 0.3s ease-in;
      width: 0;
      box-shadow: 0 3px 4px rgba(grey, 0.3);

      &.active {
        width: 100%;
      }
    }

    &__toggle {
      width: $search-width;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      z-index: 2;
      background: white;
      border-left: solid 1px $color-grey-200;
      box-shadow: 2px 0 14px rgba(black, 0.3);

      svg {
        opacity: 0;
        transform-origin: center;
        position: absolute;
        top: 23%;
        left: 25%;
        transition: 0.3s;
        transform: scale(0);

        &.active {
          transform: scale(1);
          opacity: 1;
        }
      }
    }

    &__input {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 100%;
      height: 100%;
      padding: $mu050 $mu100;
      box-sizing: border-box;
      opacity: 0;
      transition: 0s  ease-out;
      transform: translateX(-100%);

      &.active {
        left: calc(100% - #{$search-width});
        opacity: 1;
        width: calc(100% - #{$search-width});
        transition: 0.3s ease-out;

        @include set-from-screen(m) {
          width: 400px;
        }
      }

      input {
        width: 100%;
        height: 100%;
        padding: 0 $mu100;
        transition: 0.3s;
        border-radius: 4px;
        border: solid 1px grey;

        &:focus {
          border-color: $color-secondary-green-500;
          outline: none;
        }
      }
    }
  }

  .colors-container {
    transition: 0.3s;
    padding-top: 1rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: calc(50px + 18px);
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: hidden;
    background: white;
    height: calc(100vh - #{$nav-height * 2});

    @media screen and (min-width: $medium-break-point) {
      height: calc(100% - #{$nav-height + $footer-height});
    }

    @include set-from-screen(l-medium) {
      height: calc(100% - #{$nav-height-desktop + $footer-height});
    }

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-self: baseline;
      position: relative;

      & > * {
        width: 16.66%;
      }

      .footer {
        width: 100vw !important;
        bottom: calc(-120px - 18px) !important;
        margin: 0 -10px 0 -10px;
      }
    }

    &.open-palette {
      padding-bottom: calc(120px + 18px);

      &.color-selected {
        padding-bottom: calc(170px + 18px);

        .footer {
          bottom: calc(-170px - 18px) !important;
        }

        @media screen and (max-width: 390px) {
          padding-bottom: calc(150px + 18px);
        }
      }
    }
  }

  .custom-color-palette {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 20;
    left: 50%;
    transform: translateX(-50%);
    padding: $mu100;
    transition: 0.3s;

    @include set-from-screen(l) {
      left: calc(100% - #{$mu100});
      width: auto;
      transform: translateX(-100%);
    }
  }
}

</style>
