var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "canvasContainer", staticClass: "canvas-container" },
    [
      _c(
        "div",
        { class: ["canvas-loader", !_vm.isCanvasReady && "is-loading"] },
        [_c("span", [_vm._v(_vm._s(_vm._("Chargement en cours ...")))])]
      ),
      _vm._v(" "),
      _c("canvas", {
        ref: "situationAutoCanvas",
        on: {
          mousedown: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.colorize.apply(null, arguments)
          },
          mousemove: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.colorize.apply(null, arguments)
          },
          mouseup: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.colorize.apply(null, arguments)
          },
          touchstart: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.colorize.apply(null, arguments)
          },
          touchmove: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.colorize.apply(null, arguments)
          },
          touchend: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.colorize.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _vm.isDebug
        ? _c("div", [
            _c("span", [_vm._v("DEBUG")]),
            _vm._v(" "),
            _c("span", [
              _c("button", { on: { click: _vm.showCanvasColorSelected } }, [
                _vm._v("show canvas"),
              ]),
              _vm._v(" "),
              _c("button", { on: { click: _vm.showAllCanvasMasks } }, [
                _vm._v("show all canvas"),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }