<template>
    <div class="shop-card">
        <div class="shop-card__header">
            <div
                class="shop-card__header-color"
                :style="{ backgroundColor: color.rgbhex, border: isColorLight(color[uid]) && 'solid 1px #d3d2d6'}"
            />
            <div>
                <p>
                    {{ getDisplayName(color) }}
                </p>
            </div>
            <div
                class="btn-remove"
                @click="onRemoveColorClick"
            >
                <MIconPublishTrashbin48 />
            </div>
        </div>
        <div class="shop-card__body">
            <ColorAspects
                :uid="uid"
                container-class="shop-card__btn-group"
            />
        </div>

        <aspect-info
            container-class="shop-card__footer"
            :uid="uid"
        />
    </div>
</template>

<script>
import { MIconPublishTrashbin48 } from "@mozaic-ds/icons/vue/index.vue";
import self from "../../../index";
import { convertToRGB, getDisplayName, isLight } from "../../helpers/colorHelper";
import AspectInfo from "../aspect-info/aspect-info.vue";
import ColorAspects from "../color-aspects/color-aspects.vue";
import { sendEvent } from "../../services/marketing";

const {
    modules,
} = self.app;

export default {
    name: "ShoppingCartItem",
    components: {
        MIconPublishTrashbin48,
        AspectInfo,
        ColorAspects,
    },
    props: {
        color: {
            type: Object,
            required: true,
        },
        uid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            getDisplayName,
        };
    },
    computed: {
        aspects() {
            return this.$store.getters["colors/aspects"];
        },
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        onRemoveColorClick() {
            this.$store.dispatch("cartData/removeColor", this.uid);
            sendEvent("button.click", {
                button_name: "delete color",
                cdl_color_name: this.color.name,
            });
        },
        isColorLight() {
            const rgb = convertToRGB(this.color.rgbhex);

            return isLight(rgb);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.shop-card {
  padding: $mu100;
  margin: $mu100;
  background: white;
  border-radius: 4px;

  &__header {
    display: flex;
    margin-bottom: $mu100;
    align-items: flex-start;

    p {
      margin-top: 0;

      @include set-font-scale('05');
      @include set-font-weight('semi-bold');
    }

    .btn-remove {
      margin-left: auto;
      transition: 0.3s;
      border-radius: 50%;

      svg {
        transition: 0.3s;
        width: 30px;
        height: 30px;
        fill: $color-grey-800;
      }

      &:hover {
        cursor: pointer;

        svg {
          fill: $color-danger-500;
        }
      }
    }

    &-color {
      width: 58px;
      height: 58px;
      border-radius: 4px;
      margin-right: $mu100;
    }
  }

  &__footer {
    .link-container {
      display: flex;
      justify-content: flex-end;
    }

    .simple-link {
      margin-bottom: $mu100;
    }
  }

  &__body {
    overflow: auto;
    margin-bottom: $mu050;

    & > * {
      align-items: center;
      justify-content: space-between;
      display: flex;
    }

    .shop-card__btn-group {
      display: flex;
      flex-wrap: wrap;
      grid-column-gap: $mu100;
      grid-row-gap: $mu050;

      & > * {
        flex: 1 auto;
        height: initial !important;
        padding: $mu050 !important;
        min-height: 30px;
        margin: 0;

        &:focus {
          box-shadow: none !important;
        }

        span {
          @include set-font-scale('03');
        }
      }
    }
  }
}
</style>
