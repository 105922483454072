<template>
    <div>
        <div v-if="isLoading">
            <Loader />
        </div>
        <div v-else-if="hasError">
            {{ _("Désolé une erreur est survenue lors de la récupération des images.") }}
        </div>

        <div v-else>
            <div class="save-action">
                <m-button
                    class="btn-enable"
                    :label="_('Sauvegarder')"
                    @click="save"
                />
                <m-button
                    class="btn-enable"
                    :label="_('Télécharger')"
                    @click="download"
                />
                <div v-if="canUpload">
                    <input
                        id="upload-picture-file"
                        type="file"
                        accept="application/json"
                        @change="upload"
                    >
                    <label
                        for="upload-picture-file"
                        class="btn-enable"
                    >
                        {{ _("Ouvrir") }}
                    </label>
                </div>
            </div>

            <PicturesDraggable
                :pictures="pictures"
                @edit-picture="editPicture"
            />
        </div>

        <div
            v-if="isPanelOpen"
            class="side-panel"
            :class="isPanelOpen && 'open'"
        >
            <m-button
                icon="ControlCross24"
                class="btn-close"
                @click="closePanel"
            />
            <PictureEdit
                :picture="selectedPicture"
                :category="selectedPictureCategoryName"
                @update-picture="updatePicture"
                @update-picture-category="updatePictureCategory"
            />
        </div>
    </div>
</template>

<script>
import { saveAs } from "file-saver";
import self from "../../../../index";
import Loader from "../../../components/loader/loader.vue";
import PictureEdit from "../../../components/admin/picture-edit.vue";
import PicturesDraggable from "../../../components/admin/pictures-draggable.vue";
import EventBus from "../../../helpers/event-bus";
import { getCookie } from "../../../helpers/cookieHelper";

// Import config
import config from "../../../../../config";

const {
    modules,
} = self.app;

export default {
    name: "AdminPictureManager",
    components: {
        PicturesDraggable,
        Loader,
        PictureEdit,
    },
    data() {
        return {
            isPanelOpen: false,
            selectedPictureUUID: null,
            selectedPictureCategoryName: null,
        };
    },
    computed: {
        pictures() {
            return this.$store.getters["imagesGallery/images"];
        },
        isLoading() {
            return this.$store.getters["imagesGallery/isLoading"];
        },
        hasError() {
            return this.$store.getters["imagesGallery/error"];
        },
        selectedPicture() {
            const pictureCategory = this.pictures.find((category) => category.categoryName === this.selectedPictureCategoryName);
            return pictureCategory.rooms.find((picture) => picture.uuid === this.selectedPictureUUID);
        },
        canUpload() {
            return config.ADMIN_DISABLE_UPLOAD_IMAGES === "false";
        },
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        updatePicture(picture) {
            this.$store.commit("imagesGallery/UPDATE_IMAGES", {
                picture,
                categoryName: this.selectedPictureCategoryName,
            });
        },
        updatePictureCategory(data) {
            const { picture, categoryName } = data;
            this.$store.commit("imagesGallery/UPDATE_IMAGE_CATEGORY", {
                picture,
                oldCategoryName: this.selectedPictureCategoryName,
                categoryName,
            });
            this.selectedPictureCategoryName = categoryName;
        },
        editPicture(data) {
            const { indexPicture, categoryName } = data;
            this.isPanelOpen = true;
            this.selectedPictureUUID = indexPicture;
            this.selectedPictureCategoryName = categoryName;
        },
        closePanel() {
            this.isPanelOpen = false;
            this.selectedPictureUUID = null;
            this.selectedPictureCategoryName = null;
        },
        save() {
            const csrfToken = getCookie("XSRF-TOKEN");
            const locale = modules.stonejs.getLocale();

            fetch(`/save/images?locale=${locale}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": csrfToken,
                },
                body: JSON.stringify(this.pictures),
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Error while saving JSON");
                    } else {
                        return res.json();
                    }
                })
                .then((body) => {
                    if (body.error !== null) {
                        throw new Error("Error while saving JSON");
                    } else {
                        const message = {
                            title: this._("Sauvegarde réussie"),
                            message: this._("La sauvegarde de vos modifications s'est déroulée avec succès. La bibliothèque d'images a été mise à jour dans l'application."),
                            status: "success",
                            type: "SAVE",
                        };
                        EventBus.$emit("toaster-message", message);
                    }
                })
                .catch(() => {
                    const message = {
                        title: this._("Erreur"),
                        message: this._("Une erreur est survenue lors de la sauvegarde de vos modifications."),
                        status: "error",
                        type: "ERROR",
                    };
                    EventBus.$emit("toaster-message", message);
                });
        },
        download() {
            const blob = new Blob([JSON.stringify(this.pictures)], {
                type: "application/json",
            });
            saveAs(blob, "picture_library.json");
        },
        upload(event) {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (evt) => {
                this.$store.commit("imagesGallery/UPDATE_ALL_IMAGES", JSON.parse(evt.target.result));
                const messageUpload = {
                    title: this._("Succès"),
                    text: this._("Le fichier a été importé avec succès"),
                    status: "success",
                    type: "IMPORT",
                };
                EventBus.$emit("toaster-message", messageUpload);
            };
            reader.readAsText(file);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../../style/index.scss";

.save-action {
  padding: 5px 0;
  display: flex;
  justify-content: flex-end;

  .btn-enable {
    margin: 0 10px;
  }

  #upload-picture-file {
    display: none;
  }

  label {
    border-radius: 5px;
    background: $color-secondary-green-500;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    padding: 0.6875rem 3rem;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }
}

.sort-pictures {
  grid-column-gap: $mu100;
  grid-row-gap: $mu100;
  display: flex;
  flex-wrap: wrap;
  padding-top: $mu150;
  border-top: solid 1px $color-grey-300;
  margin-bottom: $mu400;
}

.side-panel {
  position: absolute;
  transition: 0.3s;
  padding: $mu100;
  left: -100%;
  top: 0;
  width: 350px;
  background: white;
  height: 100%;
  box-shadow: 0 0 10px 0 #939393;

  &.open {
    animation: 0.3s ease-out 0.1s openPanel;
    animation-fill-mode: forwards;
  }

  .btn-close {
    margin-left: auto;
  }
}

@keyframes openPanel {
  100% {
    left: 0;
  }
}
</style>
