<template>
    <div class="color-picture">
        <div class="color-picture__header">
            <ButtonBack />
        </div>
        <div class="color-picture__sub-header">
            <p>{{ _("Coloriser votre photo") }}</p>

            <ButtonShare
                v-if="isUserUploadEnabled"
                mode="SHARE_PICTURE"
                :label="_('Partager')"
            />
        </div>
        <div class="color-picture__content">
            <colorize />
            <Footer />
        </div>
    </div>
</template>

<script>
import ButtonShare from "../../components/button-share/button-share.vue";
import ButtonBack from "../../components/button-back/button-back.vue";
import Colorize from "../../components/colorize/colorize.vue";
import self from "../../../index";

const {
    modules,
} = self.app;

export default {
    name: "ColorPicture",
    components: {
        ButtonBack,
        Colorize,
        ButtonShare,
    },
    computed: {
        imageCustomer() {
            return this.$store.getters["image/image"];
        },
        imageGallery() {
            return this.$store.getters["imagesGallery/imageSelected"];
        },
        mode() {
            if (this.imageCustomer) {
                return "customer";
            }
            if (this.imageGallery) {
                return "gallery";
            }
            return "";
        },
    },
    mounted() {
        if (this.mode === "") {
            this.$router.push({ name: "color_menu" });
        }
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.color-picture {
  &__header {
    position: relative;

    .share-container {
      top: 2px;
      right: $mu100;
    }
  }

  &__sub-header {
    @extend %main-header-title;

    border-bottom: none;

    button > p {
      @include set-font-weight('regular');
    }
  }

  &__content {
    height: calc(100vh - #{$nav-height * 3});
    overflow-y: auto;
    overflow-x: hidden;

    @include set-from-screen(m) {
      height: calc(100vh - #{$nav-height * 3});
    }

    @include set-from-screen(l-medium) {
      height: calc(100vh - #{$nav-height * 2 + $nav-height-desktop});
      flex: 1;
    }
  }
}

.color-palette-wrapper {
  padding: $mu100;
}

</style>
