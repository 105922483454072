const _ = require("stonejs").lazyGettext;

export const PICTURE_CATEGORY_BEDROOM = "PICTURE_CATEGORY_BEDROOM";
export const PICTURE_CATEGORY_BEDROOM_KID = "PICTURE_CATEGORY_BEDROOM_KID";
export const PICTURE_CATEGORY_LIVINGROOM = "PICTURE_CATEGORY_LIVINGROOM";
export const PICTURE_CATEGORY_OFFICE = "PICTURE_CATEGORY_OFFICE";
export const PICTURE_CATEGORY_ENTRANCE = "PICTURE_CATEGORY_ENTRANCE";
export const PICTURE_CATEGORIES = {
    [PICTURE_CATEGORY_LIVINGROOM]: _("Salon / Salle à manger"),
    [PICTURE_CATEGORY_BEDROOM]: _("Chambre"),
    [PICTURE_CATEGORY_BEDROOM_KID]: _("Chambre enfant"),
    [PICTURE_CATEGORY_OFFICE]: _("Bureau"),
    [PICTURE_CATEGORY_ENTRANCE]: _("Entrée"),
};

export const PICTURE_ATMOSPHERE_NATURE = "PICTURE_ATMOSPHERE_NATURE";
export const PICTURE_ATMOSPHERE_GLAMOUR = "PICTURE_ATMOSPHERE_GLAMOUR";
export const PICTURE_ATMOSPHERE_INDUSTRIAL = "PICTURE_ATMOSPHERE_INDUSTRIAL";
export const PICTURE_ATMOSPHERE_DESIGN = "PICTURE_ATMOSPHERE_DESIGN";
export const PICTURE_ATMOSPHERE_BOHEM = "PICTURE_ATMOSPHERE_BOHEM";
export const PICTURE_ATMOSPHERE_PREFIX = "PICTURE_ATMOSPHERE_PREFIX";
export const PICTURE_ATMOSPHERES = {
    [PICTURE_ATMOSPHERE_PREFIX]: _("Ambiance"),
    [PICTURE_ATMOSPHERE_NATURE]: _("Nature"),
    [PICTURE_ATMOSPHERE_GLAMOUR]: _("Glamour"),
    [PICTURE_ATMOSPHERE_INDUSTRIAL]: _("Industriel"),
    [PICTURE_ATMOSPHERE_DESIGN]: _("Design"),
    [PICTURE_ATMOSPHERE_BOHEM]: _("Bohême"),
};

export const PAINTING_TOOL_FILL_MESSAGE = "Appliquez la couleur de votre choix sur un mur complet";
export const PAINTING_TOOL_PAINT_MESSAGE = "Appliquez la couleur de votre choix en peignant librement la zone souhaitée";
export const PAINTING_TOOL_ERASE_MESSAGE = "Gommez pour effacer les couleurs sur une zone";
export const PAINTING_TOOL_BACK_MESSAGE = "Vous venez d'annuler l'action précédente";
export const PAINTING_TOOL_HIGHLIGHT_MESSAGE = "Les zones à coloriser s'affichent sur l'image";

export const MOBILE_WIDTH = 800;
