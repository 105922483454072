var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "situation-perso" },
    [
      _c("ButtonBack"),
      _vm._v(" "),
      _c("div", { staticClass: "situation-perso__header" }, [
        _c("p", [_vm._v(_vm._s(_vm._("Mise en situation personnalisée")))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "guideline-container" }, [
        _c(
          "button",
          {
            staticClass: "mc-button mc-button--s",
            attrs: { type: "button" },
            on: { click: _vm.openModalGuidelines },
          },
          [
            _c("MIconNotificationInformation24"),
            _vm._v(" "),
            _c("span", { staticClass: "mc-button__label mu-ml-050" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._("Comment prendre ma photo")) +
                  "\n            "
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "dropzone-container" }, [
        _c(
          "div",
          {
            class: [
              "dropzone",
              { "active-dropzone": _vm.active },
              { fill: _vm.hasImage },
            ],
            on: {
              dragenter: function ($event) {
                $event.preventDefault()
                return _vm.toggleActive.apply(null, arguments)
              },
              dragleave: function ($event) {
                $event.preventDefault()
                return _vm.toggleActive.apply(null, arguments)
              },
              dragover: function ($event) {
                $event.preventDefault()
              },
              drop: function ($event) {
                $event.preventDefault()
                return _vm.droping($event)
              },
              change: function ($event) {
                return _vm.onFileUpload($event)
              },
            },
          },
          [
            _vm.hasImage
              ? _c(
                  "div",
                  {
                    staticClass: "dropzone-image",
                    style: { backgroundImage: `url(${_vm.image})` },
                  },
                  [
                    _c(
                      "button",
                      { attrs: { id: "remove" }, on: { click: _vm.remove } },
                      [_c("MIconPublishTrashbin32")],
                      1
                    ),
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "dropzone-no-image",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.file.click()
                      },
                    },
                  },
                  [
                    _c("label", [_c("MIconUpload32")], 1),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm._(
                            "Sélectionner une image de votre galerie afin de la coloriser à votre goût"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      ref: "file",
                      attrs: {
                        id: "dropzoneFile",
                        type: "file",
                        accept: "image/*",
                      },
                    }),
                  ]
                ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dropzone-buttons" },
        [
          _c("m-button", {
            attrs: { label: _vm._("Annuler"), theme: "bordered" },
            on: { click: _vm.cancel },
          }),
          _vm._v(" "),
          _c("m-button", {
            attrs: { disabled: !_vm.hasImage, label: _vm._("Valider") },
            on: { click: _vm.validate },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }