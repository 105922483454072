var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["admin-card", { disable: !_vm.picture.enable }] },
    [
      _c("div", { class: ["admin-card__cover", { generic: _vm.isGeneric }] }, [
        _c("img", { attrs: { src: `/${_vm.picture.thumbnail_image}` } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "admin-card__content" }, [
        _c(
          "div",
          [
            _vm.picture.enable
              ? _c(
                  "button",
                  {
                    staticClass: "btn-action active",
                    on: { click: _vm.disablePicture },
                  },
                  [
                    _c("span"),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm._("Actif")))]),
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn-action",
                    on: { click: _vm.enablePicture },
                  },
                  [
                    _c("span"),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm._("Inactif")))]),
                  ]
                ),
            _vm._v(" "),
            _c("m-button", {
              staticClass: "btn-edit",
              attrs: { icon: "PublishEdit24", theme: "bordered-neutral" },
              on: { click: _vm.editPicture },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isGeneric,
                expression: "isGeneric",
              },
            ],
          },
          [
            _vm._v(
              "\n            " + _vm._s(_vm._("Image générique")) + "\n        "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }