<template>
    <div>
        <div>
            <h1>
                {{ _("Espace administration") }}
            </h1>

            <form
                class="form"
                @submit.stop.prevent="authenticate"
            >
                <m-field
                    id="username"
                    :label="_('Nom d\'utilisateur')"
                    :is-invalid="hasError"
                    :error-message="_('Les identifiants renseignés sont invalides.')"
                >
                    <m-text-input
                        id="username"
                        class="mc-field__input"
                        required
                    />
                </m-field>

                <m-field
                    id="password"
                    :label="_('Mot de passe')"
                    :is-invalid="hasError"
                    :error-message="_('Les identifiants renseignés sont invalides.')"
                >
                    <m-text-input
                        id="password"
                        class="mc-field__input"
                        required
                        type="password"
                    />
                </m-field>

                <div class="buttons">
                    <m-button
                        theme="solid-neutral"
                        type="submit"
                        :label="_('Se connecter')"
                    />
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import self from "../../../../index";
import { login } from "../../../api/auth";

const {
    modules,
} = self.app;

export default {
    name: "AdminLogin",
    data() {
        return {
            hasError: false,
        };
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        authenticate(event) {
            const username = event.target.elements.username.value;
            const password = event.target.elements.password.value;

            login(username, password)
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error("Invalid credentials");
                    }
                    this.$router.push({ name: "admin_color_manager" });
                })
                .catch(() => {
                    this.hasError = true;
                });
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../../style/index.scss";

.form {
  margin: auto;

  .mc-field {
    margin-bottom: magic-unit-rem(1);
  }

  .buttons {
    margin-top: magic-unit-rem(1);
    margin-left: 0;
  }
}

@include set-from-screen(l) {
  .form {
    width: 50%;
  }
}
</style>
