import cv from "@techstark/opencv-js";

/**
 * This function adjust colors of the mask if the colors code is not excatly 0 or 255
 * @param {Canvas} mask - Canvas as HTML element
 * @return {Canvas} - A new Canvas with adjusted white and black
 */
function refineMask(mask) {

    const maskMatRGB = cv.imread(mask);

    const maskMatGray = new cv.Mat();
    cv.cvtColor(maskMatRGB, maskMatGray, cv.COLOR_RGB2GRAY);

    const refinedMaskMatGray = new cv.Mat();
    cv.threshold(maskMatGray, refinedMaskMatGray, 122, 255, cv.THRESH_BINARY);

    // Reconvert to canvas
    const canvas = document.createElement("canvas");
    cv.imshow(canvas, refinedMaskMatGray);

    // Clean up
    maskMatRGB.delete(); maskMatGray.delete(); refinedMaskMatGray.delete();

    // End
    return canvas;

}

/**
 * This function tells if a click is on a mask area.
 * @param {Canvas} imgMask - Canvas as HTML element
 * @param {Object} mousePos - Mouse position as an object { x: 1, y: 2}
 * @return {Boolean} - true if the click is on the mask area, false otherwise
 */
function isMaskUnder(imgMask, mousePos) {

    // Convert to OpenCV matrix
    const imgMaskMatRGB = cv.imread(imgMask, cv.IMREAD_UNCHANGED);

    // Do the thing
    const alphaIndex = (Math.round(mousePos.y) * imgMaskMatRGB.cols + Math.round(mousePos.x)) * imgMaskMatRGB.channels();
    const ret = imgMaskMatRGB.data[alphaIndex - 1] !== 0;

    // Clean up
    imgMaskMatRGB.delete();

    // End ?
    return ret;

}

/**
 * This function merge two masks.
 * @param {Canvas} mask1 - Canvas as HTML element
 * @param {Canvas} mask2 - Canvas as HTML element
 * @return {Canvas} - A canvas with a mask from merged masks in entry
 */
function fuseMasks(mask1, mask2) {
    // Converting to OpenCV mat
    const mask1Mat = cv.imread(mask1);
    const mask2Mat = cv.imread(mask2);

    // Converting to gray
    cv.cvtColor(mask1Mat, mask1Mat, cv.COLOR_RGB2GRAY);
    cv.cvtColor(mask2Mat, mask2Mat, cv.COLOR_RGB2GRAY);

    // Refining (just in case)
    cv.threshold(mask1Mat, mask1Mat, 122, 255, cv.THRESH_BINARY);
    cv.threshold(mask2Mat, mask2Mat, 122, 255, cv.THRESH_BINARY);

    // Fusion
    const ret = new cv.Mat();
    cv.bitwise_or(mask1Mat, mask2Mat, ret);

    // Reconvert to canvas
    const canvas = document.createElement("canvas");
    cv.imshow(canvas, ret);

    // Clean up
    mask1Mat.delete(); mask2Mat.delete(); ret.delete();

    // End
    return canvas;
}

/**
 * This function return the difference between two masks.
 * @param {Canvas} mask1 - Canvas as HTML element
 * @param {Canvas} mask2 - Canvas as HTML element
 * @return {Canvas} - A canvas with a mask from the mask1 reduced by the mask2
 */
function diffMasks(mask1, mask2) {
    // Converting to OpenCV mat
    const mask1Mat = cv.imread(mask1);
    const mask2Mat = cv.imread(mask2);

    const diff = new cv.Mat();
    cv.absdiff(mask1Mat, mask2Mat, diff);

    const canvas = document.createElement("canvas");
    cv.imshow(canvas, diff);

    mask1Mat.delete(); mask2Mat.delete(); diff.delete();

    return canvas;
}

/**
 * Test if same color zone can be merged
 * @param  {CanvasImage} zoneToMerge, mask you want to test to merge with others masks
 * @param  {Array[CanvasImage]} zones       list of masks zoneToMerge will be tested
 * @return {CanvasImage}             zone initial or merged with other(s) zones
 */
function testAndMergeZone(zoneToMerge, zones) {
    const baseMask = cv.imread(zoneToMerge);
    const kernel = cv.Mat.ones(5, 5, cv.CV_8U);
    cv.dilate(baseMask, baseMask, kernel);

    for (let i = 0; i < zones.length; i++) {
        const currentZone = cv.imread(zones[i]);
        const dilatedCurrentZone = new cv.Mat();
        cv.dilate(currentZone, dilatedCurrentZone, kernel);
        const dilatedZoneBitwiseAnd = new cv.Mat();
        // we do a bitwise and, if result as white pixels it means there is an intersection on dilated zones
        // it means both mask were close geometrically
        cv.bitwise_and(baseMask, dilatedCurrentZone, dilatedZoneBitwiseAnd);

        const areaIntersectedZone = Math.floor(dilatedZoneBitwiseAnd.data.reduce((a, b) => a + b, 0) / 255);
        if (areaIntersectedZone > 50) {
            dilatedCurrentZone.delete(); currentZone.delete(); dilatedZoneBitwiseAnd.delete(); baseMask.delete(); kernel.delete();
            return fuseMasks(zoneToMerge, zones[i]);
        }
        dilatedCurrentZone.delete(); currentZone.delete(); dilatedZoneBitwiseAnd.delete();
    }
    const result = document.createElement("canvas");
    cv.imshow(result, baseMask);
    baseMask.delete(); kernel.delete();
    return result;
}

/**
 * Morphological opening dilate followed by a erosion the kernel size
 * It's main use is to reduce number of really small zones or artefact
 * Care this function transform passed picture
 * @param  {OpenCVMAT} basePicture picture to morphological open
 * @return {OpenCVMAT} basePicture morphologically opened
 *
 * function used in detectRegion
 */
function morphologicalOpeningMat(basePicture) {
    const M = cv.Mat.ones(5, 5, cv.CV_8U);
    const anchor = new cv.Point(-1, -1);
    // You can try more different parameters
    cv.morphologyEx(basePicture, basePicture, cv.MORPH_OPEN, M, anchor, 1, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue());
    M.delete();

    return basePicture;
}

/**
 * Call Morphological opening (dilate followed by a erosion) the kernel size
 * It's main use is to reduce number of really small zones or artefact
 * Care this function transform passed canvas
 * @param  {Canvas} basePicture picture to morphological open
 * @return {Canvas} basePicture morphologically opened
 *
 */
function morphologicalOpeningCanvas(basePicture) {
    // Converting to OpenCV mat
    const basePictureMat = cv.imread(basePicture);

    morphologicalOpeningMat(basePictureMat);

    const canvas = document.createElement("canvas");
    cv.imshow(canvas, basePictureMat);

    basePictureMat.delete();

    return canvas;
}

/**
 * Preprocessor
 * Max contrast and median smoothing
 *
 * function used in detectRegion
 */
// eslint-disable-next-line no-unused-vars
function preprocessor(pictureMatGray, medianBlurSize) {
    const preprocessedPictureMatGray = new cv.Mat();
    cv.equalizeHist(pictureMatGray, preprocessedPictureMatGray);
    // Temporary fix to remove medianBlur as for big picture it only works on 8U picture
    // At moment we don't properly suceed to convert picture to correct format or even read format to "if" the median blur
    // console.log(preprocessedPictureMatGray.type);
    // cv.convertScaleAbs(preprocessedPictureMatGray, preprocessedPictureMatGray);
    // preprocessedPictureMatGray.convertTo(preprocessedPictureMatGray, cv.CV_8U);
    // cv.medianBlur(preprocessedPictureMatGray, preprocessedPictureMatGray, medianBlurSize);
    morphologicalOpeningMat(preprocessedPictureMatGray);

    return preprocessedPictureMatGray;
}

/**
 * Global canny edge detection
 * Otsu as threshold works well
 *
 * function used in detectRegion
 */
function cannyEdgesAuto(pictureMatGray, sensitivity) {

    const aux = new cv.Mat();
    const otsu = Math.round(sensitivity * cv.threshold(pictureMatGray, aux, 0, 255, cv.THRESH_OTSU));
    aux.delete();

    const edgesMatGray = new cv.Mat();
    cv.Canny(pictureMatGray, edgesMatGray, otsu / 2, otsu, 3, true);

    pictureMatGray.delete();
    return edgesMatGray;

}

/**
 * Uses floodfill to fill inside of edges
 *
 * function used in detectRegion
 */
function floodFillMask(pictureMatRGB, edgesMatGray, seedPoint, tolerance, luminanceCoeff = 5) {

    const wallMaskMatGray = edgesMatGray.clone();

    const w = edgesMatGray.cols;
    const h = edgesMatGray.rows;

    // Mask for OpenCV floodfill must have a "frame" around it (so it has to be 2 pixels higher and wider)
    const row = cv.Mat.ones(1, w, cv.CV_8U);
    const vectV = new cv.MatVector();
    vectV.push_back(row);
    vectV.push_back(wallMaskMatGray);
    vectV.push_back(row);
    cv.vconcat(vectV, wallMaskMatGray);

    const col = cv.Mat.ones(h + 2, 1, cv.CV_8U);
    const vectH = new cv.MatVector();
    vectH.push_back(col);
    vectH.push_back(wallMaskMatGray);
    vectH.push_back(col);
    cv.hconcat(vectH, wallMaskMatGray);

    // Convert to Lab (floodfill should be more sensible to color than light)
    const pictureMatLab = new cv.Mat();
    cv.cvtColor(pictureMatRGB, pictureMatLab, cv.COLOR_RGB2Lab);

    // Floodfill
    cv.floodFill(
        pictureMatLab,
        wallMaskMatGray,
        seedPoint,
        [0, 0, 0, 255],
        0,
        [tolerance, tolerance, luminanceCoeff * tolerance, 255],
        [tolerance, tolerance, luminanceCoeff * tolerance, 255],
        cv.FLOODFILL_FIXED_RANGE
    );

    // Removing edges from the mask (edges are 255, wall is 1, rest is 0)
    const aux = new cv.Mat(h + 2, w + 2, cv.CV_8U, new cv.Scalar(1));
    cv.inRange(wallMaskMatGray, aux, aux, wallMaskMatGray);

    // Close (to remove holes 2 pixels or smaller) then open (to remove 2 pixels or smaller artifacts)
    const kernel = cv.Mat.ones(3, 3, cv.CV_8U);
    cv.morphologyEx(wallMaskMatGray, wallMaskMatGray, cv.MORPH_CLOSE, kernel);
    cv.morphologyEx(wallMaskMatGray, wallMaskMatGray, cv.MORPH_OPEN, kernel);

    // Clean up
    row.delete(); col.delete(); aux.delete(); kernel.delete(); pictureMatLab.delete(); vectV.delete(); vectH.delete();

    // Return (remove frame)
    const rect = new cv.Rect(1, 1, w, h);
    return wallMaskMatGray.roi(rect);

}

/**
 * This function return a Mask from a position on a Canvas image
 * @param {Canvas} basePicture - Canvas as HTML element
 * @param {Object} mousePos - Mouse position as an object { x: 1, y: 2}
 * @param {Integer} tolerance - A number between 0 and 255 to adjust mask detection (use for floodfill algo)
 * @param {Float} sensitivity - A number between 0 and 1 to adjust mask detection (use for canny algo)
 * 0 is super strict and 255 is very permissive (can potentially return the whole picture)
 * @return {Canvas} - A new Canvas with the Mask to use for coloration
 */
function detectRegion(basePicture, mousePos, tolerance = 30, sensitivity = 0.66) {
    // Convert to OpenCV matrix
    const basePictureMatRGBA = cv.imread(basePicture);

    const w = basePictureMatRGBA.cols;
    const h = basePictureMatRGBA.rows;
    const d = Math.sqrt(w * w + h * h);

    // Convert to gray
    const basePictureMatGray = new cv.Mat();
    cv.cvtColor(basePictureMatRGBA, basePictureMatGray, cv.COLOR_RGBA2GRAY);

    // Preprocess
    const preprocessedPictureMatGray = preprocessor(basePictureMatGray, Math.round(d / 1000));

    // Canny edge detection
    const edgesMatGray = cannyEdgesAuto(preprocessedPictureMatGray, sensitivity);

    // Convert to RGB
    const basePictureMatRGB = new cv.Mat();
    cv.cvtColor(basePictureMatRGBA, basePictureMatRGB, cv.COLOR_RGBA2RGB);

    // Floodfill
    const wallMaskMatGray = floodFillMask(basePictureMatRGB, edgesMatGray, mousePos, tolerance, 8);

    // Convert to canvas
    const wallMask = document.createElement("canvas");
    cv.imshow(wallMask, wallMaskMatGray);

    // Clean up
    basePictureMatRGBA.delete();
    basePictureMatGray.delete();
    edgesMatGray.delete();
    basePictureMatRGB.delete();
    wallMaskMatGray.delete();

    // Return :)
    return wallMask;
}

function detectInMask(basePicture, mousePos, tolerance = 30, sensitivity = 0.66) {
    // Convert to OpenCV matrix
    const basePictureMatRGBA = cv.imread(basePicture);

    // Convert to gray
    const basePictureMatGray = new cv.Mat();
    cv.cvtColor(basePictureMatRGBA, basePictureMatGray, cv.COLOR_RGBA2GRAY);

    // Canny edge detection
    const edgesMatGray = cannyEdgesAuto(basePictureMatGray, sensitivity);

    // Convert to RGB
    const basePictureMatRGB = new cv.Mat();
    cv.cvtColor(basePictureMatRGBA, basePictureMatRGB, cv.COLOR_RGBA2RGB);

    // Floodfill
    const wallMaskMatGray = floodFillMask(basePictureMatRGB, edgesMatGray, mousePos, tolerance, 8);

    // Convert to canvas
    const wallMask = document.createElement("canvas");
    cv.imshow(wallMask, wallMaskMatGray);

    // Clean up
    basePictureMatRGBA.delete();
    edgesMatGray.delete();
    basePictureMatRGB.delete();
    wallMaskMatGray.delete();

    // Return :)
    return wallMask;
}

export {
    refineMask,
    isMaskUnder,
    testAndMergeZone,
    fuseMasks,
    detectRegion,
    detectInMask,
    diffMasks,
    morphologicalOpeningCanvas,
};
