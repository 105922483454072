var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isLoading
      ? _c("div", [_c("Loader")], 1)
      : _vm.hasError
      ? _c("div", [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._(
                  "Désolé une erreur est survenue lors de la récupération des images."
                )
              ) +
              "\n    "
          ),
        ])
      : _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "save-action" },
              [
                _c("m-button", {
                  staticClass: "btn-enable",
                  attrs: { label: _vm._("Sauvegarder") },
                  on: { click: _vm.save },
                }),
                _vm._v(" "),
                _c("m-button", {
                  staticClass: "btn-enable",
                  attrs: { label: _vm._("Télécharger") },
                  on: { click: _vm.download },
                }),
                _vm._v(" "),
                _vm.canUpload
                  ? _c("div", [
                      _c("input", {
                        attrs: {
                          id: "upload-picture-file",
                          type: "file",
                          accept: "application/json",
                        },
                        on: { change: _vm.upload },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "btn-enable",
                          attrs: { for: "upload-picture-file" },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm._("Ouvrir")) +
                              "\n                "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("PicturesDraggable", {
              attrs: { pictures: _vm.pictures },
              on: { "edit-picture": _vm.editPicture },
            }),
          ],
          1
        ),
    _vm._v(" "),
    _vm.isPanelOpen
      ? _c(
          "div",
          { staticClass: "side-panel", class: _vm.isPanelOpen && "open" },
          [
            _c("m-button", {
              staticClass: "btn-close",
              attrs: { icon: "ControlCross24" },
              on: { click: _vm.closePanel },
            }),
            _vm._v(" "),
            _c("PictureEdit", {
              attrs: {
                picture: _vm.selectedPicture,
                category: _vm.selectedPictureCategoryName,
              },
              on: {
                "update-picture": _vm.updatePicture,
                "update-picture-category": _vm.updatePictureCategory,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }