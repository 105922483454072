<template>
    <div
        class="share-container"
        :class="[
            isOpen && 'open',
            inPalette ? 'palette-mode' : 'header-mode',
        ]"
    >
        <button
            :title="_('Partager')"
            class="btn-share"
            data-cerberus="share-button"
            :class="!isOpen && 'show'"
            @click="openBar"
        >
            <MIconShareShareAndroid24 color="#554f52" />
            <p :class="[isOpen && 'open']">
                {{ label }}
            </p>
        </button>

        <div :class="['share-actions', isOpen && 'show']">
            <button
                :title="_('Envoyer par email')"
                data-cerberus="share-button-email"
                @click="openMailModal"
            >
                <img
                    :src="mailIcon"
                    alt="mail-icon"
                >
            </button>
            <button
                :title="_('Partager le lien')"
                data-cerberus="share-button-link"
                @click="shareLink"
            >
                <MIconDisplayExternalLink24 />
            </button>
            <button
                :title="_('Télécharger')"
                @click="download"
            >
                <MIconDownloadApp24 />
            </button>
            <button
                :title="_('Fermer')"
                data-cerberus="share-button-close"
                @click="closeBar"
            >
                <MIconControlCross24 color="white" />
            </button>
        </div>
    </div>
</template>

<script>
import {
    MIconShareShareAndroid24, MIconDisplayExternalLink24, MIconDownloadApp24, MIconControlCross24,
} from "@mozaic-ds/icons/vue/index.vue";
import mailIcon from "../../../../../assets/images/icons/mail.svg";
import EventBus from "../../helpers/event-bus";
import self from "../../../index";
import { sendEvent } from "../../services/marketing";

const {
    modules,
} = self.app;

export default {
    name: "ButtonShare",
    components: {
        MIconShareShareAndroid24,
        MIconDisplayExternalLink24,
        MIconDownloadApp24,
        MIconControlCross24,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        inPalette: {
            type: Boolean,
            required: false,
            default: false,
        },
        mode: {
            type: String,
            required: true,
            validator: function (value) {
                // The value must match one of these strings
                return ["SHARE_COLOR", "SHARE_PALETTE", "SHARE_PICTURE"].indexOf(value) !== -1;
            },
        },
    },
    data() {
        return {
            mailIcon,
            isOpen: false,
        };
    },
    computed: {
        isShareButtonOpenedInModal() {
            return this.$store.getters["cartData/isShareButtonOpenedInModal"];
        },
    },
    mounted() {
        EventBus.$on("close-modal-mail", this.closeBar);
    },
    beforeDestroy() {
        EventBus.$off("close-modal-mail");
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        openBar() {
            if (this.inPalette) {
                this.$store.commit("cartData/OPEN_BUTTON_SHARE", true);
                this.isOpen = this.isShareButtonOpenedInModal;
            } else {
                this.isOpen = true;
            }
        },
        closeBar() {
            if (!this.inPalette) {
                this.$store.commit("cartData/CLOSE_BUTTON_SHARE", false);
                this.isOpen = this.isShareButtonOpenedInModal;
                this.isOpen = false;
            } else {
                this.isOpen = false;
            }
        },
        sendShareAnalycticsEvent(shareMode, event) {
            if (this.mode === "SHARE_PICTURE") {
                sendEvent("button.click", {
                    link_destination: shareMode,
                    button_name: "share my picture",
                }, event ? event.target : null);
            } else if (this.mode === "SHARE_COLOR") {
                sendEvent("button.click", {
                    link_destination: shareMode,
                    button_name: "share my color",
                }, event ? event.target : null);
            } else if (this.mode === "SHARE_PALETTE") {
                if (this.inPalette) {
                    sendEvent("button.click", {
                        link_destination: shareMode,
                        button_name: "share my colors",
                        feature_name: "color palette",
                    }, event ? event.target : null);
                } else {
                    sendEvent("button.click", {
                        link_destination: shareMode,
                        button_name: "share my color list",
                    }, event ? event.target : null);
                }
            }
        },
        openMailModal(event) {
            this.sendShareAnalycticsEvent("email", event);

            EventBus.$emit("open-modal-mail", this.mode);
            this.closeBar();
        },
        shareLink(event) {
            this.sendShareAnalycticsEvent("link", event);

            const callback = (clipbordData) => new Promise(() => {
                navigator.clipboard.writeText(clipbordData).then(() => {
                    const messageSuccess = {
                        title: this._("Copié"),
                        text: this._("Le lien a été copié"),
                        status: "success",
                        type: "LINK",
                    };
                    EventBus.$emit("toaster-message", messageSuccess);
                    this.closeBar();
                }, () => {
                    const messageError = {
                        title: this._("Erreur"),
                        text: this._("Impossible de copier le lien"),
                        status: "error",
                        type: "ERROR",
                    };
                    EventBus.$emit("toaster-message", messageError);
                    this.closeBar();
                });
            });

            if (this.inPalette || this.mode === "SHARE_PALETTE") {
                EventBus.$emit("share-link-palette", callback);
            } else {
                EventBus.$emit("share-link", callback);
            }
        },
        download(event) {
            this.sendShareAnalycticsEvent("download", event);

            EventBus.$emit("download-action");
            this.closeBar();
        },
    },
};

</script>

<style lang="scss">
@import "../../../../../style/index.scss";

$btn-share-size: 32px;

.share-container {
  position: absolute;
  right: 0;
  transition: 0.3s ease-in-out;
  width: $btn-share-size + 8px;
  height: $btn-share-size + 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    transition: 0.3s;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  &.open {
    width: 260px;
  }

  .btn-share {
    border-radius: 4px;
    border: none !important;
    width: $btn-share-size;
    height: $btn-share-size;
    position: absolute;
    transform: scale(0);
    right: 4px;
    opacity: 0;
    transition: 0.3s ease-in-out;
    z-index: 2;
    background: white;
    box-shadow: 0 0 4px rgba(black, 0.4);

    &.show {
      transform: scale(1);
      opacity: 1;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .share-actions {
    position: absolute;
    right: 4px;
    display: flex;
    transition: 0.3s ease-in-out;
    overflow: hidden;
    z-index: 1;

    & > * {
      width: $btn-share-size;
      height: $btn-share-size;
      border-radius: 50%;
      margin-left: $mu050;
      border: none;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      img {
        width: $btn-share-size * 0.65;
        height: $btn-share-size * 0.65;
      }

      svg {
        fill: $color-grey-800;
        transition: 0.3s;
        width: $btn-share-size * 0.75;
        height: $btn-share-size * 0.75;

        &:hover {
          opacity: 0.7;
        }
      }

      &:focus {
        box-shadow: none !important;
      }

      &:last-child {
        background: $color-grey-700;
        border-radius: 4px;

        svg {
          fill: white;
          width: $btn-share-size * 0.6;
          height: $btn-share-size * 0.6;
        }
      }
    }
  }

  &.palette-mode {
    width: auto;
    overflow: initial;

    .btn-share {
      background: white;
      width: 0;
      display: flex;
      align-items: center;
      transform: scale(1);
      transition: opacity 0.2s, width 0s 0.3s, padding 0s 0.3s;
      overflow: hidden;
      padding: 0;
      opacity: 0;

      svg {
        width: 20px;
        height: 20px;
      }

      p {
        margin-left: $mu075;

        @include set-font-scale('04');
      }

      &.show {
        transition: 0.3s;
        opacity: 1;
        transform: scale(1);
        width: 250px;
        padding: 6px;
      }

      &:focus {
        box-shadow: none !important;
      }
    }

    .share-actions {
      opacity: 0;
      position: relative;
    }

    &.open {
      .share-actions {
        opacity: 1;
      }
    }
  }

  &.header-mode {
    width: auto;
    overflow: initial;

    .btn-share {
      background: white;
      width: 0;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      transform: scale(1);
      transition: opacity 0.2s, width 0s 0.3s, padding 0s 0.3s;
      overflow: hidden;
      padding: 0;
      opacity: 0;

      svg {
        width: 20px;
        height: 20px;
      }

      p {
        padding: 0 1rem 0 0;
        transition: 0.3s;
        opacity: 0;
        display: none;

        @include set-font-scale('05');

        @include set-from-screen('m') {
          display: block;
        }
      }

      &.show {
        transition: 0.3s;
        opacity: 1;
        transform: scale(1);
        width: 36px;
        padding: 8px;

        @include set-from-screen('m') {
          width: 125px;
        }

        p {
          opacity: 1;
        }
      }

      &:focus {
        box-shadow: none !important;
      }
    }

    .share-actions {
      opacity: 0;
      position: relative;
    }

    &.open {
      .share-actions {
        opacity: 1;
      }
    }
  }
}

.share-modal {
  .mc-modal__header {
    display: none;
  }

  .mc-modal__dialog {
    padding: $mu100;

    #email-input {
      margin-top: $mu100;
    }
  }
}

h1 {
  text-align: center;
}

.form {
  padding: $mu100 $mu200;
}
</style>
