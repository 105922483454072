<template>
    <div class="situation-auto">
        <ButtonBack />
        <div class="situation-perso__header">
            <p>{{ _("Choisissez votre pièce") }}</p>
        </div>

        <div class="cards-wrapper">
            <div class="rooms-wrapper">
                <card-style-picto
                    v-for="(room) in sortedRooms"
                    :data-cerberus="room.categoryName"
                    :key="room.categoryName"
                    @click="selectRoom(room.categoryName)"
                >
                    <div>
                        <MIconLiving48 v-show="room.categoryName === 'PICTURE_CATEGORY_LIVINGROOM'" />
                        <MIconBedroom48 v-show="room.categoryName === 'PICTURE_CATEGORY_BEDROOM'" />
                        <MIconKids48 v-show="room.categoryName === 'PICTURE_CATEGORY_BEDROOM_KID'" />
                        <MIconLighting48 v-show="room.categoryName === 'PICTURE_CATEGORY_OFFICE'" />
                        <MIconDoor48 v-show="room.categoryName === 'PICTURE_CATEGORY_ENTRANCE'" />
                    </div>

                    <p>{{ _(PICTURE_CATEGORIES[room.categoryName]) }}</p>
                </card-style-picto>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import {
    MIconLiving48,
    MIconBedroom48,
    MIconKids48,
    MIconLighting48,
    MIconDoor48,
} from "@mozaic-ds/icons/vue/index.vue";
import ButtonBack from "../../components/button-back/button-back.vue";
import self from "../../../index";
import CardStylePicto from "../../components/card-style/card-style-picto.vue";
import {
    PICTURE_CATEGORY_BEDROOM,
    PICTURE_CATEGORY_BEDROOM_KID,
    PICTURE_CATEGORY_LIVINGROOM,
    PICTURE_CATEGORY_OFFICE,
    PICTURE_CATEGORY_ENTRANCE,
    PICTURE_CATEGORIES,
} from "../../helpers/constants";

const {
    modules,
} = self.app;

export default {
    name: "Rooms",
    components: {
        ButtonBack,
        CardStylePicto,
        MIconLiving48,
        MIconBedroom48,
        MIconKids48,
        MIconLighting48,
        MIconDoor48,
    },
    data() {
        return {
            PICTURE_CATEGORIES,
            sortedRooms: [],
            categoryOrder: [
                PICTURE_CATEGORY_LIVINGROOM,
                PICTURE_CATEGORY_BEDROOM,
                PICTURE_CATEGORY_BEDROOM_KID,
                PICTURE_CATEGORY_OFFICE,
                PICTURE_CATEGORY_ENTRANCE,
            ],
        };
    },
    computed: {
        rooms() {
            return this.$store.getters["imagesGallery/images"];
        },
    },
    mounted() {
        this.sortRooms();
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        selectRoom(categoryName) {
            this.$router.push({ name: "room", params: { categoryName } });
        },
        getRoomImage(room) {
            return room.rooms[0] ? room.rooms[0].image : "";
        },
        sortRooms() {
            this.sortedRooms = [...this.rooms];
            this.sortedRooms.sort((a, b) => (this.categoryOrder.indexOf(a.categoryName) - this.categoryOrder.indexOf(b.categoryName)));
        },
    },
};

</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.situation-auto {
  .cards-wrapper {
    // padding: $mu100;
    height: calc(100vh - #{$nav-height * 3});
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    @include set-from-screen(l-medium) {
      height: calc(100vh - #{$nav-height * 2 + $nav-height-desktop});
    }
  }

  .rooms-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: $mu100;

    & > * {
      width: 100%;
      max-width: 100%;
      margin: 1rem;

      @include set-from-screen(m) {
        width: calc(50% - 2rem);
        margin: 1rem;
      }

      @include set-from-screen(l-medium) {
        @include set-font-scale('04');

        width: calc(20% - 2rem);
        margin: 0 1rem;
      }

      @include set-from-screen(xl) {
        @include set-font-scale('05');
      }
    }
  }
}

</style>
