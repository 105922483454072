/**
 * Class to edit a paint mask.
 */
 class Amelib {

    static BRUSH_SHAPE_CIRCLE = "circle";
    static BRUSH_SHAPE_SQUARE = "square";

    /**
     * @constructor
     * @param {HTMLCanvasElement} photoCanvas - A canvas containing the photo
     *                                          used by the magic brush.
     * @param {HTMLCanvasElement} maskCanvas - A canvas containing the mask to edit.
     */
    constructor(photoCanvas, maskCanvas) {
        this._photoCanvas = photoCanvas;
        this._maskCanvas = maskCanvas;

        this._brushCanvas = document.createElement("canvas");
        this._brushSize = 32;
        this._brushShape = Amelib.BRUSH_SHAPE_CIRCLE;
        this._magicBrushThreshold = 10;

        this._magicBuffCanvas = document.createElement("canvas");

        this._brushStrokeInitialized = false;
        this._brushStrokePrevX = 0;
        this._brushStrokePrevY = 0;

        this._updateBrush();
    }

    get brushSize() {
        return this._brushSize;
    }

    set brushSize(brushSize) {
        this._brushSize = brushSize;
        this._updateBrush();
    }

    set maskCanvas(maskCanvas) {
        this._maskCanvas = maskCanvas;
    }

    get brushShape() {
        return this._brushShape;
    }

    set brushShape(brushShape) {
        this._brushShape = brushShape;
        this._updateBrush();
    }

    get magicBrushThreshold() {
        return this._magicBrushThreshold;
    }

    set magicBrushThreshold(threshold) {
        this._magicBrushThreshold = threshold;
    }

    /**
     * Notify Amelib that a new brushstroke started at (x, y).
     *
     * @param {Number} x
     * @param {Number} y
     */
    beginBrushstrokeXY(x, y) {
        this._brushStrokeInitialized = false;
        this._brushStrokePrevX = x;
        this._brushStrokePrevY = y;
    }

    /**
     * Draw using the standard brush at (x, y).
     *
     * @param {Number} x
     * @param {Number} y
     */
    brushXY(x, y) {
        const hbs = (this.brushSize / 2) | 0;
        const dx = x - this._brushStrokePrevX;
        const dy = y - this._brushStrokePrevY;
        const maskCtx = this._maskCanvas.getContext("2d");

        if (Math.abs(dx) < 3 && Math.abs(dy) < 3) {
            if (!this._brushStrokeInitialized) {
                this._brushStrokeInitialized = true;
            } else {
                return;
            }
        }

        if (this.brushShape === Amelib.BRUSH_SHAPE_CIRCLE) {
            maskCtx.beginPath();
            maskCtx.lineWidth = this.brushSize;
            maskCtx.moveTo(x - dx, y - dy);
            maskCtx.lineTo(x, y);
            maskCtx.stroke()
        } else if (this.brushShape === Amelib.BRUSH_SHAPE_SQUARE) {
            maskCtx.beginPath();
            maskCtx.moveTo(x - hbs, y - hbs);
            maskCtx.lineTo(x + hbs, y + hbs);
            maskCtx.lineTo(x + hbs - dx, y + hbs - dy);
            maskCtx.lineTo(x - hbs - dx, y - hbs - dy);
            maskCtx.fill();
            maskCtx.beginPath();
            maskCtx.moveTo(x + hbs, y - hbs);
            maskCtx.lineTo(x - hbs, y + hbs);
            maskCtx.lineTo(x - hbs - dx, y + hbs - dy);
            maskCtx.lineTo(x + hbs - dx, y - hbs - dy);
            maskCtx.fill();
        }

        maskCtx.drawImage(
            this._brushCanvas,
            x - hbs,
            y - hbs,
        );

        this._brushStrokePrevX = x;
        this._brushStrokePrevY = y;
    }

    /**
     * Erase the mask at (x, y).
     *
     * @param {Number} x
     * @param {Number} y
     */
    eraserXY(x, y) {
        const maskCtx = this._maskCanvas.getContext("2d");
        maskCtx.save();
        maskCtx.globalCompositeOperation = "destination-out";
        this.brushXY(x, y);
        maskCtx.restore();
    }

    /**
     * Draw using the magic brush at (x, y).
     *
     * @param {Number} x
     * @param {Number} y
     */
    magicBrushXY(x, y) {
        const R = 0;
        const G = 1;
        const B = 2;
        const A = 3;

        const hbs = (this.brushSize / 2) | 0;
        const photoCtx = this._photoCanvas.getContext("2d");
        const photoData = photoCtx.getImageData(x - hbs, y - hbs, this.brushSize, this.brushSize);
        const maskCtx = this._maskCanvas.getContext("2d");
        const maskData = maskCtx.getImageData(x - hbs, y - hbs, this.brushSize, this.brushSize);
        const magicBuffCtx = this._magicBuffCanvas.getContext("2d");

        const refColor = photoCtx.getImageData(x, y, 1, 1).data;
        const minColor = [0, 0, 0, 0];
        const maxColor = [255, 255, 255, 0];
        for (let i in refColor) {
            minColor[i] = Math.max(refColor[i] - this._magicBrushThreshold, 0);
            maxColor[i] = Math.min(refColor[i] + this._magicBrushThreshold, 255);
        }

        function _checkPhotoColorAtIndex(i) {
            return (
                   photoData.data[i + R] <= maxColor[R]
                && photoData.data[i + R] >= minColor[R]
                && photoData.data[i + G] <= maxColor[G]
                && photoData.data[i + G] >= minColor[G]
                && photoData.data[i + B] <= maxColor[B]
                && photoData.data[i + B] >= minColor[B]
            );
        }
        function _xy2i(x, y) { return (y * maskData.width + x) * 4; }
        function _i2x(i) { return (i / 4) % maskData.width; }
        function _i2y(i) { return ((i / 4) / maskData.width) | 0; }

        const stack = [];
        const computed = [];

        stack.push(_xy2i(hbs, hbs))

        while(stack.length > 0) {
            const i = stack.pop();
            const px = _i2x(i);
            const py = _i2y(i);

            maskData.data[i + R] = 0xFF;
            maskData.data[i + G] = 0xFF;
            maskData.data[i + B] = 0xFF;
            maskData.data[i + A] = 0xFF;

            const adjacent = [
                [px, py - 1],
                [px, py + 1],
                [px - 1, py],
                [px + 1, py],
            ];

            for (const aCoord in adjacent) {
                const [ax, ay] = adjacent[aCoord];
                if (
                       ax < 0 || ax > maskData.width
                    || ay < 0 || ay > maskData.height
                ) {
                    continue;
                }
                const ai = _xy2i(ax, ay);
                if (!computed[ai] && _checkPhotoColorAtIndex(ai)) {
                    stack.push(ai);
                }
                computed[ai] = true;
            }
        }

        magicBuffCtx.save();
        magicBuffCtx.putImageData(maskData, 0, 0);
        magicBuffCtx.globalCompositeOperation = "destination-in";
        magicBuffCtx.drawImage(this._brushCanvas, 0, 0);
        magicBuffCtx.restore();

        maskCtx.drawImage(this._magicBuffCanvas, x - hbs, y - hbs);
    }

    /**
     * Generate an highlight for the currently edited mask.
     *
     * NOTE: DO NOT call this function at each draw, it will be resource
     * heavy.
     *
     * @param {String} color - The color of the highlight (default: "#fff").
     * @param {Number} lineWidth - The width of the highlight border (default: 3).
     * @param {Boolean} usePattern - Add a pattern inside the mask (default: false).
     * @param {Number} patternSize - The size of the repeated pattern (default: 5).
     * @param {Number} patternAlpha - The opacity of the pattern (default: 0.5).
     *
     * @return {HTMLCanvasElement}
     */
    generateMaskHighlight(color="#fff", lineWidth=3, usePattern=false, patternSize=5, patternAlpha=0.5) {
        const highlightCanvas = document.createElement("canvas");
        highlightCanvas.width = this._maskCanvas.width;
        highlightCanvas.height = this._maskCanvas.height;
        const highlightCtx = highlightCanvas.getContext("2d");

        const buffCanvas = document.createElement("canvas");
        buffCanvas.width = highlightCanvas.width;
        buffCanvas.height =  highlightCanvas.height;
        const buffCtx = buffCanvas.getContext("2d");

        // Border
        buffCtx.save();

        buffCtx.drawImage(this._maskCanvas, -lineWidth, -lineWidth);
        buffCtx.drawImage(this._maskCanvas, lineWidth, -lineWidth);
        buffCtx.drawImage(this._maskCanvas, -lineWidth, lineWidth);
        buffCtx.drawImage(this._maskCanvas, lineWidth, lineWidth);

        buffCtx.globalCompositeOperation = "xor";
        buffCtx.drawImage(this._maskCanvas, 0, 0);

        buffCtx.restore();
        highlightCtx.save();

        highlightCtx.fillStyle = color;
        highlightCtx.fillRect(0, 0, highlightCanvas.width, highlightCanvas.height);
        highlightCtx.globalCompositeOperation = "destination-in";
        highlightCtx.drawImage(buffCanvas, 0, 0);

        highlightCtx.restore();

        // Pattern
        if (usePattern) {
            const patternCanvas = document.createElement("canvas");
            patternCanvas.width = patternSize;
            patternCanvas.height = patternSize;
            const patternCtx = patternCanvas.getContext("2d");

            patternCtx.strokeStyle = color;
            patternCtx.globalAlpha = patternAlpha;

            patternCtx.beginPath();
            patternCtx.moveTo(0, 0);
            patternCtx.lineTo(patternSize, patternSize);
            patternCtx.stroke();

            patternCtx.beginPath();
            patternCtx.moveTo(-patternSize, 0);
            patternCtx.lineTo(0, patternSize);
            patternCtx.stroke();

            patternCtx.beginPath();
            patternCtx.moveTo(patternSize, 0);
            patternCtx.lineTo(2 * patternSize, patternSize);
            patternCtx.stroke();

            const buffPattern = buffCtx.createPattern(patternCanvas, "repeat");
            buffCtx.fillStyle = buffPattern;
            buffCtx.fillRect(0, 0, buffCanvas.width, buffCanvas.height);
            buffCtx.globalCompositeOperation = "destination-in";
            buffCtx.drawImage(this._maskCanvas, 0, 0);

            highlightCtx.drawImage(buffCanvas, 0, 0);
        }

        return highlightCanvas;
    }

    /**
     * Update the brush drawing.
     *
     * @private
     */
    _updateBrush() {
        this._brushCanvas.width = this.brushSize;
        this._brushCanvas.height = this.brushSize;

        this._magicBuffCanvas.width = this.brushSize;
        this._magicBuffCanvas.height = this.brushSize;

        const brushCtx = this._brushCanvas.getContext("2d");
        brushCtx.fillStyle = "#fff";

        brushCtx.clearRect(0, 0, this._brushCanvas.width, this._brushCanvas.height);

        switch (this.brushShape) {
            case Amelib.BRUSH_SHAPE_CIRCLE:
                brushCtx.beginPath();
                brushCtx.arc(
                    (this.brushSize / 2) | 0,  // x
                    (this.brushSize / 2) | 0,  // y
                    (this.brushSize / 2) | 0,  // radius
                    0,                         // start angle
                    2 * Math.PI,               // end angle
                );
                brushCtx.closePath();
                brushCtx.fill();
                break;
            case Amelib.BRUSH_SHAPE_SQUARE:
                brushCtx.fillRect(0, 0, this.brushSize, this.brushSize);
                break;
        }
    }

}

export default Amelib;
