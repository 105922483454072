var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", [
        _vm._v(
          _vm._s(_vm._("Modification de l'image")) +
            " " +
            _vm._s(_vm.picture.title) +
            " - " +
            _vm._s(_vm._(_vm.PICTURE_ATMOSPHERES[_vm.picture.atmosphere]))
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-group" },
        [
          _c("label", { attrs: { for: "room-selector" } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm._("Catégorie de la pièce")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("v-select", {
            attrs: {
              id: "room-selector",
              options: _vm.categoriesArray,
              value: _vm.category,
              reduce: (category) => category.value,
              label: "label",
            },
            on: {
              input: function ($event) {
                return _vm.updateCategory($event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-group" },
        [
          _c("label", { attrs: { for: "atmosphere-selector" } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm._("Ambiance de la pièce")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("v-select", {
            attrs: {
              id: "atmosphere-selector",
              options: _vm.atmospheresArray,
              value: _vm.picture.atmosphere,
              reduce: (atmoshpere) => atmoshpere.value,
              label: "label",
            },
            on: {
              input: function ($event) {
                return _vm.updateAtmosphere($event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("h4", [_vm._v(_vm._s(_vm._("Couleurs associées")))]),
      _vm._v(" "),
      _vm._l(_vm.pictureColors, function (color, index) {
        return _c(
          "div",
          { key: index, staticClass: "input-group" },
          [
            _c(
              "label",
              { staticClass: "color-label", attrs: { for: "colors-selector" } },
              [
                color.rgbhex
                  ? _c("span", {
                      staticClass: "color-preview",
                      style: `background-color: ${color.rgbhex}`,
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm._(`Couleur ${index + 1}`)))]),
              ]
            ),
            _vm._v(" "),
            _c("v-select", {
              staticClass: "color-selector",
              attrs: {
                id: "color-selector",
                options: _vm.colors,
                value: `${color.name || ""}`,
                label: "name",
              },
              on: {
                input: function ($event) {
                  return _vm.updateColor($event, index)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "option",
                    fn: function (color) {
                      return [
                        _c("div", { staticClass: "color-option" }, [
                          _c("span", {
                            staticClass: "color-preview",
                            style: `background-color: ${color.rgbhex}`,
                          }),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "black" } }, [
                            _vm._v(_vm._s(color.name)),
                          ]),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }