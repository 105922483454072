<template>
    <div :class="['footer',fixed && 'absolute']">
        <span>&#169; {{ _("Mon simulateur couleur Leroy Merlin") }} {{ new Date().getFullYear() }}</span>
        <span class="separator"> - </span>
        <a
            :href="cguUrl"
            target="_blank"
        >{{ _("Crédits et mentions légales") }}</a>
        <span class="separator"> - </span>
        <a
            :href="privacyUrl"
            target="_blank"
        >{{ _("Données personnelles") }}</a>
        <span class="separator"> - </span>
        <a
            href="/CREDITS.txt"
            target="_blank"
        >{{ _("Licences") }}</a>
        <span class="to-the-right">v{{ currentVersion }}</span>
    </div>
</template>

<script>
import self from "../../../index";
import config from "../../../../config";

const {
    stonejs,
} = self.app.modules;

export default {
    name: "Footer",
    props: {
        fixed: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        currentVersion() {
            return config.VERSION;
        },
    },
    methods: {
        _(...args) {
            return stonejs.gettext(...args);
        },

    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.footer {
  display: none;
}

.to-the-right {
    position: absolute;
    right: 0;
    opacity: 0;
    transition: opacity 0.5s;
    transition-delay: 2s;
}

.to-the-right:hover {
    opacity: 1;
    cursor: default;
    transition: opacity 0.3s;
    transition-delay: 0s;
}

@media screen and (min-width: $medium-break-point) {
  .footer {
    position: relative;
    z-index: 2000;
    bottom: 0;
    left: 0;
    width: 100vw;
    background: white;
    height: $footer-height;
    min-height: $footer-height;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-grey-900;
    font-size: 12px;
    border-top: solid 1px $color-grey-200;
    margin-top: auto;

    &.absolute {
      position: absolute;
    }

    > * {
      margin: 0 5px;
    }

    a {
      color: $color-grey-900;
    }
  }
}

</style>
