<template>
    <div class="color-show">
        <ButtonBack />
        <div
            v-if="color !== null"
            class="color"
            :style="{backgroundColor: color.rgbhex}"
        >
            <div class="color-header">
                <p class="font-size-05 font--semi-bold">
                    {{ getDisplayName(color) }}
                </p>

                <ButtonShare
                    mode="SHARE_COLOR"
                    :label="_('Partager')"
                />
            </div>
        </div>
        <div class="color-show-responsive">
            <Carousel
                ref="carousel"
                class="carousel"
            />
            <div class="color-info-container">
                <div class="buy">
                    <div class="buy-wrapper">
                        <ColorAspects :uid="uid" />

                        <aspect-info
                            v-show="getColorAspects.length > 1"
                            :uid="uid"
                            container-class="color-info-wrapper"
                        />
                    </div>
                </div>

                <div class="actions">
                    <router-link
                        class="action btn-border"
                        :to="{ name: 'color_menu' }"
                        @click.native="onSituationTestClick"
                    >
                        <MIconCamera48 class="mc-button__icon" />

                        <span class="mc-button__label">{{ _("Tester sur une autre image et avec d'autres couleurs") }}</span>
                    </router-link>

                    <button
                        class="action btn-border"
                        @click="onShowSuggestedColorsClick"
                    >
                        <MIconPromiseFashionChoice48 class="mc-button__icon" />
                        <span class="mc-button__label">{{ _("Suggestions de couleurs associées") }}</span>
                    </button>
                </div>
                <p class="color-information">
                    {{
                        _("Un doute sur cette couleur ?")
                    }}
                    <br>
                    {{ _("Commandez votre échantillon en ") }}
                    <a
                        v-if="getColorAspects[0]"
                        :href="getAspectUrl(getColorAspects[0])"
                        target="_blank"
                        class="color-sample"
                        data-merc-pos="simulateur-peinture"
                        :data-merch-name="getColorAspects[0].name"
                        data-merch-arbo="simulateur"
                    >
                        <span>
                            {{ getColorAspects[0].name }}
                        </span>
                    </a>
                    <span v-if="getColorAspects[1]"> {{ _("ou") }} </span>
                    <a
                        v-if="getColorAspects[1]"
                        :href="getAspectUrl(getColorAspects[1])"
                        target="_blank"
                        class="color-sample"
                        data-merc-pos="simulateur-peinture"
                        :data-merch-name="getColorAspects[1].name"
                        data-merch-arbo="simulateur"
                    >
                        <span>
                            {{ getColorAspects[1].name }}
                        </span>
                    </a>
                    <span v-if="getColorAspects[2]"> {{ _("ou") }} </span>
                    <a
                        v-if="getColorAspects[2]"
                        :href="getAspectUrl(getColorAspects[2])"
                        target="_blank"
                        class="color-sample"
                        data-merc-pos="simulateur-peinture"
                        :data-merch-name="getColorAspects[2].name"
                        data-merch-arbo="simulateur"
                    >
                        <span>
                            {{ getColorAspects[2].name }}
                        </span>
                    </a>
                    {{ _("pour confirmer votre choix (la couleur réelle peut différer de sa représentation à l'écran).") }}
                </p>

                <color-suggestion
                    :class="isModalOpen && 'open'"
                    :colors-object="color"
                    :color-uid="uid"
                    @close-modal="closeModal"
                />

                <div class="mu-m-100">
                    <color-palette
                        open-by-default
                        visualize-on-click
                    />
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { MIconCamera48, MIconPromiseFashionChoice48 } from "@mozaic-ds/icons/vue/index.vue";
import { convertToRGB, getDisplayName, isLight } from "../../helpers/colorHelper";
import ButtonBack from "../../components/button-back/button-back.vue";
import ButtonShare from "../../components/button-share/button-share.vue";
import Carousel from "../../components/carousel/carousel.vue";
import ColorAspects from "../../components/color-aspects/color-aspects.vue";
import ColorPalette from "../../components/color-palette/color-palette.vue";
import ColorSuggestion from "../../components/color-suggestion/color-suggestion.vue";
import AspectInfo from "../../components/aspect-info/aspect-info.vue";
import EventBus from "../../helpers/event-bus";
import { sendEvent } from "../../services/marketing";
import { getAspectUrl } from "../../services/color";
import self from "../../../index";

const {
    modules,
} = self.app;

export default {
    name: "ColorShow",
    components: {
        MIconCamera48,
        MIconPromiseFashionChoice48,
        ButtonBack,
        ButtonShare,
        Carousel,
        ColorAspects,
        ColorPalette,
        ColorSuggestion,
        AspectInfo,
    },
    data() {
        return {
            isModalOpen: false,
            uid: null,
            color: null,
            getDisplayName,
            openInfo: false,
        };
    },
    computed: {
        aspects() {
            return this.$store.getters["colors/aspects"];
        },
        colors() {
            return this.$store.getters["colors/colors"];
        },
        images() {
            return [
                "../assets/images/pieces/chambre/contemporain/chambre_contemporain_0.jpg",
                "../assets/images/pieces/chambre/industriel/chambre_industriel_0.jpg",
                "../assets/images/pieces/salon/ardeco/salon_ardeco_0.jpg",
                "../assets/images/pieces/salon/industriel/salon_industriel_0.jpg",
                "../assets/images/pieces/salon/boheme/salon_boheme_0.jpg",
            ];
        },
        selectedColor() {
            return this.$store.getters["cartData/color"];
        },
        getColorAspects() {
            return this.$store.getters["colors/getColorAspects"](this.uid);
        },
    },
    watch: {
        $route() {
            this.init();
        },
    },
    beforeDestroy() {
        EventBus.$off("share-link");
        EventBus.$off("download-action");
    },
    created() {
        this.init();
    },
    mounted() {
        EventBus.$on("share-link", (callback) => callback(window.location.href));

        EventBus.$on("download-action", this.download);

        // get good picture ratio
        const screenWidth = window.screen.width;
        const carousel = document.querySelector(".navigation-wrapper");
        if (screenWidth < 1280) {
            carousel.style.height = `${screenWidth * 0.65}px`;
        }
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        onSituationTestClick(event) {
            sendEvent("button.click", {
                button_name: "test on picture",
                cdl_color_name: this.color ? this.color.name : "",
            }, event ? event.target : null);
        },
        getAspectUrl(aspect) {
            const domain = this.getLeroyMerlinDomain();

            return getAspectUrl(aspect, domain);
        },
        removeColor() {
            this.$store.dispatch("cartData/removeColor", this.selectedColor);
        },
        isColorLight() {
            const rgb = convertToRGB(this.color.rgbhex);

            return isLight(rgb);
        },
        init() {
            const { uid } = this.$route.params;
            const found = this.colors[uid];
            if (found) {
                this.uid = uid;
                this.color = found;
            }
        },
        onShowSuggestedColorsClick() {
            this.isModalOpen = true;
            sendEvent("button.click", {
                button_name: "display colors suggested",
                cdl_color_name: this.color ? this.color.name : "",
            });
        },
        closeModal() {
            this.isModalOpen = false;
        },
        download() {
            // access carousel first picture
            const mainCanvas = this.$refs.carousel.$refs.carouselCanvas[0].$refs.canvasImage;

            const aLink = document.createElement("a");
            const preName = this._("Leroy Merlin - Mon simulateur couleur");
            aLink.download = `${preName} - ${getDisplayName(this.color)}.png`;

            const augmentedCanvas = modules.paintinglib.helpers.createCanvasWithPaintList([this.color], mainCanvas);
            aLink.href = augmentedCanvas.toDataURL();
            aLink.click();
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.color {
  padding: $mu100;
  margin-bottom: 4px;

  &-info {
    margin: $mu050 auto 0;
    padding: 0 $mu100;

    @include set-font-scale('02');

    @include set-from-screen(m) {
      padding: 0;
      width: $center-container;

      @include set-font-scale('04');
    }
  }

  &-show {
    height: calc(100vh - #{$nav-height});
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    background: white;

    @media screen and (min-width: $medium-break-point) {
      height: calc(100vh - #{$nav-height-desktop});
    }

    button,
    a {
      &:focus {
        box-shadow: 0 0 0 0.125rem white, 0 0 0 0.25rem $color-primary-01-600 !important;
      }

      &:focus-visible {
        outline: none;
      }
    }

    &-responsive {
      background: white;

      @include set-from-screen(xl) {
        display: flex;
        justify-content: space-around;
        flex: 1;
      }

      & > * {
        &:nth-child(2) {
          @include set-from-screen(xl) {
            max-width: $palette-container-width;
            display: flex;
            flex-direction: column;
            justify-content: center;

            & > * {
              margin-bottom: 2rem;
            }
          }
        }
      }

      .actions {
        position: relative;
        margin: 0 $mu100;
        text-align: center;
        display: flex;
        justify-content: center;
        padding: $mu100 0;
        row-gap: $mu050;
        grid-column-gap: $mu100;
        flex-wrap: wrap;

        @include set-from-screen(m) {
          margin: 0 auto;
          width: $center-container;
        }

        & > * {
          &.action {
            transition: 0.3s;

            &:hover {
              cursor: pointer;
              opacity: 0.7;
            }
          }

          width: 100%;
          background: white;
          color: $color-grey-900;
          border: solid 1px $color-grey-900;
          text-decoration: none;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          padding: $mu025 $mu050 $mu025 $mu300;
          text-align: left;

          @include set-border-radius('m');
          @include set-font-weight('regular');

          svg {
            width: 20px;
            height: 20px;
            position: absolute;
            left: $mu100;
          }

          @include set-from-screen(m) {
            font-size: $mu100;
            padding: $mu100;

            svg {
              width: 30px;
              height: 30px;
              position: relative;
              left: 0;
              margin-left: $mu100;
            }
          }
        }
      }

      .color-information {
        margin: 0 $mu100;

        @include set-font-scale('03');

        @include set-from-screen(m) {
          @include set-font-scale('04');

          width: $center-container;
          margin: auto;
        }

        .color-sample {
          text-decoration: none;
          text-transform: lowercase;
        }
      }
    }

    .carousel {
      flex: 1;
      padding: 0;

      @include set-from-screen(l-medium) {
        max-height: 60vh;
      }

      @include set-from-screen(xl) {
        max-width: calc(100% - 700px);
      }
    }

    .color-info-container {
      padding-bottom: $mu100;

      @include set-from-screen(xl) {
        height: 100%;
      }

      & > *:last-child {
        flex: 1;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .cart-data {
          margin: 0;
        }
      }
    }
  }

  &-header {
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: relative;

    p {
      background: white;
      border-radius: 2pc;
      padding: $mu050 $mu100;
      color: black;
      margin: 0;
    }
  }
}

.buy {
  height: auto;
  padding: $mu100 $mu100 0;
  display: flex;
  margin-bottom: 0 !important;

  &-wrapper {
    width: 100%;
    margin: auto;
    display: inline-flex;
    flex-direction: column;
    grid-column-gap: $mu100;
    grid-row-gap: $mu100;

    @include set-from-screen(m) {
      width: $center-container;
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
      grid-column-gap: $mu100;
      grid-row-gap: $mu100;
      & > * {
        flex: 1 auto;
      }
    }

    .color-info-wrapper {
      width: 100%;
      padding: 0;

      @include set-from-screen(m) {
        width: $center-container;
      }

      .simple-link {
        margin-bottom: $mu100;
        width: 100%;
        text-align: right;

        &:focus {
          box-shadow: none !important;
        }
      }

      &:last-child {
        align-items: flex-end;

        & > * {
          &:first-child {
            text-align: right;
          }
        }
      }
    }
  }
}

</style>
