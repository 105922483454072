<template>
    <div
        v-if="selectedColors.length === 0 && !isColorChartRoute"
        class="cart-data--empty"
    >
        <div class="cart-data-header">
            <p>
                {{ _("Mes couleurs") }}
                <strong>
                    ({{ selectedColors.length }})
                </strong>
            </p>
        </div>
        <div class="cart-data-body">
            <router-link
                :to="{ name: 'color_chart' }"
                data-cerberus="choose-colors"
                class="main-btn"
            >
                <p>{{ _("Choisir mes couleurs") }}</p>
            </router-link>
        </div>
    </div>
    <div
        v-else
        :class="['cart-data',{
            'open': isPaletteOpen,
            'opened-info-color': hasSelectedColor
        }]"
    >
        <div class="cart-data-header">
            <div
                class="arrow-icon cursor"
                :class="isPaletteOpen && 'flip-arrow'"
                data-cerberus="toggle-palette"
                @click="togglePalette"
            >
                <MIconArrowArrowTop24 color="#78be20" />
            </div>

            <p
                class="cursor mr-auto"
                @click="togglePalette"
            >
                {{ _("Mes couleurs") }}
                <strong>
                    ({{ selectedColors.length }})
                </strong>
            </p>

            <div class="header-actions">
                <router-link
                    v-if="showCompare"
                    :to="{ name: 'situation_random' }"
                    class="compare cursor"
                    :title="_('Comparer les couleurs sur une photo')"
                    data-cerberus="compare"
                    @click="onCompareColorClick"
                >
                    <img :src="compareIcon">
                    <p>
                        {{ _("Comparer") }}
                    </p>
                </router-link>

                <div class="show-more">
                    <button
                        class="show-more-btn"
                        :title="_('En voir plus')"
                        data-cerberus="see more"
                        @click="showMore"
                    >
                        <MIconControlCross32
                            class="btn-close"
                            :class="showModal && 'active'"
                            color="#78be20"
                        />
                        <div
                            class="btn-open"
                            :class="!showModal && 'active'"
                        >
                            <MIconDisplayOther32 color="#78be20" />
                            <p>{{ _("Plus") }}</p>
                        </div>
                    </button>
                </div>
            </div>
        </div>

        <div class="colors-selection">
            <div class="colors-selection-wrapper">
                <div
                    v-for="uid in selectedColors"
                    :key="uid"
                    class="colors-selection-list__item"
                    :data-cerberus="colors[uid].rgbhex"
                    @click="onSelectColorClick(uid, $event)"
                >
                    <div
                        :style="{ backgroundColor: colors[uid].rgbhex }"
                        :class="selectedColor === uid && 'active'"
                    >
                        <MIconNotificationAvailable32
                            v-if="selectedColor === uid"
                            :style="{ fill: isColorLight(colors[uid]) ? 'black' : 'white' }"
                        />
                    </div>
                </div>

                <div
                    v-for="n in getColorGhosts(COLORS_LIMIT)"
                    :key="n"
                    class="ghost__item"
                >
                    <div v-if="n===1 && !isColorChartRoute">
                        <router-link
                            data-cerberus="color-chart"
                            :to="{ name: 'color_chart' }"
                        >
                            <MIconControlMore32 color="#554F52" />
                        </router-link>
                    </div>
                    <div v-else />
                </div>
            </div>
        </div>
        <div
            v-if="hasSelectedColor"
            class="color-info"
            :class="[selectedColor && 'show', isPaletteOpen && 'show-parent', isColorLight(colors[selectedColor]) && 'light-color']"
            :style="{
                backgroundColor: colors[selectedColor].rgbhex,
                color: isColorLight(colors[selectedColor]) ? 'black' : 'white',
            }"
        >
            <div>
                <button
                    class="mu-mr-100"
                    data-cerberus="remove-color"
                    @click="onRemoveColorClick"
                >
                    <MIconPublishTrashbin32 />
                </button>
            </div>

            <button
                v-if="!visualizeOnClick"
                class="responsive-btn mu-mr-100"
                data-cerberus="see-color"
                @click="onShowColorClick"
            >
                <MIconDisplayView32 />

                <span class="long">{{ _("Voir la fiche couleur") }} {{ getDisplayName(colors[selectedColor]) }}</span>
                <span class="short">{{ _("Voir") }} {{ getDisplayName(colors[selectedColor]) }}</span>
            </button>

            <router-link
                v-if="isColorizing"
                class="responsive-btn right-btn"
                tag="button"
                data-cerberus="apply"
                :to="{ name: 'color_picture' }"
                @click.native="onApplyColorClick"
            >
                <span class="short">{{ _("Appliquer") }}</span>
                <span class="long">{{ _("Appliquer sur ma photo") }}</span>
            </router-link>
        </div>

        <ColorModal :class="showModal && 'open'" />
    </div>
</template>

<script>
import {
    MIconArrowArrowTop24,
    MIconDisplayOther32,
    MIconPublishTrashbin32,
    MIconControlMore32,
    MIconNotificationAvailable32,
    MIconDisplayView32,
    MIconControlCross32,
} from "@mozaic-ds/icons/vue/index.vue";
import ColorModal from "../color-modal/color-modal.vue";
import constants from "../../constants";
import { getDisplayName, convertToRGB, isLight } from "../../helpers/colorHelper";
import { sendEvent } from "../../services/marketing";
import compareIcon from "../../../../../assets/images/icons/compare.svg";
import self from "../../../index";

const {
    modules,
} = self.app;

export default {
    name: "ColorPalette",
    components: {
        ColorModal,
        MIconArrowArrowTop24,
        MIconDisplayOther32,
        MIconPublishTrashbin32,
        MIconControlMore32,
        MIconNotificationAvailable32,
        MIconDisplayView32,
        MIconControlCross32,
    },
    props: {
        visualizeOnClick: {
            type: Boolean,
            required: false,
            default: false,
        },
        openByDefault: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            getDisplayName,
            COLORS_LIMIT: constants.COLORS_LIMIT,
            showModal: false,
            compareIcon,
        };
    },
    computed: {
        isColorChartRoute() {
            return this.$route.name === "color_chart";
        },
        colors() {
            return this.$store.getters["colors/colors"];
        },
        isPaletteOpen() {
            return this.$store.getters["cartData/isPaletteOpen"];
        },
        selectedColor() {
            return this.$store.getters["cartData/color"];
        },
        hasSelectedColor() {
            return this.$store.getters["cartData/hasColor"];
        },
        selectedColors() {
            return this.$store.getters["cartData/colors"];
        },
        hasImageCustomer() {
            return this.$store.getters["image/hasImage"];
        },
        hasImageGallery() {
            return this.$store.getters["imagesGallery/hasImageSelected"];
        },
        isColorizing() {
            return (this.hasImageCustomer || this.hasImageGallery) && this.$route.name === "color_chart";
        },
        showCompare() {
            return this.selectedColors.length >= 2 && this.$route.name !== "situation_random";
        },
    },
    created() {
        if (this.openByDefault) {
            this.openPalette();
        } else {
            this.closePalette();
        }
    },
    methods: {
        onApplyColorClick(event) {
            const color = this.colors[this.selectedColor];
            sendEvent("button.click", {
                button_name: "test on picture",
                feature_name: "color palette",
                cdl_color_name: color ? color.name : "",
            }, event ? event.target : null);
        },
        onCompareColorClick(event) {
            sendEvent("button.click", {
                button_name: "compare colors",
                feature_name: "color palette",
            }, event ? event.target : null);
        },
        onShowColorClick(event) {
            const color = this.colors[this.selectedColor];
            this.$router.push({ name: "color_show", params: { uid: this.selectedColor } });
            sendEvent("button.click", {
                button_name: "see color card",
                feature_name: "color_palette",
                cdl_color_name: color ? color.name : "",
            }, event ? event.target : null);

        },
        onRemoveColorClick(event) {
            const color = this.colors[this.selectedColor];
            this.$store.dispatch("cartData/removeColor", this.selectedColor);
            sendEvent("button.click", {
                button_name: "delete color",
                feature_name: "color_palette",
                cdl_color_name: color ? color.name : "",
            }, event ? event.target : null);

        },
        onSelectColorClick(uid) {
            this.$store.commit("cartData/SELECT_COLOR", uid);

            if (this.visualizeOnClick) {
                this.onShowColorClick();
            }
            sendEvent("element.select", {
                element_category: "color palette",
                element_name: "color selected",
                element_value: this.colors[uid].name,
                feature_name: "color_palette",
            });
        },
        getColorGhosts(num) {
            return num - this.$store.getters["cartData/colors"].length;
        },
        togglePalette() {
            this.showModal = false;
            if (this.isPaletteOpen) {
                this.closePalette();
            } else {
                this.openPalette();
                const colorShowContainer = document.querySelector(".color-show");
                if (colorShowContainer) {
                    setTimeout(() => {
                        colorShowContainer.scrollTo({ top: colorShowContainer.scrollHeight + 200, bottom: 0, behavior: "smooth" });
                    }, 300);
                }
            }
        },
        openPalette() {
            this.$store.dispatch("cartData/openPalette");
        },
        closePalette() {
            this.$store.dispatch("cartData/closePalette");
        },
        isColorLight(color) {
            const rgb = convertToRGB(color.rgbhex);

            return isLight(rgb);
        },
        showMore() {
            this.showModal = !this.showModal;
            this.$emit("palette-is-open", this.showModal);
        },
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

$color-sample-size--screen: 60px;
$color-sample-size--tablet: 44px;
$color-sample-size--mobile: 40px;

.cursor:hover {
  cursor: pointer;
}

.arrow-icon {
  @extend .cursor;

  transform: scaleY(1);
  transition: 0.2s ease-in-out;

  &.flip-arrow {
    transform: scaleY(-1);
  }
}
%cart-data {
  transition: 0.3s ease-in-out;
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  box-sizing: border-box;
  box-shadow: 0 2px 20px rgba(black, 0.6);
  border-radius: 4px;
  margin: auto;
  width: 100%;

  @include set-font-scale("04");
  @include set-font-weight('regular');

  color: $color-grey-900;

  @include set-from-screen(m) {
    width: 600px;
  }
}

.cart-data {
  @extend %cart-data;

  height: 50px;

  &--empty {
    @extend %cart-data;

    padding-bottom: $mu100;

    & > * {
      &:first-child {
        border-bottom: solid 1px $color-grey-200;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
      }

      &:last-child {
        display: flex;
        justify-content: center;

        a {
          background: $color-primary-01-500;
          padding: 0 $mu100;
          color: white;
          border-radius: 4px;
          text-decoration: none;

          @include set-font-scale('04');

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }

  &-wrapper {
    display: flex;
  }

  .colors {
    &-selection {
      position: relative;
      padding-bottom: 0;
      z-index: 21;
      background: white;

      &::before {
        transition: 0.3s;
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background: transparent;
        top: -$mu025;
        left: 0;
      }

      &-list {
        position: relative;
        top: 0;
        width: 100%;
        display: flex;
        box-sizing: border-box;
        height: $color-sample-size--screen;
        grid-column-gap: 10px;

        @media screen and (max-width: 680px) {
          height: $color-sample-size--tablet;
        }

        @media screen and (max-width: 390px) {
          height: $color-sample-size--mobile;
          grid-column-gap: 5px;
        }

        &__item {
          @extend .cursor;

          height: $color-sample-size--screen;
          width: $color-sample-size--screen;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;

          @media screen and (max-width: 680px) {
            height: $color-sample-size--tablet;
            width: $color-sample-size--tablet;
          }

          @media screen and (max-width: 390px) {
            height: $color-sample-size--mobile;
            width: $color-sample-size--mobile;
          }

          & > * {
            border: 2px solid white;
            box-shadow: 0 0 0 2px white;

            @extend %color-item;

            &:not(.active) {
              border: 1px solid $color-grey-400;
            }

            &.active {
              box-shadow: 0 0 0 2px $color-grey-700;
            }
          }
        }
      }

      &-wrapper {
        display: flex;
        transition: 0.3s;
        height: 0;
        opacity: 0;
        overflow: hidden;
        width: 430px;
        margin: auto;
        position: relative;
        justify-content: space-between;

        @media screen and (max-width: 680px) {
          width: 350px;
        }

        @media screen and (max-width: 390px) {
          width: 100%;
          padding: 0 $mu050;
        }

        .ghost__item {
          box-sizing: border-box;
          height: $color-sample-size--screen;
          width: $color-sample-size--screen;
          display: flex;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: 680px) {
            height: $color-sample-size--tablet;
            width: $color-sample-size--tablet;
          }

          @media screen and (max-width: 390px) {
            height: $color-sample-size--mobile;
            width: $color-sample-size--mobile;
          }

          & > * {
            background: transparent;
            border: dashed 1px $color-grey-400 !important;

            a {
              transition: 0.3s;

              &:focus {
                box-shadow: none !important;
              }

              &:hover {
                opacity: 0.6;
              }
            }

            @extend %color-item;
          }
        }
      }
    }
  }

  &.open {
    height: 105px;

    @include set-from-screen(m) {
      height: 120px;
    }

    .colors-selection {
      padding-bottom: $mu050;

      &::before {
        background: $color-grey-200;
      }
    }

    .colors-selection-wrapper {
      height: $color-sample-size--screen;
      max-height: 100px;
      opacity: 1;

      @media screen and (max-width: 680px) {
        height: $color-sample-size--tablet;
      }

      @media screen and (max-width: 390px) {
        height: $color-sample-size--mobile;
      }
    }

    &.opened-info-color {
      height: 162px;

      @media screen and (max-width: 680px) {
        height: 146px;
      }

      @media screen and (max-width: 390px) {
        height: 142px;
      }
    }
  }

  %color-item {
    border-radius: 50%;
    width: 80%;
    height: 80%;
    position: relative;
    border: 2px solid white;
    box-shadow: 0 0 0 2px white;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 26px;
      height: 26px;

      @media screen and (max-width: 680px) {
        width: 22px;
        height: 22px;
      }

      @media screen and (max-width: 390px) {
        width: 18px;
        height: 18px;
      }
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $mu100;
    text-align: center;
    background: white;
    position: relative;
    z-index: 21;
    border-radius: 4px;

    .mr-auto {
      margin-right: auto;
      margin-left: $mu100;
    }

    strong {
      color: $color-primary-01-500;
    }

    .header-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .compare {
      color: $color-grey-900;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: $mu100;

      @include set-font-scale("04");
      @include set-font-weight('regular');

      &:focus {
        box-shadow: none !important;
      }

      img {
        width: 30px;
      }

      p {
        margin-left: 5px;
      }

      @media screen and (max-width: 450px) {
        p {
          display: none;
        }
      }
    }

    .show-more {
      position: relative;
      margin-left: $mu100;

      &-btn {
        transition: 0.3s;
        box-sizing: content-box;

        @extend .cursor;

        border: none;
        background: transparent;
        height: 40px;
        width: 67px;

        @media screen and (max-width: 350px) {
          width: auto;
        }

        &:focus {
          border: none;
          outline: none;
          box-shadow: none !important;
        }

        svg {
          top: 0;
          right: 0;
          position: absolute;
          width: 40px;
          height: 100%;
          transition: 0.3s;
          transform-origin: center;
          box-sizing: border-box;
        }

        .btn-open {
          transition: 0.3s all, transform 0s;
          transform-origin: right center;
          transform: scale(0);
          display: flex;
          align-items: center;
          justify-content: flex-end;

          svg {
            position: relative;
          }

          p {
            margin: 0;
            opacity: 0;
            color: $color-grey-900;

            @include set-font-scale("04");
            @include set-font-weight('regular');

            @media screen and (max-width: 350px) {
              display: none;
            }
          }

          &.active {
            transform: scale(1);

            p {
              opacity: 1;
            }
          }
        }

        .btn-close {
          transition: 0.3s;
          transform-origin: center;
          padding: $mu050;
          transform: scale(0);

          &.active {
            transform: scale(1);
          }
        }
      }
    }
  }

  .color-info {
    position: relative;
    z-index: 21;
    transition: 0.3s;
    height: 0;
    border-radius: 0 0 4px 4px;
    padding: $mu025;
    display: flex;
    justify-content: flex-start;
    opacity: 0;
    overflow: hidden;
    width: 100%;

    &.light-color {
      border-top: solid 1px  $color-grey-200;

      button {
        border: solid 1px $color-grey-200;
      }
    }

    & > div {
      display: flex;
    }

    .responsive-btn {
      display: flex;
      align-items: center;
      height: 34px;

      &.right-btn {
        border-color: $color-primary-01-800;
        color: $color-primary-01-800;
        margin-left: auto;

        & > span {
          margin-right: 0;
        }
      }

      .short {
        display: none;
      }

      @media screen and (max-width: 500px) {
        .short {
          display: inline-block;
        }

        .long {
          display: none;
        }
      }

      & > * {
        &:first-child {
          margin-right: $mu050;
        }

        &:last-child {
          @include set-font-scale('04');
        }

        @media screen and (max-width: 500px) {
          &:last-child {
            @include set-font-scale('03');
          }
        }

        @media screen and (max-width: 400px) {
          &:last-child {
            @include set-font-scale('01');
          }
        }
      }
    }

    button {
      background: white;
      border-radius: 4px;
      border: solid 1px white;
      align-self: center;
      color: $color-grey-900;
      padding: $mu025;
      transition: 0.3s;

      @extend .cursor;

      svg {
        width: 24px;
        height: 24px;
      }

      &:hover {
        opacity: 0.6;
      }
    }

    &.show-parent.show {
      height: 46px;
      align-items: center;
      display: flex;
      margin: $mu100 - $mu100 0 !important;
      opacity: 1;
    }
  }
}
</style>
