var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "color-show" },
    [
      _c("ButtonBack"),
      _vm._v(" "),
      _vm.color !== null
        ? _c(
            "div",
            {
              staticClass: "color",
              style: { backgroundColor: _vm.color.rgbhex },
            },
            [
              _c(
                "div",
                { staticClass: "color-header" },
                [
                  _c("p", { staticClass: "font-size-05 font--semi-bold" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.getDisplayName(_vm.color)) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("ButtonShare", {
                    attrs: { mode: "SHARE_COLOR", label: _vm._("Partager") },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "color-show-responsive" },
        [
          _c("Carousel", { ref: "carousel", staticClass: "carousel" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "color-info-container" },
            [
              _c("div", { staticClass: "buy" }, [
                _c(
                  "div",
                  { staticClass: "buy-wrapper" },
                  [
                    _c("ColorAspects", { attrs: { uid: _vm.uid } }),
                    _vm._v(" "),
                    _c("aspect-info", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.getColorAspects.length > 1,
                          expression: "getColorAspects.length > 1",
                        },
                      ],
                      attrs: {
                        uid: _vm.uid,
                        "container-class": "color-info-wrapper",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "actions" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "action btn-border",
                      attrs: { to: { name: "color_menu" } },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onSituationTestClick.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("MIconCamera48", { staticClass: "mc-button__icon" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "mc-button__label" }, [
                        _vm._v(
                          _vm._s(
                            _vm._(
                              "Tester sur une autre image et avec d'autres couleurs"
                            )
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "action btn-border",
                      on: { click: _vm.onShowSuggestedColorsClick },
                    },
                    [
                      _c("MIconPromiseFashionChoice48", {
                        staticClass: "mc-button__icon",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "mc-button__label" }, [
                        _vm._v(
                          _vm._s(_vm._("Suggestions de couleurs associées"))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "color-information" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._("Un doute sur cette couleur ?")) +
                    "\n                "
                ),
                _c("br"),
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._("Commandez votre échantillon en ")) +
                    "\n                "
                ),
                _vm.getColorAspects[0]
                  ? _c(
                      "a",
                      {
                        staticClass: "color-sample",
                        attrs: {
                          href: _vm.getAspectUrl(_vm.getColorAspects[0]),
                          target: "_blank",
                          "data-merc-pos": "simulateur-peinture",
                          "data-merch-name": _vm.getColorAspects[0].name,
                          "data-merch-arbo": "simulateur",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.getColorAspects[0].name) +
                              "\n                    "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.getColorAspects[1]
                  ? _c("span", [_vm._v(" " + _vm._s(_vm._("ou")) + " ")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.getColorAspects[1]
                  ? _c(
                      "a",
                      {
                        staticClass: "color-sample",
                        attrs: {
                          href: _vm.getAspectUrl(_vm.getColorAspects[1]),
                          target: "_blank",
                          "data-merc-pos": "simulateur-peinture",
                          "data-merch-name": _vm.getColorAspects[1].name,
                          "data-merch-arbo": "simulateur",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.getColorAspects[1].name) +
                              "\n                    "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.getColorAspects[2]
                  ? _c("span", [_vm._v(" " + _vm._s(_vm._("ou")) + " ")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.getColorAspects[2]
                  ? _c(
                      "a",
                      {
                        staticClass: "color-sample",
                        attrs: {
                          href: _vm.getAspectUrl(_vm.getColorAspects[2]),
                          target: "_blank",
                          "data-merc-pos": "simulateur-peinture",
                          "data-merch-name": _vm.getColorAspects[2].name,
                          "data-merch-arbo": "simulateur",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.getColorAspects[2].name) +
                              "\n                    "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._(
                        "pour confirmer votre choix (la couleur réelle peut différer de sa représentation à l'écran)."
                      )
                    ) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("color-suggestion", {
                class: _vm.isModalOpen && "open",
                attrs: { "colors-object": _vm.color, "color-uid": _vm.uid },
                on: { "close-modal": _vm.closeModal },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mu-m-100" },
                [
                  _c("color-palette", {
                    attrs: { "open-by-default": "", "visualize-on-click": "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }