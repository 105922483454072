import "core-js/fn/array/flat-map";

import obsidian from "@obsidianjs/obsidian";
import stonejs from "@obsidianjs/stonejs";
import httpRequest from "@obsidianjs/http-request";
import { datadogRum } from "@datadog/browser-rum";

import InternationalizationUtility from "./modules/internationalization-utility";
import Vuejs from "./modules/vuejs";
import PaintingLib from "./modules/painting-lib";
import pjson from "../package.json";

import "./style/index.scss";
// Import config
import config from "./modules/config";

if (config.NODE_ENV !== "dev") {
    datadogRum.init({
        applicationId: "3a3c83c0-01b1-4070-afca-123508a63643",
        clientToken: "pube645bb66d312aea70b7b0b1e15c77ebd",
        site: "datadoghq.eu",
        service: "monsimulateurcouleur",
        env: config.NODE_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: pjson.version,
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
    });
}

datadogRum.startSessionReplayRecording();

const app = obsidian("starter-app");

// Internationalization modules
app.use(stonejs);
app.use(InternationalizationUtility);
// ////

app.use(httpRequest);
app.use(Vuejs);
app.use(PaintingLib);

app.start();
