var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isLoading
      ? _c("div", [_c("Loader")], 1)
      : _vm.hasError
      ? _c("div", [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._(
                  "Désolé une erreur est survenue lors de la récupération des peintures."
                )
              ) +
              "\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { attrs: { id: "obsidian-app" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }