/**
 * image store module is storing custom image the user uploaded :
 * - @property {String} image is a blob of the uploaded image.
 */
export default {
    namespaced: true,
    state: {
        image: null,
    },
    getters: {
        image(state) {
            return state.image;
        },
        hasImage(state) {
            return state.image !== null;
        },
    },
    mutations: {
        "UPDATE_IMAGE"(state, image) {
            state.image = image;
        },
        "REMOVE_IMAGE"(state) {
            state.image = null;
        },
    },
};
