import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/home.vue";
import ColorChart from "../views/color-chart/color-chart.vue";
import ColorMenu from "../views/color-menu/color-menu.vue";
import Rooms from "../views/rooms/rooms.vue";
import Room from "../views/room/room.vue";
import SituationPerso from "../views/situation-perso/situation-perso.vue";
import SituationRandom from "../views/situation-random/situation-random.vue";
import ColorPicture from "../views/color-picture/color-picture.vue";
import ShoppingCart from "../views/shopping-cart/shopping-cart.vue";
import ColorShow from "../views/color-show/color-show.vue";
import ImageShare from "../views/image-share/image-share.vue";
import LegalNotices from "../views/legal-notices/legal-notices.vue";
import Admin from "../views/admin/admin.vue";
import AdminLogin from "../views/admin/admin-login/admin-login.vue";
import AdminStandBy from "../views/admin/admin-stand-by/admin-stand-by.vue";
import AdminColorManager from "../views/admin/admin-color-manager/admin-color-manager.vue";
import AdminPictureManager from "../views/admin/admin-picture-manager/admin-picture-manager.vue";
import store from "../store/index";
import { sendPageView } from "../services/marketing";
import { isAuthenticated, isOAuthenticated } from "../helpers/authHelper";
import self from "../../index";

import config from "../../../config";

function _(...args) {
    return self.app.modules.stonejs.gettext(...args);
}

Vue.use(VueRouter);

const appRoutes = [
    {
        path: "/",
        name: "index",
        component: Home,
        meta: () => ({
            btnBackLabel: _("Retour à l'accueil"),
            pageName: "color simulator homepage",
            pageType: "color simulator",
            pageSubType: "homepage",
            pageCategory1: "",
            pageCategory2: "",
            pageCategory3: "",
            pageCategory4: "",
        }),
    },
    {
        path: "/menu",
        name: "color_menu",
        component: ColorMenu,
        meta: () => ({
            btnBackLabel: _("Retour au menu"),
            pageName: "colorisation method",
            pageType: "color simulator",
            pageSubType: "colorise photo",
            pageCategory1: "colorise photo",
            pageCategory2: "",
            pageCategory3: "",
            pageCategory4: "",
        }),
    },
    {
        path: "/nuancier",
        name: "color_chart",
        component: ColorChart,
        meta: () => ({
            btnBackLabel: _("Retour au nuancier"),
            pageName: "color chart",
            pageType: "color simulator",
            pageSubType: "color chart",
            pageCategory1: "",
            pageCategory2: "",
            pageCategory3: "",
            pageCategory4: "",
        }),
    },
    {
        path: "/pieces",
        name: "rooms",
        component: Rooms,
        meta: () => ({
            btnBackLabel: _("Retour au choix de pièce"),
            pageName: "room choice",
            pageType: "color simulator",
            pageSubType: "colorise photo",
            pageCategory1: "colorise photo",
            pageCategory2: "photo from our gallery",
            pageCategory3: "",
            pageCategory4: "",
        }),
    },
    {
        path: "/pieces/:categoryName",
        name: "room",
        component: Room,
        meta: (route) => ({
            btnBackLabel: _("Retour au choix d'ambiance"),
            pageName: "mood choice",
            pageType: "color simulator",
            pageSubType: "colorise photo",
            pageCategory1: "colorise photo",
            pageCategory2: "photo from our gallery",
            pageCategory3: route.params ? route.params.categoryName : "",
            pageCategory4: "",
        }),
    },
    {
        path: "/situation-perso",
        name: "situation_perso",
        component: SituationPerso,
        meta: () => ({
            btnBackLabel: _("Retour au choix d'image"),
            pageName: "download picture",
            pageType: "color simulator",
            pageSubType: "colorise photo",
            pageCategory1: "colorise photo",
            pageCategory2: "download picture",
            pageCategory3: "",
            pageCategory4: "",
        }),
    },
    {
        path: "/situation-random",
        name: "situation_random",
        component: SituationRandom,
        meta: () => ({
            btnBackLabel: _("Retour à la comparaison de couleurs"),
            pageName: "color comparison",
            pageType: "color simulator",
            pageSubType: "color comparison",
            pageCategory1: "",
            pageCategory2: "",
            pageCategory3: "",
            pageCategory4: "",
        }),
    },
    {
        path: "/coloriser",
        name: "color_picture",
        component: ColorPicture,
        meta: (route) => {
            let pageCategory2 = "download picture";
            let pageCategory3 = "color picture";
            let pageCategory4 = "";
            if (route.query && route.query.room && route.query.mood) {
                pageCategory2 = "photo from our gallery";
                pageCategory3 = route.query.room;
                pageCategory4 = route.query.mood;
            }
            return {
                btnBackLabel: _("Retour à la colorisation d'image"),
                pageName: "colorise photo",
                pageType: "color simulator",
                pageSubType: "colorise photo",
                pageCategory1: "colorise photo",
                pageCategory2,
                pageCategory3,
                pageCategory4,
            };
        },
    },
    {
        path: "/panier",
        name: "shopping_cart",
        component: ShoppingCart,
        meta: () => ({
            btnBackLabel: _("Retour à ma liste de couleurs"),
            pageName: "colors list",
            pageType: "color simulator",
            pageSubType: "colors list",
            pageCategory1: "",
            pageCategory2: "",
            pageCategory3: "",
            pageCategory4: "",
        }),
    },
    {
        path: "/couleur/:uid",
        name: "color_show",
        component: ColorShow,
        meta: (route) => {
            let colorName = "";
            if (route.params && route.params.uid) {
                const color = store.getters["colors/colors"][route.params.uid];
                if (color) {
                    colorName = color.name;
                }
            }
            return {
                btnBackLabel: _("Retour à la fiche couleur"),
                pageName: `color card_${colorName}`,
                pageType: "color simulator",
                pageSubType: "color card",
                pageCategory1: "color card",
                pageCategory2: colorName,
                pageCategory3: "",
                pageCategory4: "",
            };
        },
    },
    {
        path: "/partage/:uid/:colors?",
        name: "image_share",
        component: ImageShare,
        meta: () => ({
            btnBackLabel: _("Retour à l'image partagée"),
        }),
    },
    {
        path: "/palette/:uids",
        name: "palette",
        redirect: (to) => {
            const colors = to.params.uids.split("_");
            localStorage.setItem("colors", JSON.stringify(colors));

            return { name: "shopping_cart" };
        },
    },
    {
        path: "/mentions-legales",
        name: "legal_notices",
        component: LegalNotices,
        meta: () => ({
            btnBackLabel: _("Retour au mentions légales"),
        }),
    },
];

const backofficeRoutes = [
    {
        path: "/leroy-merlin-admin",
        component: Admin,
        children: [
            {
                component: AdminLogin,
                path: "",
                name: "admin_login",
            },
            {
                component: AdminStandBy,
                path: "check",
                name: "admin_stand_by",
            },
            {
                name: "admin_color_manager",
                component: AdminColorManager,
                path: "color-manager",
                meta: () => ({
                    isAuthRequired: true,
                }),
            },
            {
                name: "admin_picture_manager",
                component: AdminPictureManager,
                path: "picture-manager",
                meta: () => ({
                    isAuthRequired: true,
                }),
            },
        ],
    },
];

const routes = appRoutes.concat(backofficeRoutes, [
    {
        path: "*",
        redirect: { name: "index" },
    },
]);

const router = new VueRouter({
    mode: "history",
    routes: routes,
});

router.beforeEach(async (to, from, next) => {
    // custom router history
    store.commit("history/PUSH_ROUTE", to);

    // handle routes that require authentication
    const isAuthRequired = to.matched.some(
        (record) => {
            if (typeof record.meta === "function") {
                const meta = record.meta(record);

                return meta.isAuthRequired;
            }
            return false;
        }
    );
    if (isAuthRequired) {
        const valid = await isAuthenticated();
        if (!valid) {
            next({ name: "admin_login" });
        }
    }

    if (to.name === "admin_login") {
        const valid = await isAuthenticated();
        if (valid) {
            next({ name: "admin_color_manager" });
        } else if (config.OAUTH_ENABLED === "true") {
            const validOauth = await isOAuthenticated();
            if (!validOauth) {
                // redirect to oauth login (get on auth/login)
                next({ name: "admin_stand_by" });
                window.location.href = "/oauth/login";
            }
        }
    }

    next();
});

router.afterEach((to) => {
    // analytics
    const meta = to.meta ? to.meta(to) : null;
    if (meta && meta.pageName) {
        sendPageView(
            meta.pageName,
            meta.pageType,
            meta.pageSubType,
            meta.pageCategory1,
            meta.pageCategory2,
            meta.pageCategory3,
            meta.pageCategory4,
        );
    }
});

export default router;
