var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ButtonBack"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "color-menu-wrapper" },
        [
          _c("div", { staticClass: "situation-perso__header" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm._("Sélectionnez la méthode qui vous convient"))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cards" },
            [
              _c(
                "card-style-picto",
                {
                  attrs: { "data-cerberus": "upload-picture" },
                  on: {
                    click: function ($event) {
                      return _vm.goToLink("situation_perso")
                    },
                  },
                },
                [
                  _c("div", [_c("MIconDownloadApp64")], 1),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm._("Télécharger votre photo")))]),
                ]
              ),
              _vm._v(" "),
              _c(
                "card-style-picto",
                {
                  attrs: { "data-cerberus": "chose-picture" },
                  on: {
                    click: function ($event) {
                      return _vm.goToLink("rooms")
                    },
                  },
                },
                [
                  _c("div", [_c("MIconInspiration64")], 1),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm._("Choisir une photo de notre galerie"))),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("Footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }