<template>
    <div class="navigation-wrapper">
        <div
            ref="slider"
            class="keen-slider"
        >
            <div
                v-for="(image, index) in imagesSort"
                :key="index"
                class="keen-slider__slide"
            >
                <CarouselCanvas
                    ref="carouselCanvas"
                    :image="image"
                    :load="current === index || (current + 1) === index"
                />
            </div>
        </div>

        <div
            v-if="slider"
            :class="{
                arrow: true,
                'arrow--left': true,
            }"
            @click="previous"
        >
            <MIconArrowArrowLeft48 :fill="isPrevDisabled ? '#887f87' : '#222020'" />
        </div>

        <div
            v-if="slider"
            :class="{
                arrow: true,
                'arrow--right': true,
            }"
            @click="next"
        >
            <MIconArrowArrowRight48 :fill="isNextDisabled ? '#887f87' : '#222020'" />
        </div>
    </div>
</template>

<script>
import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";
import { MIconArrowArrowLeft48, MIconArrowArrowRight48 } from "@mozaic-ds/icons/vue/index.vue";
import self from "../../../index";
import CarouselCanvas from "./carousel-canvas.vue";
import EventBus from "../../helpers/event-bus";
import {
    PICTURE_CATEGORY_BEDROOM,
    PICTURE_CATEGORY_BEDROOM_KID,
    PICTURE_CATEGORY_LIVINGROOM,
    PICTURE_CATEGORY_OFFICE,
    PICTURE_CATEGORY_ENTRANCE,
} from "../../helpers/constants";

const {
    modules,
} = self.app;

export default {
    name: "Carousel",
    components: {
        MIconArrowArrowLeft48,
        MIconArrowArrowRight48,
        CarouselCanvas,
    },
    data() {
        return {
            current: 0,
            slider: null,
            changing: false,
            canvasLoadedTitle: [],
            imagesSort: [],
            imageCategoryOrder: [
                PICTURE_CATEGORY_LIVINGROOM,
                PICTURE_CATEGORY_BEDROOM,
                PICTURE_CATEGORY_OFFICE,
                PICTURE_CATEGORY_BEDROOM_KID,
                PICTURE_CATEGORY_ENTRANCE,
            ],
        };
    },
    computed: {
        isNextDisabled() {
            return this.current === this.slider.details().size - 1 || this.changing;
        },
        isPrevDisabled() {
            return this.current === 0 || this.changing;
        },
        images() {
            return this.$store.getters["imagesGallery/imagesGeneric"];
        },
    },
    mounted() {
        // Canvas needs to be loaded before slider
        EventBus.$on("carousel-canvas-loaded", (imageTitle) => {
            this.canvasLoadedTitle.push(imageTitle);
            if (this.canvasLoadedTitle.length === this.images.length) {
                this.slider = new KeenSlider(this.$refs.slider, {
                    initial: this.current,
                    move: () => {
                        this.changing = true;
                    },
                    afterChange: (s) => {
                        this.current = s.details().relativeSlide;
                        this.changing = false;
                    },
                });
            }
        });
        this.sortImages();
    },
    beforeDestroy() {
        if (this.slider) this.slider.destroy();
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        previous() {
            if (!this.isPrevDisabled) {
                this.slider.prev();
            }
        },
        next() {
            if (!this.isNextDisabled) {
                this.slider.next();
            }
        },
        sortImages() {
            this.imagesSort = [...this.images];
            this.imagesSort.sort((a, b) => (this.imageCategoryOrder.indexOf(a.categoryName) - this.imageCategoryOrder.indexOf(b.categoryName)));
        },
    },
};

</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.navigation-wrapper {
  position: relative;
}

.keen-slider {
  height: 100%;

  &__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    @media screen and (max-width: 1100px) {
      & > * {
        width: 100%;
        display: flex;
        justify-content: center;

        canvas {
          height: auto;
        }
      }
    }
  }
}

.arrow {
  background: white;
  position: absolute;
  top: 50%;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(black, 0.3);

  svg {
    width: 50%;
    height: 50%;
  }
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}
</style>
