var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !!_vm.lastRoute
    ? _c("div", { staticClass: "button-back" }, [
        _c(
          "div",
          {
            staticClass: "simple-link",
            attrs: { "data-cerberus": "back-button" },
            on: { click: _vm.goBack },
          },
          [
            _c("MIconArrowArrowLeft24", { attrs: { color: "#78be20" } }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.backButtonLabel))]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }