<template>
    <div class="colorize">
        <div class="colorize-picture">
            <PaintCanvas :tool="tool" />
        </div>
        <div class="colorize-info">
            <div class="colorize-actions">
                <p>{{ _(toolMessage) }}</p>
                <div class="colorize-actions__buttons">
                    <button
                        :class="tool === 'FILL' && 'active'"
                        :title="_(PAINTING_TOOL_FILL_MESSAGE)"
                        data-cerberus="remplissage"
                        @click="switchTool('FILL', $event)"
                    >
                        <img
                            :src="colorizeIcon"
                            :alt="_('icon coloriser')"
                        >
                        <span>{{ _("Remplissage") }}</span>
                    </button>
                    <button
                        :class="tool === 'PAINT' && 'active'"
                        :title="_(PAINTING_TOOL_PAINT_MESSAGE)"
                        data-cerberus="paint-tool"
                        @click="switchTool('PAINT', $event)"
                    >
                        <img
                            :src="penIcon"
                            :alt="_('icon stylo')"
                        >
                        <span>{{ _("Pinceau") }}</span>
                    </button>
                    <button
                        :class="tool === 'ERASE' && 'active'"
                        :title="_(PAINTING_TOOL_ERASE_MESSAGE)"
                        data-cerberus="erase-tool"
                        @click="switchTool('ERASE', $event)"
                    >
                        <img
                            :src="eraseIcon"
                            :alt="_('icon effacer')"
                        >
                        <span>{{ _("Gomme") }}</span>
                    </button>
                    <button
                        v-if="mode === 'gallery'"
                        :class="highlight && 'active'"
                        :title="_(PAINTING_TOOL_HIGHLIGHT_MESSAGE)"
                        data-cerberus="zone-tool"
                        @click="highlightArea"
                    >
                        <img
                            :src="frameIcon"
                            :alt="_('icon zone')"
                        >
                        <span>{{ _("Zones") }}</span>
                    </button>
                    <button
                        :title="_(PAINTING_TOOL_BACK_MESSAGE)"
                        data-cerberus="back-tool"
                        @click="goBack"
                    >
                        <img
                            :src="returnIcon"
                            :alt="_('icon retour arrière')"
                        >
                        <span>{{ _("Retour") }}</span>
                    </button>
                </div>
            </div>

            <div
                v-if="mode === 'gallery' && imageGallery.colors.length"
                class="suggested-colors"
            >
                <button
                    class="btn-liner"
                    data-cerberus="style-suggestion"
                    @click="onShowSuggestedColorsClick"
                >
                    {{ _("Afficher les couleurs suggérées par nos stylistes") }}
                </button>

                <colorize-suggestion
                    :class="isModalOpen && 'open'"
                    :colors-object="imageGallery.colors"
                    @close-modal="closeModal"
                />
            </div>

            <div class="color-palette-wrapper">
                <color-palette
                    open-by-default
                />
            </div>
        </div>
    </div>
</template>

<script>
import PaintCanvas from "./paint-canvas.vue";
import EventBus from "../../helpers/event-bus";
import ColorPalette from "../color-palette/color-palette.vue";
import ColorizeSuggestion from "../colorize-suggestion/colorize-suggestion.vue";

import self from "../../../index";
import colorizeIcon from "../../../../../assets/images/icons/colorize.svg";
import penIcon from "../../../../../assets/images/icons/pen.svg";
import frameIcon from "../../../../../assets/images/icons/frame.svg";
import eraseIcon from "../../../../../assets/images/icons/erase.svg";
import returnIcon from "../../../../../assets/images/icons/return.svg";
import { convertToRGB, isLight } from "../../helpers/colorHelper";
import {
    PAINTING_TOOL_FILL_MESSAGE,
    PAINTING_TOOL_PAINT_MESSAGE,
    PAINTING_TOOL_ERASE_MESSAGE,
    PAINTING_TOOL_BACK_MESSAGE,
    PAINTING_TOOL_HIGHLIGHT_MESSAGE,
} from "../../helpers/constants";
import { sendEvent } from "../../services/marketing";

const {
    modules,
} = self.app;

export default {
    name: "Colorize",
    components: {
        PaintCanvas,
        ColorPalette,
        ColorizeSuggestion,
    },
    data() {
        return {
            tool: "",
            highlight: true,
            isModalOpen: false,
            colorizeIcon,
            penIcon,
            eraseIcon,
            frameIcon,
            returnIcon,
            toolMessage: "",
            PAINTING_TOOL_FILL_MESSAGE,
            PAINTING_TOOL_PAINT_MESSAGE,
            PAINTING_TOOL_ERASE_MESSAGE,
            PAINTING_TOOL_BACK_MESSAGE,
            PAINTING_TOOL_HIGHLIGHT_MESSAGE,
        };
    },
    computed: {
        selectedColor() {
            return this.$store.getters["cartData/color"];
        },
        colors() {
            return this.$store.getters["colors/colors"];
        },
        imageCustomer() {
            return this.$store.getters["image/image"];
        },
        imageGallery() {
            return this.$store.getters["imagesGallery/imageSelected"];
        },
        cartColors() {
            return this.$store.getters["cartData/colors"];
        },
        mode() {
            if (this.imageCustomer) {
                return "customer";
            }
            if (this.imageGallery) {
                return "gallery";
            }
            return "";
        },
    },
    created() {
        EventBus.$on("disable-highlight", () => {
            this.highlight = false;
        });
    },
    mounted() {
        if (this.cartColors.length > 0) {
            this.tool = "FILL";
            this.toolMessage = PAINTING_TOOL_FILL_MESSAGE;
            document.getElementsByTagName("canvas")[0].style.cursor = `url(${this.colorizeIcon}) 16 16, auto`;
        }
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        switchTool(tool, event) {
            this.tool = tool;
            switch (tool) {
                case "PAINT":
                    this.toolMessage = PAINTING_TOOL_PAINT_MESSAGE;
                    document.getElementsByTagName("canvas")[0].style.cursor = `url(${this.penIcon}) 16 16, auto`;
                    this.sendToolClickEvent("paintbrush", event);
                    break;
                case "ERASE":
                    this.toolMessage = PAINTING_TOOL_ERASE_MESSAGE;
                    document.getElementsByTagName("canvas")[0].style.cursor = `url(${this.eraseIcon}) 16 16, auto`;
                    this.sendToolClickEvent("eraser", event);
                    break;
                case "FILL":
                    this.toolMessage = PAINTING_TOOL_FILL_MESSAGE;
                    document.getElementsByTagName("canvas")[0].style.cursor = `url(${this.colorizeIcon}) 16 16, auto`;
                    this.sendToolClickEvent("filling", event);
                    break;
                default:
                    this.toolMessage = PAINTING_TOOL_FILL_MESSAGE;
                    document.getElementsByTagName("canvas")[0].style.cursor = "initial";
            }
        },
        sendToolClickEvent(name, event) {
            const color = this.colors[this.selectedColor];
            sendEvent("button.click", {
                button_name: name,
                cdl_color_name: color ? color.name : "",
            }, event ? event.target : null);
        },
        goBack() {
            const prevMessage = this.toolMessage;
            this.toolMessage = PAINTING_TOOL_BACK_MESSAGE;
            EventBus.$emit("cancel-action-canvas", "go back");
            setTimeout(() => {
                this.toolMessage = prevMessage;
            }, 4000);
        },
        highlightArea() {
            EventBus.$emit("highlight-area-canvas");
            this.toolMessage = PAINTING_TOOL_HIGHLIGHT_MESSAGE;
            this.highlight = !this.highlight;
            sendEvent("button.click", {
                button_name: "zoning",
                button_location: "colorise photo",
            });
        },
        isColorLight(itemColor) {
            const rgb = convertToRGB(itemColor);

            return isLight(rgb);
        },
        onShowSuggestedColorsClick() {
            this.isModalOpen = !this.isModalOpen;
            sendEvent("button.click", {
                button_name: "share my picture",
            });
        },
        closeModal() {
            this.isModalOpen = false;
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

$canvas-height: 60vh;

.colorize {
  min-height: calc(100vh - #{$nav-height * 3});
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 850px) {
    min-height: calc(100vh - #{$nav-height * 3 + $footer-height});
  }

  @include set-from-screen(l-medium) {
    min-height: calc(100vh - #{$nav-height * 2 + $nav-height-desktop + $footer-height});
    flex-direction: row;
  }

  @include set-from-screen(xl) {
    display: flex;
    justify-content: space-around;
    height: calc(100% - #{$footer-height});
  }

  &-picture {
    background: white;
    position: relative;
    flex: 1;
    z-index: 1;
    margin-bottom: $mu100;
    display: flex;
    align-items: stretch;

    @include set-from-screen(m) {
      display: block;
      align-items: inherit;
    }

    @include set-from-screen(l-medium) {
      min-height: 400px;
    }

    @include set-from-screen(xl) {
      max-width: calc(100% - #{$palette-container-width});
      max-height: initial;
      height: 100%;
      background: transparent;
      margin-bottom: 0;
    }

    img {
      display: block;
      margin: auto;
    }

    .canvas-container {
      position: relative;
      max-width: 100%;
      display: flex;
      align-items: center;
      width: 100%;

      @include set-from-screen(m) {
        height: 400px;
        width: auto;
      }

      @include set-from-screen(xl) {
        height: 100%;
      }

      canvas {
        display: block;
        margin: auto;
        width: 100%;

        @include set-from-screen(m) {
          width: inherit;
          height: inherit;
        }

        @include set-from-screen(xl) {
          height: auto;
          width: auto;
        }
      }
    }
  }

  &-info {
    @include set-from-screen(xl) {
      max-width: $palette-container-width;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > *:last-child {
        flex: 1;
        display: flex;
        align-items: flex-end;

        .cart-data {
          margin: 0;
        }
      }
    }
  }

  &-actions {
    background: white;
    max-width: $palette-width;
    margin: 0 auto;
    padding: 0 $mu100;

    @include set-from-screen(xl) {
      background: transparent;
      max-width: $palette-container-width;
      margin: 0 $mu100;
      padding: 0;
    }

    p {
      margin-top: 0;

      @include set-font-scale('03');

      @include set-from-screen(m) {
        @include set-font-scale('04');
      }
    }

    &__buttons {
      justify-content: center;
      display: flex;

      @include set-from-screen(xl) {
        justify-content: end;
        max-width: $center-container;
        width: 100%;
      }

      @include set-from-screen(s-large) {
        width: 100%;
        max-width: 100%;
      }

      @include set-from-screen(xl) {
        display: flex;
      }

      span {
        @include set-font-scale('01');

        transition: 0.3s;

        @include set-from-screen(s-xlarge) {
          display: block;

          @include set-font-scale('04');
        }
      }

      button {
        border-radius: 0;
        border: solid 1px grey;
        border-right: none;
        transition: 0.3s;
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:nth-child(3) {
          border-right: solid 1px grey;
          border-radius: 0 4px 4px 0;
        }

        &:nth-child(4) {
          border-right: solid 1px grey;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          margin-left: $mu050;
        }

        &:last-child {
          border: solid 1px grey;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        @include set-from-screen(m) {
          &:nth-child(4) {
            margin-left: $mu100;
          }
        }

        @include set-from-screen(xl) {
          border: solid 1px grey;
          min-height: 50px !important;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          margin-bottom: $mu100;
          padding: $mu050 $mu100;
        }

        img {
          max-height: 22px;
          max-width: 22px;

          @include set-from-screen(m) {
            max-height: 30px;
            max-width: 30px;
          }
        }

        &.active {
          background: $color-grey-700;

          img {
            filter: brightness(10);
          }

          span {
            color: white;
          }
        }

        &:hover {
          cursor: pointer;

          img,
          span {
            opacity: 0.6;
          }
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }
  }

  .suggested-colors {
    box-sizing: border-box;
    width: 100%;
    padding: 0 $mu100;
    margin: 0 auto;
    max-width: $palette-width;

    .btn-liner {
      background: transparent;
      padding: $mu100 0 $mu050;
      border: none;
      text-decoration: underline;

      @include set-from-screen(m) {
        padding: $mu100 0;
      }

      &:hover {
        cursor: pointer;
      }
    }

    @include set-from-screen(xl) {
      width: $palette-width;
      padding: 0;
    }

    &__title {
      @include set-font-scale('04');

      margin-bottom: $mu050;
    }

    &-container {
      display: flex;
      justify-content: center;
      grid-column-gap: $mu100;

      & > * {
        flex: 1;
        max-width: 25%;

        .color {
          height: 30px;

          @include set-from-screen(s-xlarge) {
            height: 50px;
          }
        }
      }
    }
  }
}

</style>
