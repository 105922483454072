<template>
    <div>
        <canvas ref="canvasImage" />
    </div>
</template>

<script>
import pica from "pica";
import self from "../../../index";
import EventBus from "../../helpers/event-bus";
import { MOBILE_WIDTH } from "../../helpers/constants";

const {
    modules,
} = self.app;

export default {
    name: "CarouselCanvas",
    props: {
        image: {
            type: Object,
            required: true,
        },
        load: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            maskCanvas: document.createElement("canvas"),
            colorCanvas: document.createElement("canvas"),
            photoCanvas: document.createElement("canvas"),
            masksImage: [],
            loaded: false,
        };
    },
    computed: {
        colors() {
            return this.$store.getters["colors/colors"];
        },
        currentColor() {
            return this.colors[this.$route.params.uid].rgbhex;
        },
        needLoad() {
            return this.load && !this.loaded;
        },
        isMobile() {
            return window.screen.availWidth <= MOBILE_WIDTH;
        },
    },
    watch: {
        load() {
            if (this.needLoad) {
                this.colorizeCanvas();
            }
        },
        async $route() {
            await new Promise((resolve) => {
                this.loaded = false;
                resolve();
            });
            if (this.needLoad) {
                this.colorizeCanvas();
            }
        },
    },
    async mounted() {
        this.masksImage = await this.initFillingMasks();
        await this.initCanvas();
        EventBus.$emit("carousel-canvas-loaded", this.image.title);
        if (this.needLoad) {
            this.colorizeCanvas();
        }
    },
    methods: {
        initFillingMasks() {
            const promises = this.image.masks.map((path) => new Promise((resolve) => {
                const imageMask = new Image();
                imageMask.src = `/${path}`;
                imageMask.onload = () => {
                    resolve(imageMask);
                };
            }));
            return Promise.all(promises);
        },
        initCanvas() {
            return new Promise((resolve) => {
                const image = new Image();
                image.src = `/${this.image.image}`;
                image.onload = () => {
                    // Create a canvas width raw image
                    const imageCanvas = document.createElement("canvas");
                    imageCanvas.width = image.width;
                    imageCanvas.height = image.height;
                    const imageCanvasCtx = imageCanvas.getContext("2d");
                    imageCanvasCtx.drawImage(image, 0, 0, imageCanvas.width, imageCanvas.height);

                    const mainCanvas = this.$refs.canvasImage;
                    const maxHeight = this.$parent.$refs.slider.clientHeight;
                    let canvasHeight = image.height;
                    let canvasWidth = image.width;

                    if (image.height > maxHeight) {
                        const aspectRatio = (canvasWidth / canvasHeight);
                        canvasWidth = maxHeight * aspectRatio;
                        canvasHeight = maxHeight;
                    }

                    if (this.isMobile) {
                        mainCanvas.width = canvasWidth * 2;
                        mainCanvas.height = canvasHeight * 2;
                        mainCanvas.style.width = `${canvasWidth}px`;
                        mainCanvas.style.height = `${canvasHeight}px`;
                    } else {
                        mainCanvas.width = canvasWidth;
                        mainCanvas.height = canvasHeight;
                    }

                    this.maskCanvas.width = mainCanvas.width;
                    this.maskCanvas.height = mainCanvas.height;

                    this.colorCanvas.width = mainCanvas.width;
                    this.colorCanvas.height = mainCanvas.height;

                    this.photoCanvas.width = mainCanvas.width;
                    this.photoCanvas.height = mainCanvas.height;

                    pica().resize(imageCanvas, mainCanvas, {
                        alpha: true,
                        unsharpAmount: 150,
                        transferable: true,
                    })
                        .then(() => {
                            const mainCvansCtx = mainCanvas.getContext("2d");
                            this.originalPictureData = mainCvansCtx.getImageData(0, 0, mainCanvas.width, mainCanvas.height);

                            const photoCanvas = this.photoCanvas.getContext("2d");
                            photoCanvas.putImageData(this.originalPictureData, 0, 0);

                            resolve();
                        });
                };
            });
        },
        async colorizeCanvas() {
            await this.initMaskAndColorCanvas();
            const average = modules.paintinglib.computeAverageLuminosityImageFromMask(this.$refs.canvasImage, this.maskCanvas);
            await modules.paintinglib.colorizeFull(this.colorCanvas, [this.currentColor], average);
            this.draw();
            this.loaded = true;
        },
        initMaskAndColorCanvas() {
            return new Promise((resolve) => {
                const maskCtx = this.maskCanvas.getContext("2d");
                this.masksImage.forEach((mask) => {
                    maskCtx.drawImage(mask, 0, 0, this.maskCanvas.width, this.maskCanvas.height);
                });

                const mainCanvas = this.$refs.canvasImage;
                const mainCanvasImage = mainCanvas.getContext("2d").getImageData(0, 0, mainCanvas.width, mainCanvas.height);
                const colorCtx = this.colorCanvas.getContext("2d");
                colorCtx.putImageData(mainCanvasImage, 0, 0);
                resolve();
            });
        },
        draw() {
            const mainCanvas = this.$refs.canvasImage;
            const mainCtx = mainCanvas.getContext("2d");
            const workCanvas = document.createElement("canvas");
            workCanvas.width = mainCanvas.width;
            workCanvas.height = mainCanvas.height;
            mainCtx.drawImage(this.photoCanvas, 0, 0);

            const workCtx = workCanvas.getContext("2d");

            workCtx.globalCompositeOperation = "source-over";
            workCtx.drawImage(this.colorCanvas, 0, 0);

            workCtx.globalCompositeOperation = "destination-in";
            workCtx.drawImage(this.maskCanvas, 0, 0);

            mainCtx.drawImage(workCanvas, 0, 0);
        },
    },
};

</script>

<style lang="scss">
</style>
