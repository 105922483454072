var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shop-card" },
    [
      _c("div", { staticClass: "shop-card__header" }, [
        _c("div", {
          staticClass: "shop-card__header-color",
          style: {
            backgroundColor: _vm.color.rgbhex,
            border: _vm.isColorLight(_vm.color[_vm.uid]) && "solid 1px #d3d2d6",
          },
        }),
        _vm._v(" "),
        _c("div", [
          _c("p", [
            _vm._v(
              "\n                " +
                _vm._s(_vm.getDisplayName(_vm.color)) +
                "\n            "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn-remove", on: { click: _vm.onRemoveColorClick } },
          [_c("MIconPublishTrashbin48")],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "shop-card__body" },
        [
          _c("ColorAspects", {
            attrs: { uid: _vm.uid, "container-class": "shop-card__btn-group" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("aspect-info", {
        attrs: { "container-class": "shop-card__footer", uid: _vm.uid },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }