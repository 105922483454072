var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "navbar" },
    [
      _c(
        "router-link",
        { staticClass: "brand-logo", attrs: { to: { name: "index" } } },
        [
          _c("img", { attrs: { src: _vm.logo } }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm._("Mon simulateur couleur")))]),
        ]
      ),
      _vm._v(" "),
      _vm.isAdmin
        ? _c("div", [
            _c(
              "div",
              { staticClass: "nav-collapse", class: _vm.isNavOpen && "show" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-collapse__item",
                    attrs: {
                      to: { name: "admin_color_manager" },
                      "data-cerberus": "color-manager",
                    },
                  },
                  [
                    _c("div", { on: { click: _vm.closeNav } }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm._("Gestionnaire de couleurs"))),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "nav-collapse__item",
                    attrs: {
                      to: { name: "admin_picture_manager" },
                      "data-cerberus": "picture-manager",
                    },
                  },
                  [
                    _c("div", { on: { click: _vm.closeNav } }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm._("Gestionnaire d'images"))),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn-logout",
                    attrs: {
                      type: "button",
                      "data-cerberus": "logout",
                      title: _vm._("Déconnexion"),
                    },
                    on: { click: _vm.logout },
                  },
                  [
                    _c("MIconDisplayConnect32"),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm._("Déconnexion")))]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-toggle-menu",
                class: _vm.isNavOpen && "show",
                attrs: { "data-cerberus": "toogle-menu-1" },
                on: { click: _vm.toggleNav },
              },
              [
                _c("MIconControlCross32", {
                  staticClass: "close",
                  attrs: { color: "#78be20" },
                }),
                _vm._v(" "),
                _c("MIconDisplayMenu32", {
                  staticClass: "open",
                  attrs: { color: "#78be20" },
                }),
              ],
              1
            ),
          ])
        : _c(
            "div",
            [
              _c(
                "router-link",
                {
                  staticClass: "btn-shop",
                  class: _vm.isNavOpen && "hide",
                  attrs: {
                    to: { name: "shopping_cart" },
                    "data-cerberus": "shopping-cart-1",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "basket-icon-container",
                      class: _vm.selectedColors.length > 0 && "active",
                      attrs: { "data-palette-num": _vm.selectedColors.length },
                    },
                    [
                      _c("MIconBasketStandard32", {
                        attrs: { color: "#554f52" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "nav-collapse", class: _vm.isNavOpen && "show" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-collapse__item",
                      attrs: {
                        to: { name: "color_chart" },
                        "data-cerberus": "explore-color-chart",
                      },
                    },
                    [
                      _c("div", { on: { click: _vm.closeNav } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm._("Explorer le nuancier"))),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-collapse__item",
                      attrs: {
                        to: { name: "color_menu" },
                        "data-cerberus": "paint-picture",
                      },
                    },
                    [
                      _c("div", { on: { click: _vm.closeNav } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm._("Coloriser une photo"))),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-collapse__item legal-notices",
                      attrs: {
                        to: { name: "legal_notices" },
                        "data-cerberus": "legals",
                      },
                    },
                    [
                      _c("div", { on: { click: _vm.closeNav } }, [
                        _c("span", [_vm._v(_vm._s(_vm._("Mentions légales")))]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-collapse__item",
                      attrs: {
                        to: { name: "shopping_cart" },
                        "data-cerberus": "shopping-cart-2",
                      },
                    },
                    [
                      _c("div", { on: { click: _vm.closeNav } }, [
                        _c(
                          "div",
                          {
                            staticClass: "basket-icon-container",
                            class: _vm.selectedColors.length > 0 && "active",
                            attrs: {
                              "data-palette-num": _vm.selectedColors.length,
                            },
                          },
                          [_c("MIconBasketStandard32")],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm._("Ma liste de couleurs"))),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn-toggle-menu",
                  class: _vm.isNavOpen && "show",
                  attrs: { "data-cerberus": "toogle-menu-2" },
                  on: { click: _vm.toggleNav },
                },
                [
                  _c("MIconControlCross32", {
                    staticClass: "close",
                    attrs: { color: "#78be20" },
                  }),
                  _vm._v(" "),
                  _c("MIconDisplayMenu32", {
                    staticClass: "open",
                    attrs: { color: "#78be20" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }