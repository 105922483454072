function getResizedCanvas(image, canvasHandler) {
    let w = 0;
    let h = 0;

    if (image.width >= image.height) {
        const ratio = image.width / image.height;
        w = canvasHandler.clientWidth;
        h = w / ratio;
    }

    return { w, h };
}

function getCoordsFromCanvas(canvas, event) {
    const touchEvents = ["touchmove", "touchstart"];
    const rect = canvas.getBoundingClientRect();

    const { clientX, clientY } = touchEvents.includes(event.type) ? (
        event.changedTouches[0]
    ) : (
        event
    );

    const x = clientX - rect.left;
    const y = clientY - rect.top;

    return { x, y };
}

function showCanvas(canvas) {
    canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        window.open(url);
    });
}

function vhToPx(value) {
    const doc = document.documentElement;
    const body = document.getElementsByTagName("body")[0];
    const y = window.innerHeight || doc.clientHeight || body.clientHeight;

    return (y * value) / 100;
}

/**
 * Convert a white and black canvas mask to a white and transparent canvas mask
 * @param  {canvas} mask [description]
 * @return {[type]}      [description]
 */
function convertWBMaskToTransparent(mask) {
    const maskCtx = mask.getContext("2d");
    const imageData = maskCtx.getImageData(0, 0, mask.width, mask.height);
    for (let i = 0, c = imageData.data.length; i < c; i += 4) {
        if (imageData.data[i] < 10) {
            imageData.data[i] = 0;
            imageData.data[i + 1] = 0;
            imageData.data[i + 2] = 0;
            imageData.data[i + 3] = 0;
        }
        // we could set else to black but it would impact performance
    }
    maskCtx.putImageData(imageData, 0, 0);
    return mask;
}

function getHighlightSettings() {
    return [
        {
            color: "#fff",
            lineWidth: 3,
            usePattern: true,
            patternSize: 5,
            patternAlpha: 0.5,
        },
        {
            color: "#fba1a1",
            lineWidth: 3,
            usePattern: true,
            patternSize: 5,
            patternAlpha: 0.5,
        },
        {
            color: "#acfba1",
            lineWidth: 3,
            usePattern: true,
            patternSize: 5,
            patternAlpha: 0.5,
        },
        {
            color: "#a8a1fb",
            lineWidth: 3,
            usePattern: true,
            patternSize: 5,
            patternAlpha: 0.5,
        },
    ];
}

export {
    getResizedCanvas,
    getCoordsFromCanvas,
    showCanvas,
    vhToPx,
    convertWBMaskToTransparent,
    getHighlightSettings,
};
