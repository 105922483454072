<template>
    <div id="shop" class="shop">
        <div class="shop-header">
            <div>
                <ButtonBack />
            </div>
            <div class="shop-header__bottom">
                <p>
                    {{ _("Ma liste de couleurs") }}
                    <span v-show="selectedColors.length > 0 ">({{ selectedColors.length }})</span>
                </p>
                <ButtonShare
                    mode="SHARE_PALETTE"
                    :label="_('Partager')"
                />
            </div>
        </div>
        <div class="shop-body">
            <div v-if="selectedColors.length > 0">
                <div
                    v-for="uid in selectedColors"
                    :key="uid"
                >
                    <ShoppingCartItem
                        :uid="uid"
                        :color="colors[uid]"
                    />
                </div>
            </div>
            <div
                v-else
                class="shop-body--empty"
            >
                <div>
                    <p>{{ _("Vous n'avez pas encore de couleur dans votre liste") }}</p>
                    <router-link
                        :to="{ name: 'color_chart' }"
                    >
                        <m-button
                            :label="_('Ajouter une couleur')"
                        />
                    </router-link>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import ButtonBack from "../../components/button-back/button-back.vue";
import ShoppingCartItem from "../../components/shopping-cart-item/shopping-cart-item.vue";
import self from "../../../index";
import ButtonShare from "../../components/button-share/button-share.vue";
import EventBus from "../../helpers/event-bus";

const {
    modules,
} = self.app;

export default {
    name: "ShoppingCart",
    components: {
        ButtonShare,
        ButtonBack,
        ShoppingCartItem,
    },
    computed: {
        colors() {
            return this.$store.getters["colors/colors"];
        },
        selectedColors() {
            return this.$store.getters["cartData/colors"];
        },
    },
    mounted() {
        EventBus.$on("download-action", () => {
            this.download();
        });

        EventBus.$on("share-link-palette", (callback) => {
            const colors = this.selectedColors.join("_");
            const fullPath = `/palette/${colors}`;
            const url = `${window.location.protocol}//${window.location.host}${fullPath}`;
            callback(url, fullPath);
        });
    },
    beforeDestroy() {
        EventBus.$off("share-link-palette");
        EventBus.$off("download-action");
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        download() {
            const aLink = document.createElement("a");
            aLink.download = `${this._("Ma liste de couleurs")}.png`;

            // Get list of cart colors with color infos
            const colorList = [];
            this.selectedColors.forEach((color) => {
                colorList.push({
                    name: this.colors[color].name,
                    rgbhex: this.colors[color].rgbhex,
                });
            });

            const augmentedCanvas = modules.paintinglib.helpers.createCanvasWithPaintList(colorList);
            aLink.href = augmentedCanvas.toDataURL();
            aLink.click();
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.shop {
  height: calc(100vh - #{$nav-height});
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $medium-break-point) {
    height: calc(100vh - #{$nav-height});
  }

  @include set-from-screen(l-medium) {
    height: calc(100vh - #{$nav-height-desktop});
  }

  .footer {
    position: relative !important;
  }

  &-header {
    background: white;
    border-bottom: solid 1px $color-grey-200;
    display: flex;
    flex-direction: column;

    .share-container {
      right: $mu100;
    }

    & > * {
      height: $nav-height;
      border-bottom: solid 1px $color-grey-200;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-child {
        padding: 0 $mu150;
      }
    }

    .btn-shadow {
      border: none;
      background: white;
      box-shadow: 0 1px 8px rgba(black, 0.2);
      border-radius: 4px;

      svg {
        fill: $color-grey-800;
        width: 24px;
        height: 24px;
      }
    }

    &__bottom {
      position: relative;

      p {
        @include set-font-scale('05');
        @include set-font-weight('semi-bold');
      }

      button > p {
        @include set-font-weight('regular');
      }

      span {
        color: $color-primary-01-600;
      }
    }
  }

  &-body {
    background: $color-grey-100;
    flex: 1;

    & > div {
      display: flex;
      flex-wrap: wrap;

      & > * {
        width: 100%;

        @include set-from-screen(m) {
          width: 50%;
          border-bottom: none;
        }

        @include set-from-screen(l-medium) {
          width: 33.33%;
        }

        @include set-from-screen(xl-large) {
          width: 25%;
        }
      }
    }

    &--empty {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        background: white;
        border-radius: 4px;
        padding: $mu150;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          @include set-font-scale('06');

          margin-bottom: $mu200;
          text-align: center;
        }
      }

      a {
        text-transform: none;
        text-decoration: none !important;
      }
    }
  }
}

</style>
