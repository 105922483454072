var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["colors__item", { active: _vm.isColorSelected() }],
      attrs: { id: _vm.uid },
      on: { click: _vm.onColorClick },
    },
    [
      _c(
        "div",
        {
          style: {
            backgroundColor: _vm.color.rgbhex,
            color: _vm.isColorLight() ? "black" : "white",
            border: _vm.isColorLight() && "solid 1px #a19ba2",
          },
        },
        [
          _c(
            "div",
            [
              _c("MIconNotificationCircleAvailable32", {
                class: [
                  _vm.isColorLight() ? "" : "light",
                  _vm.isColorSelected() ? "show" : "hide",
                ],
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "\n            " +
                _vm._s(_vm.getDisplayName(_vm.color)) +
                "\n        "
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }