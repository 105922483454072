var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "div",
        [
          _c("Nav"),
          _vm._v(" "),
          _vm.isLoading
            ? _c("div", [_c("Loader")], 1)
            : _vm.hasError
            ? _c("div", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._(
                        "Désolé une erreur est survenue lors de la récupération des peintures."
                      )
                    ) +
                    "\n        "
                ),
              ])
            : _c("div", [_c("router-view")], 1),
        ],
        1
      ),
      _vm._v(" "),
      _c("Overlay"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }