const getVariable = (name, ...defaultValues) =>
    window.colorSimulator[name] || defaultValues.find((e) => e);

export default {
    VERSION: getVariable("VERSION", process.env.VERSION),
    DATADOME_JS_KEY: getVariable(
        "DATADOME_JS_KEY",
        process.env.DATADOME_JS_KEY
    ),
    NODE_ENV: getVariable("NODE_ENV", process.env.NODE_ENV, "production"),
    USER_DISABLE_UPLOAD_IMAGES: getVariable(
        "USER_DISABLE_UPLOAD_IMAGES",
        process.env.USER_DISABLE_UPLOAD_IMAGES
    ),
    SENTRY_DSN: getVariable("SENTRY_DSN", process.env.SENTRY_DSN, ""),
    SENTRY_ENV: getVariable("SENTRY_ENV", process.env.SENTRY_ENV, ""),
    GA_ENV: getVariable("GA_ENV", process.env.GA_ENV, ""),
    OAUTH_ENABLED: getVariable(
        "OAUTH_ENABLED",
        process.env.OAUTH_ENABLED,
        "false"
    ),
    ADMIN_DISABLE_UPLOAD_IMAGES: getVariable(
        "ADMIN_DISABLE_UPLOAD_IMAGES",
        process.env.ADMIN_DISABLE_UPLOAD_IMAGES
    ),
    ADMIN_EXTERNAL_DOMAINS_WHITELIST: getVariable(
        "ADMIN_EXTERNAL_DOMAINS_WHITELIST",
        process.env.ADMIN_EXTERNAL_DOMAINS_WHITELIST,
        ""
    ),
    FR_FR_ADMIN_EXTERNAL_DOMAINS_WHITELIST: getVariable(
        "FR_FR_ADMIN_EXTERNAL_DOMAINS_WHITELIST",
        process.env.FR_FR_ADMIN_EXTERNAL_DOMAINS_WHITELIST
    ),
    IT_IT_ADMIN_EXTERNAL_DOMAINS_WHITELIST: getVariable(
        "IT_IT_ADMIN_EXTERNAL_DOMAINS_WHITELIST",
        process.env.IT_IT_ADMIN_EXTERNAL_DOMAINS_WHITELIST
    ),
    ES_ES_ADMIN_EXTERNAL_DOMAINS_WHITELIST: getVariable(
        "ES_ES_ADMIN_EXTERNAL_DOMAINS_WHITELIST",
        process.env.ES_ES_ADMIN_EXTERNAL_DOMAINS_WHITELIST
    ),
    EN_GB_ADMIN_EXTERNAL_DOMAINS_WHITELIST: getVariable(
        "EN_GB_ADMIN_EXTERNAL_DOMAINS_WHITELIST",
        process.env.EN_GB_ADMIN_EXTERNAL_DOMAINS_WHITELIST
    ),
    PT_PT_ADMIN_EXTERNAL_DOMAINS_WHITELIST: getVariable(
        "PT_PT_ADMIN_EXTERNAL_DOMAINS_WHITELIST",
        process.env.PT_PT_ADMIN_EXTERNAL_DOMAINS_WHITELIST
    ),
    PL_PL_ADMIN_EXTERNAL_DOMAINS_WHITELIST: getVariable(
        "PL_PL_ADMIN_EXTERNAL_DOMAINS_WHITELIST",
        process.env.PL_PL_ADMIN_EXTERNAL_DOMAINS_WHITELIST
    ),
    GCU_ENDPOINT: getVariable(
        "GCU_ENDPOINT",
        process.env.GCU_ENDPOINT,
        "/cgu-cgv.html"
    ),
    FR_FR_GCU_ENDPOINT: getVariable(
        "FR_FR_GCU_ENDPOINT",
        process.env.FR_FR_GCU_ENDPOINT
    ),
    IT_IT_GCU_ENDPOINT: getVariable(
        "IT_IT_GCU_ENDPOINT",
        process.env.IT_IT_GCU_ENDPOINT
    ),
    ES_ES_GCU_ENDPOINT: getVariable(
        "ES_ES_GCU_ENDPOINT",
        process.env.ES_ES_GCU_ENDPOINT
    ),
    EN_GB_GCU_ENDPOINT: getVariable(
        "EN_GB_GCU_ENDPOINT",
        process.env.EN_GB_GCU_ENDPOINT
    ),
    PT_PT_GCU_ENDPOINT: getVariable(
        "PT_PT_GCU_ENDPOINT",
        process.env.PT_PT_GCU_ENDPOINT
    ),
    PL_PL_GCU_ENDPOINT: getVariable(
        "PL_PL_GCU_ENDPOINT",
        process.env.PL_PL_GCU_ENDPOINT
    ),
    PRIVACY_ENDPOINT: getVariable(
        "PRIVACY_ENDPOINT",
        process.env.PRIVACY_ENDPOINT,
        "/cgu-cgv.html"
    ),
    FR_FR_PRIVACY_ENDPOINT: getVariable(
        "FR_FR_PRIVACY_ENDPOINT",
        process.env.FR_FR_PRIVACY_ENDPOINT
    ),
    IT_IT_PRIVACY_ENDPOINT: getVariable(
        "IT_IT_PRIVACY_ENDPOINT",
        process.env.IT_IT_PRIVACY_ENDPOINT
    ),
    ES_ES_PRIVACY_ENDPOINT: getVariable(
        "ES_ES_PRIVACY_ENDPOINT",
        process.env.ES_ES_PRIVACY_ENDPOINT
    ),
    EN_GB_PRIVACY_ENDPOINT: getVariable(
        "EN_GB_PRIVACY_ENDPOINT",
        process.env.EN_GB_PRIVACY_ENDPOINT
    ),
    PT_PT_PRIVACY_ENDPOINT: getVariable(
        "PT_PT_PRIVACY_ENDPOINT",
        process.env.PT_PT_PRIVACY_ENDPOINT
    ),
    PL_PL_PRIVACY_ENDPOINT: getVariable(
        "PL_PL_PRIVACY_ENDPOINT",
        process.env.PL_PL_PRIVACY_ENDPOINT
    ),
};
