<template>
    <div class="image-share">
        <div class="color-picture__header">
            <div class="button-back">
                <router-link
                    :to="{ name: 'index' }"
                    class="simple-link"
                >
                    <MIconArrowArrowLeft24 color="#78be20" />
                    <span>{{ _("Retour à l'accueil") }}</span>
                </router-link>
            </div>
        </div>
        <div class="color-picture__sub-header">
            <p>{{ _("Mon image de partage") }}</p>
        </div>
        <div v-if="error">
            {{ _("Aucune image trouvée, elle a peut être été supprimée.") }}
        </div>
        <div
            v-else
            class="color-picture__content"
        >
            <div>
                <div class="color-picture__content-picture">
                    <img :src="image">
                </div>
                <div class="color-picture__content-info">
                    <ColorInfo
                        v-for="color in imageColors"
                        :key="color"
                        :uid="color"
                    />
                </div>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import { MIconArrowArrowLeft24 } from "@mozaic-ds/icons/vue/index.vue";
import self from "../../../index";
import ColorInfo from "../../components/color-info/color-info.vue";

const {
    modules,
} = self.app;

export default {
    name: "ImageShare",
    components: {
        MIconArrowArrowLeft24,
        ColorInfo,
    },
    data() {
        return {
            uid: null,
            image: "",
            error: false,
            imageColors: [],
        };
    },
    computed: {
        colors() {
            return this.$store.getters["colors/colors"];
        },
    },
    created() {
        this.init();
        fetch(`/share/image/${this.uid}`)
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Error while fetching image");
                } else {
                    return res.blob();
                }
            })
            .then((blobImage) => {
                this.image = URL.createObjectURL(blobImage);
            })
            .catch(() => {
                this.image = "";
                this.error = true;
            });

        if (this.$route.params.colors) {
            const colorsUrl = this.$route.params.colors.split("_");
            this.imageColors = colorsUrl.filter((uid) => this.colors[uid] !== undefined);
        }
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        init() {
            this.uid = this.$route.params.uid;
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.image-share-responsive {
  text-align: center;
  padding-top: 1rem;
}

.image-share {
  .color-picture {
    &__header {
      position: relative;

      .share-container {
        top: 2px;
        right: $mu100;
      }
    }

    &__sub-header {
      @extend %main-header-title;

      border-bottom: none;
    }

    &__content {
      display: flex;
      flex-direction: column;
      overflow: auto;

      @include set-from-screen(m) {
        height: calc(100vh - #{$nav-height * 2 + $nav-height});
      }

      @include set-from-screen(l-medium) {
        overflow-y: auto;
        height: calc(100vh - #{$nav-height * 2 + $nav-height-desktop});
      }

      @include set-from-screen(xl) {
        flex: 1;
      }

      & > div:first-child {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include set-from-screen(l) {
          flex-direction: row;
        }
      }

      &-picture {
        padding: 0 $mu100;
        background: white;
        position: relative;
        flex: 1;
        margin-bottom: $mu100;
        display: flex;
        max-height: initial;
        height: auto;

        @include set-from-screen(xl) {
          max-width: calc(100% - #{$palette-container-width});
          max-height: initial;
          height: 100%;
          background: transparent;
          margin-bottom: 0;
        }

        img {
          width: 100%;

          @include set-from-screen(xl) {
            margin: auto;
            height: 100%;
            width: 100%;
            object-fit: contain;
            object-position: top;
          }
        }
      }

      &-info {
        display: flex;
        padding: $mu100;
        flex-direction: column;
        margin: auto;

        @include set-from-screen(m) {
          max-width: 400px;
          display: flex;
          align-items: flex-start;
          padding: 0 $mu200;

          @include set-from-screen(l) {
            margin-top: $mu100;
          }
        }
      }
    }
  }
}

</style>
