/**
 * imagesGallery store module is storing the images set by Leroy Merlin :
 * - @property {Array} images is an array of pictures data.
 * - @property {Object} imageSelected is an object of picture data
 * - @property {Array} masks is an array of mask path
 */

export default {
    namespaced: true,
    state: {
        images: [],
        imageSelected: null,
        masks: null,
        isLoading: false,
        error: null,
    },
    getters: {
        images(state) {
            return state.images;
        },
        imagesGeneric(state) {
            return state.images.map((category) => {
                const firstPicture = category.rooms.find((room) => room.enable);
                if (firstPicture) {
                    firstPicture.categoryName = category.categoryName;
                }
                return firstPicture || null;
            }).filter((image) => image !== null);
        },
        imageSelected(state) {
            return state.imageSelected;
        },
        hasImageSelected(state) {
            return state.imageSelected !== null;
        },
        masks(state) {
            return state.masks;
        },
        isLoading(state) {
            return state.isLoading;
        },
        error(state) {
            return state.error;
        },
    },
    mutations: {
        "FETCHING_IMAGES"(state) {
            state.images = [];
            state.isLoading = true;
            state.error = null;
        },
        "FETCHING_IMAGES_SUCCESS"(state, data) {
            state.images = data;
            state.isLoading = false;
            state.error = null;
        },
        "FETCHING_IMAGES_ERROR"(state, err) {
            state.images = [];
            state.isLoading = false;
            state.error = err;
        },
        "UPDATE_IMAGES"(state, data) {
            const { picture, categoryName } = data;
            const imagesCopy = [...state.images];

            state.images = imagesCopy.map((category) => {
                if (category.categoryName === categoryName) {
                    category.rooms = category.rooms.map(
                        (categoryPicture) => ((categoryPicture.uuid === picture.uuid) ? picture : categoryPicture)
                    );
                }
                return category;
            });

            state.isLoading = false;
            state.error = null;
        },
        "UPDATE_IMAGE_CATEGORY"(state, data) {
            const { picture, oldCategoryName, categoryName } = data;
            const imagesCopy = [...state.images];

            state.images = imagesCopy.map((category) => {
                if (category.categoryName === oldCategoryName) {
                    category.rooms = category.rooms.filter((categoryPicture) => (categoryPicture.uuid !== picture.uuid));
                } else if (category.categoryName === categoryName) {
                    category.rooms.push(picture);
                }
                return category;
            });

            state.isLoading = false;
            state.error = null;
        },
        "UPDATE_ALL_IMAGES"(state, data) {
            state.images = data;
            state.isLoading = false;
            state.error = null;
        },
        "UPDATE_IMAGE_SELECTED"(state, image) {
            state.imageSelected = image;
        },
    },
    actions: {
        async fetchImages({ commit }, locale) {
            commit("FETCHING_IMAGES");
            try {
                const res = await fetch(`/data/picture_library.${locale}.json`);
                const data = await res.json();
                commit("FETCHING_IMAGES_SUCCESS", data);
            } catch (err) {
                commit("FETCHING_IMAGES_ERROR", err);
            }
        },
    },
};
