<template>
    <div class="legal-notices">
        <h4>{{ _("Mentions légales") }}</h4>
        <a
            :href="cguUrl"
            target="_blank"
        >{{ _("Crédits et mentions légales") }}</a>
        <a
            :href="privacyUrl"
            target="_blank"
        >{{ _("Données personnelles") }}</a>
        <a
            href="/CREDITS.txt"
            target="_blank"
        >{{ _("Licenses") }}</a>
        <span>&#169; {{ _("Mon Coach Déco Leroy Merlin") }} {{ new Date().getFullYear() }}</span>
    </div>
</template>

<script>
import self from "../../../index";

const {
    stonejs,
} = self.app.modules;

export default {
    name: "LegalNotices",
    methods: {
        _(...args) {
            return stonejs.gettext(...args);
        },

    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.legal-notices {
  display: flex;
  flex-direction: column;
  align-items: center;

  span,
  a {
    margin: 5px 0;
  }
}
</style>
