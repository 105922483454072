<template>
    <div :class="['',containerClass]">
        <a
            v-for="aspect in getColorAspects()"
            :key="aspect.name"
            :href="getAspectUrl(aspect)"
            target="_blank"
            type="button"
            class="main-button"
            :title="aspect.description"
            data-merc-pos="simulateur-peinture"
            :data-merch-name="getButtonLabel(aspect)"
            data-merch-arbo="simulateur"
        >
            <span @click="onBuyClick">
                {{ getButtonLabel(aspect) }}
            </span>
        </a>
    </div>
</template>

<script>
import { sendEvent } from "../../services/marketing";
import { getAspectUrl } from "../../services/color";
import self from "../../../index";

const {
    modules,
} = self.app;

export default {
    name: "ColorAspects",
    props: {
        uid: {
            type: String,
            required: true,
        },
        containerClass: {
            type: String,
            required: false,
            default: "buttons",
        },
    },
    computed: {
        color() {
            return this.$store.getters["colors/colors"][this.uid];
        },
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        getAspectUrl(aspect) {
            const domain = this.getLeroyMerlinDomain();

            return getAspectUrl(aspect, domain);
        },
        onBuyClick(event) {
            sendEvent("link.click", {
                link_name: "buy color",
                link_destination: "lmfr",
                cdl_color_name: this.color ? this.color.name : "",
            }, event ? event.target : null);
        },
        getButtonLabel(aspect) {
            return `${this._("Acheter en")} ${aspect.name}`;
        },
        getColorAspects() {
            return this.$store.getters["colors/getColorAspects"](this.uid);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.main-button {
  background: $color-primary-01-500;
  border-radius: 4px;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;

  @include set-font-weight('semi-bold');
  @include set-font-scale('05');

  text-align: center;
  padding: $mu050;
}

</style>
