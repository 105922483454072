const check = () => fetch("/auth/check", {
    method: "POST",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

const oauthCheck = () => fetch("/oauth/check", {
    method: "POST",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

const login = (username, password) => fetch("/auth/login", {
    method: "POST",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
});

const logout = () => fetch("/auth/logout");

export {
    check,
    oauthCheck,
    login,
    logout,
};
