import * as Sentry from "@sentry/vue";
import constants from "../constants";

/**
 * cartData store module is storing user data :
 * - @property {Array} colors is for storing the uid of the colors selected by the user.
 * - @property {String} color is the uid of the color currently selected by the user.
 * - @property {Boolean} isPaletteOpen toggle to show colors in palette.
 */
export default {
    namespaced: true,
    state: {
        colors: [],
        color: null,
        isPaletteOpen: true,
        isShareButtonOpenedInModal: false,
    },
    getters: {
        colors(state) {
            return state.colors;
        },
        color(state) {
            return state.color;
        },
        isPaletteOpen(state) {
            return state.isPaletteOpen;
        },
        isShareButtonOpenedInModal(state) {
            return state.isShareButtonOpenedInModal !== null;
        },
        hasColor(state) {
            return state.color !== null;
        },
        isColorSelected: (state) => (uid) => !!state.colors.includes(uid),
    },
    mutations: {
        "OPEN_PALETTE"(state) {
            state.isPaletteOpen = true;
        },
        "CLOSE_PALETTE"(state) {
            state.isPaletteOpen = false;
        },
        "OPEN_BUTTON_SHARE"(state) {
            state.isShareButtonOpenedInModal = true;
        },
        "CLOSE_BUTTON_SHARE"(state) {
            state.isShareButtonOpenedInModal = false;
        },
        "ADD_COLOR"(state, color) {
            if (state.colors.length < constants.COLORS_LIMIT) {
                const cart = [...state.colors];
                cart.push(color.uid);
                state.colors = cart;
            }
        },
        "REMOVE_COLOR"(state, uid) {
            state.colors = state.colors.filter((u) => u !== uid);

            if (uid === state.color) {
                // reset selected color
                state.color = null;
            }
        },
        "INIT_COLORS"(state, colors) {
            state.colors = colors;
        },
        "SAVE_COLORS"(state) {
            try {
                localStorage.setItem("colors", JSON.stringify(state.colors));
            } catch (err) {
                Sentry.captureMessage(`An error occured while getting item from localStorage: ${err}`);
            }
        },
        "SELECT_COLOR"(state, color) {
            state.color = (state.color !== color) ? color : null;
        },
        "SELECT_COLOR_BY_DEFAULT"(state) {
            const nbColorsSelected = state.colors.length;
            if (nbColorsSelected > 0 && state.color === null) {
                state.color = state.colors[nbColorsSelected - 1];
            }
        },
    },
    actions: {
        load({ commit, rootGetters }) {
            const allColors = rootGetters["colors/colors"];

            // Get colors saved in local storage
            let colors = [];
            try {
                const colorsJSON = localStorage.getItem("colors");
                if (colorsJSON) {
                    colors = JSON.parse(colorsJSON);
                }
            } catch (err) {
                Sentry.captureMessage(`An error occured while getting item from localStorage: ${err}`);
            }

            // Check if colors still exist
            const filteredColors = colors.filter((uid) => (
                allColors[uid] !== undefined
            ));

            commit("INIT_COLORS", filteredColors);
            commit("SELECT_COLOR_BY_DEFAULT");
        },
        removeColor({ commit }, uid) {
            commit("REMOVE_COLOR", uid);
            commit("SELECT_COLOR_BY_DEFAULT");
            commit("SAVE_COLORS");
        },
        addColor({ commit, rootState }, uid) {
            const color = rootState.colors.colors[uid];

            commit("ADD_COLOR", { ...color, uid });
            commit("SELECT_COLOR", uid);
            commit("SAVE_COLORS");
        },
        openPalette({ commit }) {
            commit("OPEN_PALETTE");
            commit("SELECT_COLOR_BY_DEFAULT");
        },
        closePalette({ commit }) {
            commit("CLOSE_PALETTE");
            commit("SELECT_COLOR_BY_DEFAULT");
        },
    },
};
