const maskHelpers = require("./mask-helpers");
const paintPicture = require("./paint-picture-worker.js").default;  // eslint-disable-line
const helpers = require("./helpers.js");  // eslint-disable-line

class PaintingLibController {

    constructor() {
        this.helpers = helpers;
        this.refineMask = maskHelpers.refineMask;
        this.morphologicalOpeningCanvas = maskHelpers.morphologicalOpeningCanvas;
        this.diffMasks = maskHelpers.diffMasks;
        this.clearMask = maskHelpers.clearMask;
        this.paintPicture = paintPicture;
        this.isMaskUnder = maskHelpers.isMaskUnder;
        this.fuseMasks = maskHelpers.fuseMasks;
        this.testAndMergeZone = maskHelpers.testAndMergeZone;
        this.detectRegion = maskHelpers.detectRegion;
        this.detectInMask = maskHelpers.detectInMask;
    }

    getMask(canvas, coords, masks) {
        let maskToUse = null;

        for (let i = 0; i <= masks.length - 1; i++) {
            const canvasMask = document.createElement("canvas");
            canvasMask.width = canvas.width;
            canvasMask.height = canvas.height;

            const ctx = canvasMask.getContext("2d");
            ctx.drawImage(masks[i], 0, 0, canvasMask.width, canvasMask.height);
            const isMaskUnderResult = this.isMaskUnder(canvasMask, coords);
            if (isMaskUnderResult) {
                maskToUse = { imagePath: masks[i].src, canvas: canvasMask };
                break;
            }
        }
        return maskToUse;
    }

    /**
     * Detect a region from a user click, it will return a canvas containing a region
     * Function will try to merge detected mask with already existing masks passed as parameter
     * @param {Canvas} canvas - Canvas as HTML element
     * @param {Object} coords - Mouse position as an object { x: 1, y: 2}
     * @param {Float} sensitivity - A number between 0 and 1 to adjust mask detection
     * @param {Integer} tolerance - A number between 0 and 255 to adjust mask detection
     * @param {Array} masksImage - List of mask that will be merged with detected mask
     * @return {Canvas} - A new Canvas with the Mask to use for coloration
     */
    detectRegionPerso(coords, canvas, sensibility = 0.66, tolerance = 30, masksImage = []) {
        const canvasMask = this.detectRegion(canvas, coords, tolerance, sensibility);
        const masksImageMerged = this.testAndMergeZone(canvasMask, masksImage);
        return masksImageMerged;
    }

    colorize(coords, canvas, masks, colors, luminosity = 0) {
        const mask = this.getMask(canvas, coords, masks);

        if (mask === null) {
            return Promise.resolve();
        }

        return helpers.loadImage(mask.imagePath)
            .then(helpers.imageToCanvas)
            .then(this.refineMask)
            .then((maskCanvas) => this.paintPicture(
                canvas,
                maskCanvas,
                colors,
                luminosity
            ))
            .then((paintedPictureCanvas) => helpers.copyCanvas(
                paintedPictureCanvas,
                canvas
            ));
    }

    /**
     * Function compute and return average on an image.
     * It will compute average on image by excluding mask from image
     * Average will be computed on Luminosity in lab format
     * @param  {Canvas} canvas source canvas containing image you want to compute average
     * @param  {Canvas} mask   canvas containing mask that will be use to remove pixels which are transparent from source canvas
     * @return {Number}        average, should represent a luminosity between 0 and 100
     */
    computeAverageLuminosityImageFromMask(canvas, mask) {
        function rgb2lab(rgb) {
            let r = rgb[0] / 255;
            let g = rgb[1] / 255;
            let b = rgb[2] / 255;
            let x;
            let y;
            let z;

            r = (r > 0.04045) ? ((r + 0.055) / 1.055) ** 2.4 : r / 12.92;
            g = (g > 0.04045) ? ((g + 0.055) / 1.055) ** 2.4 : g / 12.92;
            b = (b > 0.04045) ? ((b + 0.055) / 1.055) ** 2.4 : b / 12.92;

            x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
            y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
            z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

            x = (x > 0.008856) ? x ** (1 / 3) : (7.787 * x) + 16 / 116;
            y = (y > 0.008856) ? y ** (1 / 3) : (7.787 * y) + 16 / 116;
            z = (z > 0.008856) ? z ** (1 / 3) : (7.787 * z) + 16 / 116;

            return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)];
        }
        this.isAverageComputed = true;
        const photoData = canvas.getContext("2d", { willReadFrequently: true }).getImageData(0, 0, canvas.width, canvas.height);
        const maskData = mask.getContext("2d", { willReadFrequently: true }).getImageData(0, 0, mask.width, mask.height);
        const photoDataLab = [];
        for (let i = 0, c = photoData.data.length; i < c; i += 4) {
            if (maskData.data[i + 3] !== 0) {
                const labColor = rgb2lab([photoData.data[i], photoData.data[i + 1], photoData.data[i + 2]]);
                photoDataLab.push(labColor[0]);
            }
        }

        const average = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
        return average(photoDataLab);
    }

    colorizeFull(canvas, colors, preComputedAverage = null, luminosity = -10) {
        const maskCanvas = document.createElement("canvas");
        maskCanvas.width = canvas.width;
        maskCanvas.height = canvas.height;

        const maskCtx = maskCanvas.getContext("2d");
        maskCtx.fillStyle = "white";
        maskCtx.fillRect(0, 0, maskCanvas.width, maskCanvas.height);

        return this.paintPicture(canvas, maskCanvas, colors, preComputedAverage, luminosity)
            .then((paintedPictureCanvas) => {
                helpers.copyCanvas(paintedPictureCanvas, canvas);
            }).catch((error) => {
                console.log(error);
            });
    }

}

export default PaintingLibController;
