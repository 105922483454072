<template>
    <div class="situation-perso">
        <ButtonBack />
        <div class="situation-perso__header">
            <p>{{ _("Mise en situation personnalisée") }}</p>
        </div>

        <div class="guideline-container">
            <button
                type="button"
                class="mc-button mc-button--s"
                @click="openModalGuidelines"
            >
                <MIconNotificationInformation24 />

                <span class="mc-button__label mu-ml-050">
                    {{ _("Comment prendre ma photo") }}
                </span>
            </button>
        </div>

        <div class="dropzone-container">
            <div
                :class="['dropzone', {'active-dropzone' : active}, {'fill' : hasImage}]"
                @dragenter.prevent="toggleActive"
                @dragleave.prevent="toggleActive"
                @dragover.prevent
                @drop.prevent="droping($event)"
                @change="onFileUpload($event)"
            >
                <div
                    v-if="hasImage"
                    class="dropzone-image"
                    :style="{ backgroundImage: `url(${image})` }"
                >
                    <button
                        id="remove"
                        @click="remove"
                    >
                        <MIconPublishTrashbin32 />
                    </button>
                </div>
                <div
                    v-else
                    class="dropzone-no-image"
                    @click="$refs.file.click()"
                >
                    <label>
                        <MIconUpload32 />
                    </label>
                    <p>{{ _("Sélectionner une image de votre galerie afin de la coloriser à votre goût") }}</p>
                    <input
                        id="dropzoneFile"
                        ref="file"
                        type="file"
                        accept="image/*"
                    >
                </div>
            </div>
        </div>
        <div class="dropzone-buttons">
            <m-button
                :label="_('Annuler')"
                theme="bordered"
                @click="cancel"
            />

            <m-button
                :disabled="!hasImage"
                :label="_('Valider')"
                @click="validate"
            />
        </div>
        <Footer />
    </div>
</template>

<script>
import { MIconPublishTrashbin32, MIconUpload32, MIconNotificationInformation24 } from "@mozaic-ds/icons/vue/index.vue";
import EventBus from "../../helpers/event-bus";
import ButtonBack from "../../components/button-back/button-back.vue";
import self from "../../../index";

const {
    modules,
} = self.app;

export default {
    name: "SituationPerso",
    components: {
        MIconPublishTrashbin32,
        MIconUpload32,
        MIconNotificationInformation24,
        ButtonBack,
    },
    data() {
        return {
            dropzoneFile: {},
            active: false,
        };
    },
    computed: {
        image() {
            return this.$store.getters["image/image"];
        },
        hasImage() {
            return this.$store.getters["image/hasImage"];
        },
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        openModalGuidelines() {
            EventBus.$emit("open-modal-guidelines");
        },
        remove() {
            this.$store.commit("image/REMOVE_IMAGE");
            this.active = false;
        },
        cancel() {
            this.$router.push({ name: "color_menu" });
        },
        validate() {
            if (this.hasImage) {
                this.$router.push({ name: "color_picture" });
            }
        },
        toggleActive() {
            this.active = !this.active;
        },
        onFileUpload(e) {
            const file = e.target.files[0];
            const blobImage = URL.createObjectURL(file);
            this.$store.commit("image/UPDATE_IMAGE", blobImage);
            // this.validateUploadPicture(blobImage);
        },
        droping(e) {
            const file = e.dataTransfer.files[0];
            const blobImage = URL.createObjectURL(file);
            this.$store.commit("image/UPDATE_IMAGE", blobImage);
            // this.validateUploadPicture(blobImage);
        },
        /**
         * Temporary remove see preprocessor function in mask-helpers #246
        validateUploadPicture(blobImage) {
            const img = document.createElement("img");
            img.src = blobImage;
            img.onload = () => {
                if (img.width * img.height > 6000000) {
                    const messageError = {
                        title: this._("Erreur"),
                        text: this._("L'image est trop grande, merci de réduire ses dimensions. (6 millions de pixels maximum)"),
                        status: "error",
                        type: "ERROR",
                    };
                    EventBus.$emit("toaster-message", messageError);
                } else {
                    this.$store.commit("image/UPDATE_IMAGE", blobImage);
                }
            };
        },
        */
    },
};

</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.situation-perso {
  background: $color-grey-100;
  height: calc(100vh - #{$nav-height});
  display: flex;
  flex-direction: column;
  overflow: auto;

  @media screen and (min-width: $medium-break-point) {
    height: calc(100vh - #{$nav-height});
  }

  @include set-from-screen(l-medium) {
    height: calc(100vh - #{$nav-height-desktop});
  }

  &__header {
    @extend %main-header-title;

    border-bottom: none;
  }
}

.guideline-container {
  margin: auto;

  @extend .mu-mt-200;

  button {
    border-radius: 20px;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
}

.dropzone-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $mu100;
  flex: 1;
}

.dropzone {
  width: 400px;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  row-gap: 16px;
  transition: 0.3s ease-out;
  background: white;
  border: 2px dashed $color-grey-300;
  border-radius: 12px;
  overflow: hidden;

  @media screen and (max-width: 450px) {
    width: 100%;
  }

  &-no-image {
    text-align: center;
    color: $color-grey-900;
    padding: $mu100;
    height: 100%;

    @include set-font-scale('04');

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label {
      transition: 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $mu050;
      color: white;
      background: $color-grey-500;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;
      margin-bottom: $mu150;

      svg {
        width: 50%;
        height: 50%;
      }

      &:hover {
        cursor: pointer;
      }

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background: $color-grey-300;
        bottom: -20px;
      }
    }

    &:hover {
      cursor: pointer;

      label {
        background: $color-primary-01-500;
      }
    }
  }

  &.active-dropzone {
    label {
      background: $color-primary-01-500;
    }
  }

  &-image {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include set-border-radius(l);
  }

  &.fill {
    height: 100%;
    border: none !important;
  }

  input {
    display: none;
  }

  &-buttons {
    max-width: 400px;
    width: 100%;
    margin: $mu100 auto;
    display: flex;
    grid-column-gap: $mu100;

    @media screen and (max-width: 450px) {
      padding: 0 $mu100;
    }

    & > * {
      flex: 1;
    }
  }
}

#remove {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 50%;
  right: 0;
  bottom: $mu100;
  border: none;
  width: 50px;
  height: 50px;
  box-shadow: 0 0 10px rgba(black, 0.3);
  transition: 0.3s;

  svg {
    transition: 0.3s;
  }

  &:hover {
    box-shadow: 0 0 4px rgba(black, 0.5);
    transform: translateX(-50%) scale(1.1);
    cursor: pointer;

    svg {
      fill: $color-danger-500;
    }
  }
}
</style>
