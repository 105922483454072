<template>
    <div class="admin-picture-container">
        <div
            v-for="category in picturesForm"
            :key="category.categoryName"
        >
            <h4>{{ _(PICTURE_CATEGORIES[category.categoryName]) }}</h4>
            <draggable
                v-model="category.rooms"
                class="sort-pictures"
                :animation="200"
                group="rooms"
                @change="updatePictures"
            >
                <PictureCard
                    v-for="picture in category.rooms"
                    :key="picture.uuid"
                    :is-generic="isFirstPicture(picture.uuid, category.categoryName)"
                    :picture="picture"
                    :category-name="category.categoryName"
                    @edit-picture="editPicture"
                />
            </draggable>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import self from "../../../index";
import PictureCard from "./picture-card.vue";
import {
    PICTURE_CATEGORIES,
} from "../../helpers/constants";

const {
    modules,
} = self.app;

export default {
    name: "PicturesDraggable",
    components: {
        draggable,
        PictureCard,
    },
    props: {
        pictures: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            picturesForm: [],
            PICTURE_CATEGORIES,
        };
    },
    mounted() {
        this.picturesForm = this.pictures;
    },
    updated() {
        this.picturesForm = this.pictures;
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        isFirstPicture(pictureUUID, categoryName) {
            const category = this.pictures.find((cat) => cat.categoryName === categoryName);
            const firstPicture = category.rooms.find((room) => room.enable);
            if (!firstPicture) {
                return false;
            }
            const res = firstPicture.uuid === pictureUUID;
            return res;
        },
        updatePictures() {
            this.$store.commit("imagesGallery/UPDATE_ALL_IMAGES", this.picturesForm);
        },
        editPicture(data) {
            this.$emit("edit-picture", data);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.admin-picture-container {
  height: calc(100vh - #{$nav-height-desktop * 2});
  overflow: auto;
  padding: 0 $mu050;
}

.sort-pictures {
  display: flex;
  flex-wrap: wrap;
}
</style>
