var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticClass: "color-info",
      attrs: { to: { name: "color_show", params: { uid: _vm.uid } } },
    },
    [
      _c("div", { staticClass: "color-info_palette" }, [
        _c("div", {
          staticClass: "color-info_palette-color",
          style: {
            backgroundColor: _vm.color.rgbhex,
            borderColor: _vm.isColorLight(_vm.color) && "#bab6bc",
          },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "color-info_palette-name" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.getDisplayName(_vm.color)) +
              "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "color-info_show" },
        [
          _c("MIconDisplayView32"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm._("Voir la fiche couleur")))]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }