<template>
    <nav class="navbar">
        <router-link
            :to="{ name: 'index' }"
            class="brand-logo"
        >
            <img :src="logo">
            <span>{{ _("Mon simulateur couleur") }}</span>
        </router-link>
        <div v-if="isAdmin">
            <div
                class="nav-collapse"
                :class="isNavOpen && 'show'"
            >
                <router-link
                    :to="{ name: 'admin_color_manager' }"
                    data-cerberus="color-manager"
                    class="nav-collapse__item"
                >
                    <div @click="closeNav">
                        <span>{{ _("Gestionnaire de couleurs") }}</span>
                    </div>
                </router-link>
                <router-link
                    :to="{ name: 'admin_picture_manager' }"
                    data-cerberus="picture-manager"
                    class="nav-collapse__item"
                >
                    <div @click="closeNav">
                        <span>{{ _("Gestionnaire d'images") }}</span>
                    </div>
                </router-link>
                <button
                    type="button"
                    class="btn-logout"
                    data-cerberus="logout"
                    :title="_('Déconnexion')"
                    @click="logout"
                >
                    <MIconDisplayConnect32 />
                    <span>{{ _("Déconnexion") }}</span>
                </button>
            </div>
            <button
                class="btn-toggle-menu"
                data-cerberus="toogle-menu-1"
                :class="isNavOpen && 'show'"
                @click="toggleNav"
            >
                <MIconControlCross32
                    class="close"
                    color="#78be20"
                />
                <MIconDisplayMenu32
                    class="open"
                    color="#78be20"
                />
            </button>
        </div>

        <div v-else>
            <router-link
                :to="{ name: 'shopping_cart' }"
                class="btn-shop"
                data-cerberus="shopping-cart-1"
                :class="isNavOpen && 'hide'"
            >
                <div
                    class="basket-icon-container"
                    :class="selectedColors.length > 0 && 'active'"
                    :data-palette-num="selectedColors.length"
                >
                    <MIconBasketStandard32 color="#554f52" />
                </div>
            </router-link>
            <div
                class="nav-collapse"
                :class="isNavOpen && 'show'"
            >
                <router-link
                    :to="{ name: 'color_chart' }"
                    class="nav-collapse__item"
                    data-cerberus="explore-color-chart"
                >
                    <div @click="closeNav">
                        <span>{{ _("Explorer le nuancier") }}</span>
                    </div>
                </router-link>
                <router-link
                    :to="{ name: 'color_menu' }"
                    class="nav-collapse__item"
                    data-cerberus="paint-picture"
                >
                    <div @click="closeNav">
                        <span>{{ _("Coloriser une photo") }}</span>
                    </div>
                </router-link>
                <router-link
                    :to="{ name: 'legal_notices' }"
                    class="nav-collapse__item legal-notices"
                    data-cerberus="legals"
                >
                    <div @click="closeNav">
                        <span>{{ _("Mentions légales") }}</span>
                    </div>
                </router-link>
                <router-link
                    :to="{ name: 'shopping_cart' }"
                    data-cerberus="shopping-cart-2"
                    class="nav-collapse__item"
                >
                    <div @click="closeNav">
                        <div
                            class="basket-icon-container"
                            :class="selectedColors.length > 0 && 'active'"
                            :data-palette-num="selectedColors.length"
                        >
                            <MIconBasketStandard32 />
                        </div>
                        <span>{{ _("Ma liste de couleurs") }}</span>
                    </div>
                </router-link>
            </div>
            <button
                class="btn-toggle-menu"
                data-cerberus="toogle-menu-2"
                :class="isNavOpen && 'show'"
                @click="toggleNav"
            >
                <MIconControlCross32
                    class="close"
                    color="#78be20"
                />
                <MIconDisplayMenu32
                    class="open"
                    color="#78be20"
                />
            </button>
        </div>
    </nav>
</template>

<script>
import {
    MIconBasketStandard32, MIconDisplayMenu32, MIconControlCross32, MIconDisplayConnect32,
} from "@mozaic-ds/icons/vue/index.vue";
import logo from "../../../../../assets/images/icons/logo.svg";
import { logout } from "../../api/auth";
import self from "../../../index";

const {
    modules,
} = self.app;

export default {
    name: "Navbar",
    components: {
        MIconBasketStandard32,
        MIconDisplayMenu32,
        MIconControlCross32,
        MIconDisplayConnect32,
    },
    data() {
        return {
            logo,
            isNavOpen: false,
            isAdmin: false,
        };
    },
    computed: {
        selectedColors() {
            return this.$store.getters["cartData/colors"];
        },
    },
    watch: {
        $route() {
            this.isAdminRoute();
        },
    },
    created() {
        this.isAdminRoute();
    },
    methods: {
        isAdminRoute() {
            this.isAdmin = !!this.$route.meta.isAuthRequired;
        },
        toggleNav() {
            this.isNavOpen = !this.isNavOpen;
        },
        closeNav() {
            this.isNavOpen = false;
        },
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        async logout() {
            try {
                await logout();
                this.$router.push({ name: "admin_login" });
            } catch (err) {
                console.error("Error while logging out:", err);
            }
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.navbar {
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px $color-grey-200;
  height: $nav-height;
  position: relative;
  padding: 0 $mu100;

  @include set-from-screen(l-medium) {
    height: $nav-height-desktop;
  }

  .basket-icon-container {
    position: relative;
    margin-right: $mu100;

    svg {
      fill: $color-grey-800;

      @include set-from-screen(l-medium) {
        width: $nav-height-desktop * 0.7 !important;
        height: $nav-height-desktop * 0.7;
      }
    }

    &::before {
      display: none;
      content: "";
      position: absolute;
      top: -2%;
      right: -8%;
      border-radius: 50%;
      background: $color-primary-01-500;
      width: 20px;
      height: 20px;

      @include set-from-screen(l-medium) {
        top: 2%;
        right: -6%;
        width: 24px;
        height: 24px;
      }
    }

    &::after {
      display: none;
      content: attr(data-palette-num);
      position: absolute;
      top: -6%;
      right: 9%;
      font-size: 15px;
      color: white;

      @include set-font-weight('semi-bold');

      @include set-from-screen(l-medium) {
        top: -2%;
        right: 9%;
        font-size: 19px;
      }
    }

    &.active::before,
    &.active::after {
      display: block;
    }
  }

  & > * {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .brand-logo {
    text-decoration: none;
    display: flex;
    align-items: center;

    img {
      width: $nav-height * 0.9;
      height: $nav-height * 0.9;

      @include set-from-screen(l-medium) {
        width: $nav-height-desktop * 0.9;
        height: $nav-height-desktop * 0.9;
      }
    }

    span {
      color: black;
      text-transform: uppercase;
      margin-left: $mu100;
      transition: 0.3s;
      font-size: 10px;

      @include set-font-weight('semi-bold');

      @include set-from-screen(l-medium) {
        @include set-font-scale('05');
      }
    }

    &:hover {
      span {
        @include set-from-screen(l) {
          color: $color-primary-01-500;
        }
      }
    }

    &:visited,
    &:active {
      color: black;
    }
  }

  .btn-shop {
    position: relative;
    background: transparent;
    padding: 0 1.2rem 0 1.2rem !important;
    transition: 0.3s;

    .basket-icon-container {
      &::before {
        top: -2%;
        right: -6%;
      }

      &::after {
        top: 10px;
        transform: translateY(-50%);
        right: 4px;
      }
    }

    svg {
      height: $nav-height * 0.7;
      width: $nav-height * 0.7;

      @include set-from-screen(l-medium) {
        width: $nav-height-desktop * 0.7;
        height: $nav-height-desktop * 0.7;
      }
    }

    @media screen and (min-width: $medium-break-point) {
      display: none;
    }

    &.hide {
      opacity: 0;
      transform: scale(0);
    }

    &:hover {
      background: transparent;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .btn-toggle-menu {
    border: none;
    background: transparent;
    width: 32px;
    height: $nav-height * 0.7;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      background: $color-grey-200;
      left: 0;
      top: 0;
      margin-left: -1rem;
    }

    .open,
    .close {
      transition: 0.3s;
      position: absolute;
      top: 0;
      left: 0;
      width: $nav-height * 0.7;
      height: $nav-height * 0.7;
    }

    .open {
      transform: scale(1);
    }

    .close {
      transform: scale(0);
    }

    &.show {
      .open {
        transform: scale(0);
      }

      .close {
        transform: scale(1);
      }
    }

    @media screen and (min-width: $medium-break-point) {
      display: none;
    }
  }

  .btn-logout {
    background: transparent;
    border: none;
    margin-left: 0;
    display: flex;
    align-items: center;
    padding: $mu100 0;

    svg {
      width: 30px;
      height: 30px;
      transition: 0.3s;
      fill: $color-primary-01-500;
    }

    span {
      margin-left: $mu100;

      @media screen and (min-width: $medium-break-point) {
        display: none;
      }
    }

    &:hover {
      cursor: pointer;

      svg {
        fill: red;
      }
    }

    @media screen and (min-width: $medium-break-point) {
      margin-left: $mu200;
      padding: $mu100 0;
    }
  }

  .nav-collapse {
    transition: 0.3s;
    position: relative;
    padding: 0;
    background: white;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .legal-notices {
      display: none;
    }

    @media screen and (max-width: $medium-break-point) {
      opacity: 0;
      position: absolute;
      top: calc(100% + 1px);
      box-shadow: 0 0 5px rgba(grey, 0.3);
      height: auto;
      left: calc(100% + 1rem);
      z-index: 100;
      overflow: hidden;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 $mu150;

      .legal-notices {
        display: block;
      }

      &.show {
        left: calc(0% + 0rem);
        opacity: 1;
      }
    }

    &__item {
      color: $color-grey-900;
      transition: 0.3s;
      width: 100%;
      display: flex;
      align-items: center;
      text-decoration: none;
      white-space: nowrap;
      margin-left: 2rem;
      height: 100%;

      @include set-font-scale('04');

      @include set-from-screen(l-medium) {
        @include set-font-scale('05');
      }

      & > * {
        display: flex;
        align-items: center;
      }

      svg {
        width: $nav-height * 0.7;
      }

      &.router-link-exact-active,
      &:hover {
        color: $color-primary-01-600;

        svg {
          fill: $color-primary-01-600;
        }
      }

      &:nth-child(2) {
        position: relative;
        padding-right: 2rem;

        &::before {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 70%;
          background: $color-grey-200;

          @media screen and (max-width: $medium-break-point) {
            display: none;
          }
        }
      }

      @media screen and (max-width: $medium-break-point) {
        border-bottom: solid 1px $color-grey-200;
        border-right: none !important;
        color: $color-grey-900;
        width: 100%;
        padding: 0 !important;
        margin-left: 0;

        & > * {
          height: 65px;
          width: 100%;
        }

        &:first-child {
          &::before {
            display: none;
          }
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
}

</style>
