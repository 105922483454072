<template>
    <div
        class="card-style-picto"
        :data-cerberus="dataCerberus"
        @click="click"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: "CardStylePicto",
    props: {
        dataCerberus: {
            type: String,
            required: false,
            default: "picto-card",
        },
    },
    methods: {
        click() {
            this.$emit("click");
        },
    },
};

</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.card-style-picto {
  @include set-font-weight('semi-bold');
  @include set-border-radius('l');
  @include set-font-scale('05');

  background: white;
  width: 100%;
  max-width: 400px;
  color: $color-font-darker;
  text-align: center;
  box-shadow: 0 2px 15px rgba(black, 0.2);
  padding: $mu200;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  transition: 0.3s;
  cursor: pointer;

  @include set-from-screen(m) {
    align-self: flex-start;
  }

  &:hover {
    box-shadow: 0 2px 5px rgba(black, 0.4);
  }

  div {
    background: $color_primary_01_500;
    border-radius: 50%;
    padding: $mu150;
    position: relative;
    margin-bottom: $mu200;

    @include set-from-screen(m) {
      padding: $mu200;
    }

    svg {
      fill: white;
      height: 46px;
      width: 46px;
    }

    &::before {
      content: '';
      background: $color-grey-300;
      width: 65%;
      height: 1px;
      position: absolute;
      bottom: 0;
      display: block;
      left: 50%;
      transform: translate(-50%, $mu150);
    }
  }

  p {
    margin-bottom: 0;
  }
}

</style>
