// VueJs Module : contains the main vue component
// and ui parts not binded to a specific module (ex : main-menu)
export default {
    name: "paintinglib",
    requires: [],
    load() {
        const PaintingLibController = require("./src/paintinglib-controller").default;
        return new PaintingLibController();
    },
    unload() {},
};
