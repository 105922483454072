<template>
    <div
        class="card-style"
        @click="click"
    >
        <div class="card-style-wrapper">
            <div class="card-style__cover">
                <div
                    v-if="icon"
                    class="icon"
                >
                    <MIconLiving48 v-show="icon === 'PICTURE_CATEGORY_LIVINGROOM'" />
                    <MIconBedroom48 v-show="icon === 'PICTURE_CATEGORY_BEDROOM'" />
                    <MIconKids48 v-show="icon === 'PICTURE_CATEGORY_BEDROOM_KID'" />
                    <MIconLighting48 v-show="icon === 'PICTURE_CATEGORY_OFFICE'" />
                    <MIconDoor48 v-show="icon === 'PICTURE_CATEGORY_ENTRANCE'" />
                </div>
                <img
                    v-else
                    :src="`/${image}`"
                    :alt="title"
                >
            </div>
            <div class="card-style__content">
                <p><span v-show="titlePrefix">{{ titlePrefix }} - </span> {{ title }}</p>
            </div>
        </div>
    </div>
</template>

<script>

import {
    MIconLiving48,
    MIconBedroom48,
    MIconKids48,
    MIconLighting48,
    MIconDoor48,
} from "@mozaic-ds/icons/vue/index.vue";

export default {
    name: "CardStyle",
    components: {
        MIconLiving48,
        MIconBedroom48,
        MIconKids48,
        MIconLighting48,
        MIconDoor48,
    },
    props: {
        image: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        titlePrefix: {
            type: String,
            default: null,
            required: false,
        },
        icon: {
            type: String,
            default: null,
            required: false,
        },
    },
    methods: {
        click() {
            this.$emit("click");
        },
    },
};

</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.card-style {
  padding: 1rem;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
  }

  &-wrapper {
    background: white;
    overflow: hidden;
    box-shadow: 2px 2px 15px rgba(black, 0.3);
    transition: 0.3s;

    @include set-border-radius('l');

    &:hover {
      box-shadow: 0 2px 5px rgba(black, 0.4);
    }
  }

  &__cover {
    width: 100%;
    height: 200px;

    .icon {
      background: white;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: solid 1px $color-grey-200;

      svg {
        width: 100px;
        height: 100px;
        fill: $color-primary-01-500;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding: 0 $mu100;

    @include set-font-scale('04');
  }
}

</style>
