<template>
    <div class="situation-auto-room">
        <ButtonBack />
        <div class="situation-perso__header">
            <p>{{ _("Choisissez votre photo d'ambiance") }}</p>
        </div>

        <div class="cards-wrapper">
            <div>
                <card-style
                    v-for="(picture, index) in pictures"
                    :data-cerberus="PICTURE_ATMOSPHERES[picture.atmosphere]"
                    :key="index"
                    :title="_(PICTURE_ATMOSPHERES[picture.atmosphere])"
                    :title-prefix="_(PICTURE_ATMOSPHERES['PICTURE_ATMOSPHERE_PREFIX'])"
                    :image="picture.image"
                    @click="selectAtmosphere(picture)"
                />
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import ButtonBack from "../../components/button-back/button-back.vue";
import CardStyle from "../../components/card-style/card-style.vue";
import self from "../../../index";
import { PICTURE_ATMOSPHERES } from "../../helpers/constants";

const {
    modules,
} = self.app;

export default {
    name: "Room",
    components: {
        ButtonBack,
        CardStyle,
    },
    data() {
        return {
            selectedRoomName: null,
            PICTURE_ATMOSPHERES,
        };
    },
    computed: {
        rooms() {
            return this.$store.getters["imagesGallery/images"];
        },
        pictures() {
            const rooms = this.rooms.find((room) => room.categoryName === this.selectedRoomName);
            if (rooms) {
                return rooms.rooms.filter((room) => room.enable);
            }
            return [];
        },
    },
    created() {
        this.selectedRoomName = this.$route.params.categoryName;
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        selectAtmosphere(picture) {
            this.$store.commit("imagesGallery/UPDATE_IMAGE_SELECTED", picture);
            this.$store.commit("image/REMOVE_IMAGE");
            this.$router.push({
                name: "color_picture",
                query: {
                    room: this.selectedRoomName,
                    mood: picture.atmosphere,
                },
            });
        },
    },
};

</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.situation-auto-room {
  .bloc-title {
    min-height: $nav-height;
    display: flex;
    background: white;
    align-items: center;
    padding: $mu050 $mu100;

    h1 {
      margin: 0;
      text-align: left;

      @include set-font-scale('05');
    }
  }

  .cards-wrapper {
    height: calc(100vh - #{$nav-height * 3});
    overflow: auto;
    display: flex;
    flex-direction: column;

    @include set-from-screen(l-medium) {
      height: calc(100vh - #{$nav-height * 2 + $nav-height-desktop});
    }

    & > div:first-child {
      display: flex;
      flex-wrap: wrap;
      padding: $mu100;

      & > * {
        width: 100%;

        @include set-from-screen(m) {
          width: 50%;
        }

        @include set-from-screen(l) {
          width: 33.33%;

          .card-style__cover {
            height: 250px;
          }
        }

        @include set-from-screen(xl-medium) {
          width: 25%;
        }
      }
    }
  }
}

</style>
