var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "color-chart" },
    [
      _c("ButtonBack"),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _c("h4", [
          _vm._v(_vm._s(_vm._("Choisissez une ou plusieurs couleurs"))),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "palettes-bar" }, [
        _c(
          "div",
          { staticClass: "palettes-sample-wrapper" },
          _vm._l(_vm.palettes, function (palette, index) {
            return _c(
              "div",
              {
                key: index,
                class: [
                  "palettes__item",
                  { active: _vm.selectedPaletteName === palette.name },
                ],
                on: {
                  click: function ($event) {
                    return _vm.onPaletteClick(palette, $event)
                  },
                },
              },
              [
                _c("p", [_vm._v(_vm._s(palette.name))]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "sample-color",
                  style: {
                    backgroundColor: palette.preview_rgbhex,
                    borderColor: _vm.isColorLight(palette.preview_rgbhex)
                      ? "#a19ba2"
                      : "transparent",
                  },
                }),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "search__container",
            class: { active: _vm.searchBarIsActive },
          },
          [
            _c(
              "div",
              {
                staticClass: "search__toggle",
                on: { click: _vm.toggleSearchBar },
              },
              [
                _c("MIconDisplaySearch32", {
                  class: { active: !_vm.searchBarIsActive },
                  attrs: { color: "#78be20" },
                }),
                _vm._v(" "),
                _c("MIconControlCross32", {
                  staticClass: "search-close",
                  class: { active: _vm.searchBarIsActive },
                  attrs: { color: "#78be20" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "search__input",
                class: { active: _vm.searchBarIsActive },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchTerm,
                      expression: "searchTerm",
                    },
                  ],
                  ref: "searchInput",
                  attrs: {
                    name: "searchbar",
                    type: "search",
                    placeholder: _vm._(
                      "Rechercher le nom ou la référence de votre teinte"
                    ),
                  },
                  domProps: { value: _vm.searchTerm },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.searchTerm = $event.target.value
                      },
                      _vm.onSearchInput,
                    ],
                    change: _vm.onSearchChange,
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "colors",
          staticClass: "colors-container",
          class: {
            "open-palette": _vm.isPaletteOpen,
            "color-selected": _vm.selectedColor,
          },
          on: {
            scroll: function ($event) {
              return _vm.movePalette($event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "colors-container-wrapper" },
            [
              _vm.observer !== null
                ? [
                    _vm._l(_vm.colors, function ([uid, color], index) {
                      return _c(
                        "div",
                        { key: uid },
                        [
                          color !== null
                            ? _c("Color", {
                                ref: uid,
                                refInFor: true,
                                attrs: {
                                  uid: uid,
                                  color: color,
                                  observer:
                                    index % _vm.COLORS_BY_ROW === 0
                                      ? _vm.observer
                                      : null,
                                },
                              })
                            : _c("div", { staticClass: "blank" }),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "custom-color-palette" },
                      [
                        _c("color-palette", {
                          attrs: { "open-by-default": "" },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("Footer"),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }