var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "color-picture" }, [
    _c("div", { staticClass: "color-picture__header" }, [_c("ButtonBack")], 1),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "color-picture__sub-header" },
      [
        _c("p", [_vm._v(_vm._s(_vm._("Coloriser votre photo")))]),
        _vm._v(" "),
        _vm.isUserUploadEnabled
          ? _c("ButtonShare", {
              attrs: { mode: "SHARE_PICTURE", label: _vm._("Partager") },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "color-picture__content" },
      [_c("colorize"), _vm._v(" "), _c("Footer")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }