var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "color-suggestion" }, [
    _c("div", { staticClass: "color-suggestion-wrapper" }, [
      _c("div", { staticClass: "color-section divider" }, [
        _c("h2", { staticClass: "font-size--06 mu-mb-100" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm._("Couleur consultée")) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "color-similar" },
          [
            _c("color-card", {
              staticClass: "color-similar__item",
              attrs: { uid: _vm.colorUid, color: _vm.colors[_vm.colorUid] },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "color-section" }, [
        _c("h2", { staticClass: "font-size--06 mu-mb-100" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm._("Couleurs similaires")) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "color-similar" },
          _vm._l(_vm.colorsObject.adjacent_colors, function (item, index) {
            return _c("color-card", {
              key: index,
              staticClass: "color-similar__item",
              attrs: { uid: item, color: _vm.colors[item], "can-interact": "" },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "color-section" }, [
        _c("h2", { staticClass: "font-size--06 mu-mb-100" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm._("Couleurs complémentaires")) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "color-additional" },
          _vm._l(_vm.colorsObject.complementary_colors, function (item, index) {
            return _c("color-card", {
              key: index,
              staticClass: "color-additional__item",
              attrs: { uid: item, color: _vm.colors[item], "can-interact": "" },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "close-btn", on: { click: _vm.closeModal } },
        [_c("MIconControlCross32", { attrs: { color: "#3c3738" } })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }