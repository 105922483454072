<template>
    <div
        :id="uid"
        :class="['colors__item', {active: isColorSelected()}]"
        @click="onColorClick"
    >
        <div :style="{backgroundColor: color.rgbhex, color: isColorLight() ? 'black' : 'white', border: isColorLight() && 'solid 1px #a19ba2'}">
            <div>
                <MIconNotificationCircleAvailable32
                    :class="[isColorLight() ? '' : 'light', isColorSelected() ? 'show':'hide' ]"
                />
            </div>
            <span>
                {{ getDisplayName(color) }}
            </span>
        </div>
    </div>
</template>

<script>
import { MIconNotificationCircleAvailable32 } from "@mozaic-ds/icons/vue/index.vue";
import self from "../../../index";
import { convertToRGB, isLight, getDisplayName } from "../../helpers/colorHelper";
import EventBus from "../../helpers/event-bus";
import constants from "../../constants";
import { sendEvent } from "../../services/marketing";

const {
    modules,
} = self.app;

export default {
    name: "Color",
    components: {
        MIconNotificationCircleAvailable32,
    },
    props: {
        observer: {
            type: IntersectionObserver,
            required: false,
            default: null,
        },
        uid: {
            type: String,
            required: true,
        },
        color: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            getDisplayName,
            COLORS_LIMIT: constants.COLORS_LIMIT,
        };
    },
    computed: {
        colors() {
            return this.$store.getters["colors/colors"];
        },
        selectedColors() {
            return this.$store.getters["cartData/colors"];
        },
    },
    mounted() {
        if (this.observer) {
            this.observer.observe(this.$el);
        }
    },
    methods: {
        isColorLight() {
            const rgb = convertToRGB(this.color.rgbhex);

            return isLight(rgb);
        },
        isColorSelected() {
            return this.$store.getters["cartData/isColorSelected"](this.uid);
        },
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        onColorClick(event) {
            if (this.isColorSelected()) {
                this.$store.dispatch("cartData/removeColor", this.uid);
            } else if (this.selectedColors.length === constants.COLORS_LIMIT) {
                const messageInfo = {
                    title: this._("Liste complète"),
                    text: this._("Vous avez déjà 6 couleurs dans votre liste : veuillez supprimer des couleurs pour en ajouter de nouvelles"),
                    status: "info",
                    type: "INFO",
                };
                EventBus.$emit("toaster-message", messageInfo);

            } else {
                const color = this.colors[this.uid];
                this.$store.dispatch("cartData/addColor", this.uid);
                sendEvent("element.select", {
                    element_category: "color chart",
                    element_name: "color selected",
                    element_value: color ? color.name : "",
                }, event ? event.target : null);

            }
        },

    },
};

</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.colors__item {
  & > div {
    margin: 5px;
    padding: $mu025;
    transition: 0.3s;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    @include set-border-radius('l');

    @include set-from-screen(m) {
      min-height: 86px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 7px;
    }

    svg {
      transition: 0.3s;
      height: 26px;
      width: 26px;
      margin: 0 auto $mu025;

      @include set-from-screen(m) {
        height: 32px;
        width: 32px;
      }
    }

    .light {
      fill: white;
    }

    .hide {
      opacity: 0;
      transform: scale(0);
      display: none;
    }
  }

  span {
    word-break: break-word;
    line-height: 1.2 !important;
    white-space: initial;
    font-size: 8px;

    @include set-from-screen(m) {
      @include set-font-scale('02');
    }
  }

  &.active {
    & > div {
      box-shadow: 0 0 0 2px white, 0 0 0 4px $color-grey-600;

      @include set-from-screen(m) {
        box-shadow: 0 0 0 4px white, 0 0 0 6px $color-grey-600;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
