var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "image-share" }, [
    _c("div", { staticClass: "color-picture__header" }, [
      _c(
        "div",
        { staticClass: "button-back" },
        [
          _c(
            "router-link",
            { staticClass: "simple-link", attrs: { to: { name: "index" } } },
            [
              _c("MIconArrowArrowLeft24", { attrs: { color: "#78be20" } }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm._("Retour à l'accueil")))]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "color-picture__sub-header" }, [
      _c("p", [_vm._v(_vm._s(_vm._("Mon image de partage")))]),
    ]),
    _vm._v(" "),
    _vm.error
      ? _c("div", [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._("Aucune image trouvée, elle a peut être été supprimée.")
              ) +
              "\n    "
          ),
        ])
      : _c(
          "div",
          { staticClass: "color-picture__content" },
          [
            _c("div", [
              _c("div", { staticClass: "color-picture__content-picture" }, [
                _c("img", { attrs: { src: _vm.image } }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "color-picture__content-info" },
                _vm._l(_vm.imageColors, function (color) {
                  return _c("ColorInfo", { key: color, attrs: { uid: color } })
                }),
                1
              ),
            ]),
            _vm._v(" "),
            _c("Footer"),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }