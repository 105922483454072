<template>
    <div id="overlay">
        <Toaster />
        <ModalMail />
        <ModalGuidelines />
        <!--    you can add here your modals components -->
        <!--    you can add here your loader -->
    </div>
</template>
<script>
import Toaster from "../toaster/toaster.vue";
import ModalMail from "../modal-mail/modal-mail.vue";
import ModalGuidelines from "../modal-guidelines/modal-guidelines.vue";

export default {
    name: "Overlay",
    components: {
        Toaster,
        ModalMail,
        ModalGuidelines,
    },
};
</script>
<style lang="scss" >
#overlay {
  z-index: 2000;
}
</style>
