var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("h1", [
          _vm._v(
            "\n            " +
              _vm._s(_vm._("Espace administration")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("Loader"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }