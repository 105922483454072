var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "share-container",
      class: [
        _vm.isOpen && "open",
        _vm.inPalette ? "palette-mode" : "header-mode",
      ],
    },
    [
      _c(
        "button",
        {
          staticClass: "btn-share",
          class: !_vm.isOpen && "show",
          attrs: { title: _vm._("Partager"), "data-cerberus": "share-button" },
          on: { click: _vm.openBar },
        },
        [
          _c("MIconShareShareAndroid24", { attrs: { color: "#554f52" } }),
          _vm._v(" "),
          _c("p", { class: [_vm.isOpen && "open"] }, [
            _vm._v("\n            " + _vm._s(_vm.label) + "\n        "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { class: ["share-actions", _vm.isOpen && "show"] }, [
        _c(
          "button",
          {
            attrs: {
              title: _vm._("Envoyer par email"),
              "data-cerberus": "share-button-email",
            },
            on: { click: _vm.openMailModal },
          },
          [_c("img", { attrs: { src: _vm.mailIcon, alt: "mail-icon" } })]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            attrs: {
              title: _vm._("Partager le lien"),
              "data-cerberus": "share-button-link",
            },
            on: { click: _vm.shareLink },
          },
          [_c("MIconDisplayExternalLink24")],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            attrs: { title: _vm._("Télécharger") },
            on: { click: _vm.download },
          },
          [_c("MIconDownloadApp24")],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            attrs: {
              title: _vm._("Fermer"),
              "data-cerberus": "share-button-close",
            },
            on: { click: _vm.closeBar },
          },
          [_c("MIconControlCross24", { attrs: { color: "white" } })],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }