<template>
    <div>
        <div
            id="app"
            class="home"
        >
            <div class="home-title">
                <h1>{{ _("Projetez-vous dans votre choix de peinture couleur en quelques clics") }}</h1>
                <p>{{ _("Pour démarrer, choisissez une des options ci-dessous") }}</p>
            </div>

            <div class="card-wrapper">
                <div>
                    <MainCard
                        v-bind:title="_('A partir du nuancier de couleurs')"
                        v-bind:description="_('Explorez notre nuancier de peinture couleur et visualisez le rendu des teintes de votre choix.')"
                        data-cerberus="from-palette"
                        cover-webp="assets/images/accueil/mon_simulateur_couleur_nuancier.webp"
                        cover-img="assets/images/accueil/mon_simulateur_couleur_nuancier.jpg"
                        link="color_chart"
                    />
                </div>

                <div>
                    <MainCard
                        v-bind:title="_('A partir d\'une photo')"
                        v-bind:description="_('Prenez une photo de votre pièce ou choisissez une photo de notre galerie et testez le rendu de vos peintures couleurs préférées.')"
                        data-cerberus="from-picture"
                        cover-webp="assets/images/accueil/mon_simulateur_couleur_photo.webp"
                        cover-img="assets/images/accueil/mon_simulateur_couleur_photo.jpg"
                        link="color_menu"
                    />
                </div>
            </div>
        </div>
        <Footer fixed />
    </div>
</template>

<script>
import self from "../../../index";
import MainCard from "../../components/main-card/main-card.vue";

const {
    modules,
} = self.app;

export default {
    name: "Home",
    components: {
        MainCard,
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.home {
  height: calc(100vh - #{$nav-height});
  overflow: auto;

  @include set-from-screen(m) {
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{$nav-height + $footer-height});
  }

  @include set-from-screen(l-medium) {
    height: calc(100vh - #{$nav-height-desktop + $footer-height});
  }

  &-title {
    margin: $mu200 0;

    h1 {
      padding: 0 $mu100;
      color: $color-primary-01-500;
    }

    p {
      color: $color-grey-900;
      text-align: center;
      padding: 0 $mu100;
    }
  }

  .card-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    p {
      margin: 0;
    }

    & > * {
      padding: 1rem;
      width: 100%;
      display: flex;

      @include set-from-screen(m) {
        width: 50%;

        .main-card__cover {
          height: 200px;
        }
      }

      @include set-from-screen(l) {
        width: 40%;

        .main-card__cover {
          height: 250px;
        }
      }

      @include set-from-screen(xl-medium) {
        .main-card__cover {
          height: 300px;
        }
      }
    }
  }

  // this fix display bug and avoid scroll useless
  @media screen and (max-width: 1500px) and (max-height: 800px) {
    .home-title {
      margin: $mu100 0;

      h1 {
        @include set-font-scale('08');
      }
    }

    .card-wrapper {
      .main-card__cover {
        height: 200px !important;
      }
    }
  }
}
</style>
