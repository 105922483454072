<template>
    <div>
        <ButtonBack />
        <div class="color-menu-wrapper">
            <div class="situation-perso__header">
                <p>{{ _("Sélectionnez la méthode qui vous convient") }}</p>
            </div>

            <div class="cards">
                <card-style-picto
                    data-cerberus="upload-picture"
                    @click="goToLink('situation_perso')"
                >
                    <div>
                        <MIconDownloadApp64 />
                    </div>

                    <p>{{ _("Télécharger votre photo") }}</p>
                </card-style-picto>
                <card-style-picto
                    data-cerberus="chose-picture"
                    @click="goToLink('rooms')"
                >
                    <div>
                        <MIconInspiration64 />
                    </div>

                    <p>{{ _("Choisir une photo de notre galerie") }}</p>
                </card-style-picto>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import { MIconDownloadApp64, MIconInspiration64 } from "@mozaic-ds/icons/vue/index.vue";
import ButtonBack from "../../components/button-back/button-back.vue";
import Footer from "../../components/footer/footer.vue";
import self from "../../../index";
import CardStylePicto from "../../components/card-style/card-style-picto.vue";

const {
    modules,
} = self.app;

export default {
    name: "ColorMenu",
    components: {
        ButtonBack,
        MIconDownloadApp64,
        MIconInspiration64,
        CardStylePicto,
        Footer,
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        goToLink(linkName) {
            this.$router.push({ name: linkName });
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.color-menu-wrapper {
  height: calc(100vh - #{$nav-height * 2});
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  @include set-from-screen(l-medium) {
    height: calc(100vh - #{$nav-height-desktop + $nav-height});
    display: flex;
    flex-direction: column;
  }

  h1 {
    color: $color-primary-01-500;
    margin: 0;
    padding: $mu300 $mu100 $mu100;
  }
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $mu200 $mu200 $mu500;
  grid-column-gap: $mu100;
  row-gap: $mu100;
  overflow: auto;

  @include set-from-screen(m) {
    flex-direction: row;
    padding: $mu200;
    align-items: flex-start;
    grid-column-gap: $mu200;
  }

  .card {
    @include set-font-weight('semi-bold');
    @include set-border-radius('l');
    @include set-font-scale('05');

    background: white;
    width: 100%;
    max-width: 400px;
    color: $color-font-darker;
    text-align: center;
    box-shadow: 0 2px 15px rgba(black, 0.2);
    padding: $mu200;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    transition: 0.3s;

    @include set-from-screen(m) {
      align-self: flex-start;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 2px 5px rgba(black, 0.4);
    }

    div {
      background: $color_primary_01_500;
      border-radius: 50%;
      padding: $mu150;
      position: relative;
      margin-bottom: $mu200;

      @include set-from-screen(m) {
        padding: $mu200;
      }

      svg {
        fill: white;
        height: 46px;
        width: 46px;
      }

      &::before {
        content: '';
        background: $color-grey-300;
        width: 65%;
        height: 1px;
        position: absolute;
        bottom: 0;
        display: block;
        left: 50%;
        transform: translate(-50%, $mu150);
      }
    }

    p {
      margin-bottom: 0;
    }
  }
}
</style>
