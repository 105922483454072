import Vue from "vue";
import MozaicVue from "@mozaic-ds/vue";
import * as Sentry from "@sentry/vue";
import vSelect from "vue-select";
import { getTmsInstance } from "@lmfr-int-core/js/assets/commons/_tms";
import defaultConfig from "defaultConfig";
import Footer from "./components/footer/footer.vue";
import store from "./store/index";
import router from "./router/index";
import App from "./app.vue";
import self from "..";
import { getLanguage } from "../../internationalization-utility/src/internationalization-controller";

import "vue-select/dist/vue-select.css";

// Import config
import config from "../../config";

// Import CSS of Mozaic components before loading them in our Vue app
import "@mozaic-ds/vue/dist/mozaic-vue.css";

const { modules } = self.app;

function pushFirstFragment(fragment) {
    getTmsInstance().pushFragment([
        {
            name: "cdl_environment_currency",
            value: "EUR",
        },
        {
            name: "cdl_environment_work",
            value: fragment.isProductionEnv ? "prod" : "prep",
        },
        {
            name: "cdl_environment_country",
            value: fragment.country,
        },
    ]);
}

/**
 * Instanciate the main Vue, on the app element
 * @type {Boolean}
 */
export default class VuejsController {

    constructor() {
        const {
            NODE_ENV, DATADOME_JS_KEY, SENTRY_DSN, SENTRY_ENV, GA_ENV,
        } = config;

        Vue.config.productionTip = false;
        Vue.config.devtools = (["dev", "caas", "development"].includes(NODE_ENV));

        Vue.component("VSelect", vSelect);
        Vue.component("Footer", Footer);

        // This is a global mixin, it is applied to every vue instance
        Vue.mixin(
            {
                data() {
                    return {
                        get imageRootPath() {
                            return "assets/images/";
                        },
                        get colorPath() {
                            return "assets/data/";
                        },
                        // Function to test if USER_DISABLE_UPLOAD_IMAGES is true
                        get isUserUploadEnabled() {
                            return config.USER_DISABLE_UPLOAD_IMAGES !== "true";
                        },
                        getAdminExternalDomainWhitelist() {
                            const locale = modules.stonejs.getLocale();
                            const localePrefix = locale ? `${locale.toUpperCase()}_` : "";

                            let adminDomainListSplitted = config[`${localePrefix}ADMIN_EXTERNAL_DOMAINS_WHITELIST`]
                                || config.ADMIN_EXTERNAL_DOMAINS_WHITELIST;
                            if (adminDomainListSplitted) {
                                adminDomainListSplitted = adminDomainListSplitted.split(";");
                            }
                            return adminDomainListSplitted;
                        },
                        getLeroyMerlinDomain() {
                            const domainList = this.getAdminExternalDomainWhitelist();
                            // Return the first domain of the list or www.leroymerlin.fr
                            if (domainList && domainList[0]) {
                                return domainList[0];
                            }
                            return "www.leroymerlin.fr";
                        },
                        getGCUEndpoint() {
                            const locale = modules.stonejs.getLocale();
                            const localePrefix = locale ? `${locale.toUpperCase()}_` : "";
                            return config[`${localePrefix}GCU_ENDPOINT`] || config.GCU_ENDPOINT;
                        },
                        getPrivacyEndpoint() {
                            const locale = modules.stonejs.getLocale();
                            const localePrefix = locale ? `${locale.toUpperCase()}_` : "";
                            return config[`${localePrefix}PRIVACY_ENDPOINT`] || config.PRIVACY_ENDPOINT;
                        },
                        get cguUrl() {
                            const cguEndpoint = this.getGCUEndpoint();
                            // Test if cguEndpoint is an absolute url
                            if (cguEndpoint.indexOf("http") === 0) {
                                return cguEndpoint;
                            }
                            const domain = this.getLeroyMerlinDomain();
                            return `https://${domain}${cguEndpoint}`;
                        },
                        get privacyUrl() {
                            const privacyEndpoint = this.getPrivacyEndpoint();
                            // Test if privacyEndpoint is an absolute url
                            if (privacyEndpoint.indexOf("http") === 0) {
                                return privacyEndpoint;
                            }
                            const domain = this.getLeroyMerlinDomain();
                            return `https://${domain}${privacyEndpoint}`;
                        },
                    };
                },
            }
        );

        Vue.use(MozaicVue);

        window.GA_ENV = GA_ENV;

        // =>>> Datadome =>>>
        if (DATADOME_JS_KEY) {
            const datadomeScript = document.createElement("script");
            datadomeScript.text = `!function(a,b,c,d,e,f){a.ddjskey=e;a.ddoptions=f||null;var m=b.createElement(c),n=b.getElementsByTagName(c)[0];m.async=1,m.src=d,n.parentNode.insertBefore(m,n)}(window,document,"script","https://js.datadome.co/tags.js","${DATADOME_JS_KEY}", { ajaxListenerPath : true });`;
            document.head.appendChild(datadomeScript);
        }
        // <<<= Datadome <<<=

        // =>>> Analytics =>>>
        const isAnalyticsEnv = ["prod", "prep", "uat1"].includes(GA_ENV);
        const isProductionEnv = GA_ENV === "prod";
        const language = getLanguage();
        const alpha2 = language.split("_")[0];

        if (isAnalyticsEnv) {
            const languageConfig = defaultConfig.topLevelDomainToLanguage[alpha2];   
            if (languageConfig) {
                const scriptWithoutDomain = "((t,m,s)=>{t[m]={dataLayer:{},prepareFragment:function(){const r=this._q.length+1;return this._q.push([\"prepareFragment\",r]),a=>{t[m].pushFragment(a,r)}},_q:[]},[\"dataLayerReady\",\"pushFragment\",\"pushEvent\"].forEach((r=>{t[m][r]=function(){this._q.push([r].concat(Array.prototype.slice.call(arguments)))}}));const a=document.createElement(\"script\");a.type=\"text/javascript\",a.defer=!0,a.setAttribute(\"src\",(s||\"\").concat(\"/tms-module/resources/tms.js\")),document.head.appendChild(a)})(window,\"tms\",";
                const { alpha3 } = languageConfig;
                const tmsMainScript = document.createElement("script");
                tmsMainScript.type = "text/javascript";
                tmsMainScript.text = isProductionEnv
                    ? scriptWithoutDomain.concat(`"${defaultConfig.topLevelDomainToLanguage[alpha2].domainProd}");`)
                    : scriptWithoutDomain.concat(`"${defaultConfig.topLevelDomainToLanguage[alpha2].domainPreProd}");`);
                document.head.appendChild(tmsMainScript);

                if (alpha2 === "it" || alpha2 === "pt") {
                    getTmsInstance().pushFragment([
                        {
                            name: "cdl_environment_country",
                            value: alpha2 === "it" ? "ITA" : "PRT",
                        },
                        {
                            name: "cdl_environment_currency",
                            value: "EUR",
                        },
                        {
                            name: "cdl_environment_work",
                            value: isProductionEnv ? "prod" : "prep",
                        },
                    ]);
                } else {
                    pushFirstFragment({ country: alpha3, isProductionEnv });
                }
            }
        }
        // <<<= Analytics <<<=

        // Cookies
        if (alpha2 === "fr") {
            const cookiesMainScript = document.createElement("script");
            cookiesMainScript.type = "text/javascript";
            const cookiesScriptWithoutDomain = "((p,r,v)=>{p[r]={isGroupOptin:()=>!1,_q:[]},[\"ready\",\"openModal\"].forEach((o=>{p[r][o]=function(){this._q.push([o].concat(Array.prototype.slice.call(arguments)))}}));const a=document.createElement(\"script\");a.type=\"text/javascript\",a.defer=!0,a.setAttribute(\"src\",(v||\"\").concat(\"/privacy-module/resources/modal.js\")),document.head.appendChild(a)})(window,\"privacy\",";
            cookiesMainScript.text = isProductionEnv
                ? cookiesScriptWithoutDomain.concat(`"${defaultConfig.topLevelDomainToLanguage[alpha2].domainProd}");`)
                : cookiesScriptWithoutDomain.concat(`"${defaultConfig.topLevelDomainToLanguage[alpha2].domainPreProd}");`);
            document.head.appendChild(cookiesMainScript);
        }

        if (SENTRY_ENV) {
            Sentry.init({
                Vue,
                dsn: SENTRY_DSN,
                environment: SENTRY_ENV,
            });
        }

        // Data binding and vue creation
        this.app = new Vue({
            el: "#app",
            router,
            store,
            render: (h) => h(App),
        });
    }

}
