<template>
    <div
        v-if="color"
        :title="_('Voir la fiche couleur')"
        :class="{
            'color-card': true,
            'with-interaction': canInteract,
        }"
    >
        <div
            :class="{
                color: true,
                selected: isColorSelected(),
            }"
            :style="{backgroundColor: color.rgbhex, borderColor: isColorLight(color) && '#bab6bc'}"
            @click="onColorClick"
        >
            <MIconControlCircleMore64
                v-if="!isColorSelected()"
                class="add"
                :style="{fill: isColorLight(color) ? 'black' : 'white'}"
            />

            <MIconNotificationCircleAvailable64
                v-else
                :style="{fill: isColorLight(color) ? 'black' : 'white'}"
            />
        </div>

        <p class="font-size--01">
            {{ getDisplayName(color) }}
        </p>
    </div>
</template>

<script>
import { MIconControlCircleMore64, MIconNotificationCircleAvailable64 } from "@mozaic-ds/icons/vue/index.vue";
import { convertToRGB, getDisplayName, isLight } from "../../helpers/colorHelper";
import self from "../../../index";
import EventBus from "../../helpers/event-bus";
import constants from "../../constants";

const {
    modules,
} = self.app;

export default {
    name: "ColorCard",
    components: {
        MIconControlCircleMore64,
        MIconNotificationCircleAvailable64,
    },
    props: {
        uid: {
            type: String,
            required: true,
        },
        color: {
            type: Object,
            required: false,
        },
        canInteract: {
            type: Boolean,
            required: false,
            default: false,
        },
        showColorLink: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            getDisplayName,
            COLORS_LIMIT: constants.COLORS_LIMIT,
        };
    },
    computed: {
        selectedColors() {
            return this.$store.getters["cartData/colors"];
        },
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        isColorSelected() {
            return this.canInteract && this.$store.getters["cartData/isColorSelected"](this.uid);
        },
        onColorClick() {
            if (this.showColorLink) {
                this.$router.push({ name: "color_show", params: { uid: this.uid } });
            }

            if (!this.canInteract) {
                return;
            }

            if (this.selectedColors.length === constants.COLORS_LIMIT) {
                const messageInfo = {
                    title: this._("Liste complète"),
                    text: this._("Vous avez déjà 6 couleurs dans votre liste : veuillez supprimer des couleurs pour en ajouter de nouvelles"),
                    status: "info",
                    type: "INFO",
                };
                EventBus.$emit("toaster-message", messageInfo);
            } else if (this.isColorSelected()) {
                this.$store.dispatch("cartData/removeColor", this.uid);
            } else {
                this.$store.dispatch("cartData/addColor", this.uid);
            }
        },
        isColorLight(color) {
            const rgb = convertToRGB(color.rgbhex);

            return isLight(rgb);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/Base/font.scss";

.color-card {
  box-sizing: border-box;

  p {
    text-align: left;
    color: $color-grey-900;
  }

  & > * {
    &:nth-child(1) {
      width: 100%;
      height: 60px;
      border-radius: 5px;
    }
  }

  .color {
    transition: 0.3s;
    padding: $mu050;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px transparent;

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }

    .add {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s ease;
    }
  }

  &.with-interaction {
    position: relative;

    .color {
      svg {
        width: 100%;
        height: 100%;
      }

      &:hover {
        transform: scale(1.05);
        transition: 0.5s;
        cursor: pointer;

        .add {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
</style>
