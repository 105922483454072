import config from "defaultConfig";
import self from "..";

const { modules } = self.app;

// Get the current language
export function getLanguage() {
    const { hostname } = window.location;
    const extension = hostname.split(".").pop();
    const languageConfig = config.topLevelDomainToLanguage[extension];
    const languageFromConfig = languageConfig && languageConfig.locale;
    const languageFromUser = modules.stonejs.guessUserLanguage();
    const language = languageFromConfig || languageFromUser;
    return language;
}

export default class InternationalizationController {

    constructor() {
        const catalogs = require("translationsCatalog");
        modules.stonejs.addCatalogs(catalogs);
        modules.stonejs.setBestMatchingLocale(getLanguage());
        modules.stonejs.enableDomScan(true);
    }

}
