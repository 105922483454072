var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["footer", _vm.fixed && "absolute"] }, [
    _c("span", [
      _vm._v(
        "© " +
          _vm._s(_vm._("Mon simulateur couleur Leroy Merlin")) +
          " " +
          _vm._s(new Date().getFullYear())
      ),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "separator" }, [_vm._v(" - ")]),
    _vm._v(" "),
    _c("a", { attrs: { href: _vm.cguUrl, target: "_blank" } }, [
      _vm._v(_vm._s(_vm._("Crédits et mentions légales"))),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "separator" }, [_vm._v(" - ")]),
    _vm._v(" "),
    _c("a", { attrs: { href: _vm.privacyUrl, target: "_blank" } }, [
      _vm._v(_vm._s(_vm._("Données personnelles"))),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "separator" }, [_vm._v(" - ")]),
    _vm._v(" "),
    _c("a", { attrs: { href: "/CREDITS.txt", target: "_blank" } }, [
      _vm._v(_vm._s(_vm._("Licences"))),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "to-the-right" }, [
      _vm._v("v" + _vm._s(_vm.currentVersion)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }