<template>
    <div :class="['admin-card', {disable: !picture.enable}]">
        <div :class="['admin-card__cover', {generic: isGeneric}]">
            <img :src="`/${picture.thumbnail_image}`">
        </div>
        <div class="admin-card__content">
            <div>
                <button
                    v-if="picture.enable"
                    class="btn-action active"
                    @click="disablePicture"
                >
                    <span />
                    <span>{{ _("Actif") }}</span>
                </button>
                <button
                    v-else
                    class="btn-action"
                    @click="enablePicture"
                >
                    <span />
                    <span>{{ _("Inactif") }}</span>
                </button>
                <m-button
                    icon="PublishEdit24"
                    theme="bordered-neutral"
                    class="btn-edit"
                    @click="editPicture"
                />
            </div>

            <p v-show="isGeneric">
                {{ _("Image générique") }}
            </p>
        </div>
    </div>
</template>

<script>
import self from "../../../index";

const {
    modules,
} = self.app;

export default {
    name: "PictureCard",
    props: {
        picture: {
            type: Object,
            required: true,
        },
        categoryName: {
            type: String,
            required: true,
        },
        isGeneric: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        disablePicture() {
            const picture = { ...this.picture, enable: false };
            this.$store.commit("imagesGallery/UPDATE_IMAGES", { picture, categoryName: this.categoryName });
        },
        enablePicture() {
            const picture = { ...this.picture, enable: true };
            this.$store.commit("imagesGallery/UPDATE_IMAGES", { picture, categoryName: this.categoryName });
        },
        editPicture() {
            this.$emit("edit-picture", { indexPicture: this.picture.uuid, categoryName: this.categoryName });
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.admin-card {
  transition: 0.3s;
  background: white;
  overflow: hidden;

  @include set-border-radius('l');

  cursor: grab;
  box-shadow: 0 0 16px rgba(black, 0.3);

  &__cover {
    width: 300px;
    height: 200px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding: $mu050 $mu100 $mu100;

    & > div {
      display: flex;
      justify-content: space-between;
    }

    p {
      margin: 0;

      @include set-font-scale('02');
    }

    .btn-edit {
      border-radius: 50%;
      border: none;

      svg {
        fill: $color-grey-700;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .btn-action {
      display: flex;
      align-items: center;
      border: none;
      background: transparent;
      padding: 0;

      span {
        &:first-child {
          border-radius: 50%;
          display: block;
          width: 20px;
          height: 20px;
          background: white;
          border: solid 1px black;
          margin-right: $mu100;
        }

        &:last-child {
          font-size: 16px;

          @include set-font-scale('04');
          @include set-font-weight('semi-bold');
        }
      }

      &.active {
        span:first-child {
          border-color: white;
          background: $color-primary-01-500;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
