/**
* This function converts a HEX color into a RGB color.
* @param {String} hex - hexadecimal color
* @return {Array} - RGB color as an array : [red, green, blue]
*/
function convertToRGB(hex) {
    if (!hex) {
        throw new Error(`InvalidColorFormat: ${hex} is not a valid hexadecimal RGB color`);
    }

    const color = (hex[0] !== "#") ? `#${hex}` : hex;

    // #ff0000
    if (color.match(/^#[a-z0-9]{6}$/i)) {
        return [
            parseInt(color[1] + color[2], 16),  // red
            parseInt(color[3] + color[4], 16),  // green
            parseInt(color[5] + color[6], 16),  // blue
        ];

    // #f00
    } if (color.match(/^#[a-z0-9]{3}$/i)) {
        return [
            parseInt(color[1] + color[1], 16),  // red
            parseInt(color[2] + color[2], 16),  // green
            parseInt(color[3] + color[3], 16),  // blue
        ];
    }

    throw new Error(`InvalidColorFormat: ${hex} is not a valid hexadecimal RGB color`);
}

/**
 * This function tells if a RGB color is a light color or no (dark).
 * @param {Array} rgb - RGB color as an array : [red, green, blue]
 * @return {Boolean} - true if the color is light, false if it's dark
 */
function isLight(rgb) {
    if (!Array.isArray(rgb) && rgb.length !== 3) {
        throw new Error(`InvalidColorFormat: ${rgb} is not an array with 3 values.`);
    }

    /**
     * Calculating the perceived luminance of a RGB color.
     * Source : https://www.w3.org/TR/AERT/#color-contrast
    */
    const luminance = 1 - (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;

    return (luminance < 0.5);
}

function getDisplayName(color) {
    return color.name || color.ref;
}

export {
    convertToRGB,
    isLight,
    getDisplayName,
};
