var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("h1", [
        _vm._v(
          "\n            " +
            _vm._s(_vm._("Espace administration")) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "form",
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.authenticate.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "m-field",
            {
              attrs: {
                id: "username",
                label: _vm._("Nom d'utilisateur"),
                "is-invalid": _vm.hasError,
                "error-message": _vm._(
                  "Les identifiants renseignés sont invalides."
                ),
              },
            },
            [
              _c("m-text-input", {
                staticClass: "mc-field__input",
                attrs: { id: "username", required: "" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "m-field",
            {
              attrs: {
                id: "password",
                label: _vm._("Mot de passe"),
                "is-invalid": _vm.hasError,
                "error-message": _vm._(
                  "Les identifiants renseignés sont invalides."
                ),
              },
            },
            [
              _c("m-text-input", {
                staticClass: "mc-field__input",
                attrs: { id: "password", required: "", type: "password" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c("m-button", {
                attrs: {
                  theme: "solid-neutral",
                  type: "submit",
                  label: _vm._("Se connecter"),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }