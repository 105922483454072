<template>
    <div class="main">
        <div>
            <Nav />
            <div v-if="isLoading">
                <Loader />
            </div>
            <div v-else-if="hasError">
                {{ _("Désolé une erreur est survenue lors de la récupération des peintures.") }}
            </div>
            <div v-else>
                <router-view />
            </div>
        </div>
        <Overlay />
    </div>
</template>

<script>
import Nav from "./components/navbar/navbar.vue";
import Loader from "./components/loader/loader.vue";
import Overlay from "./components/overlay/overlay.vue";
import self from "../index";

const {
    modules,
} = self.app;

export default {
    name: "App",
    components: {
        Nav,
        Loader,
        Overlay,
    },
    computed: {
        hasError() {
            return this.$store.getters["colors/error"];
        },
        isLoading() {
            return (
                this.$store.getters["colors/isLoading"]
                || this.$store.getters["imagesGallery/isLoading"]
            );
        },
    },
    async beforeCreate() {
        const locale = modules.stonejs.getLocale();
        this.$store.dispatch("colors/fetchColors", locale);
        this.$store.dispatch("imagesGallery/fetchImages", locale);
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
    },
};
</script>
