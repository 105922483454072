<template>
    <div>
        <div v-if="isLoading">
            <Loader />
        </div>
        <div v-else-if="hasError">
            {{ _("Désolé une erreur est survenue lors de la récupération des peintures.") }}
        </div>

        <div id="obsidian-app" />
    </div>
</template>

<script>
import Loader from "../../../components/loader/loader.vue";
import self from "../../../../index";
import EventBus from "../../../helpers/event-bus";
import { getCookie } from "../../../helpers/cookieHelper";

const obsidianApp = require("obsidian-api/lib/integration");

const {
    modules,
} = self.app;

export default {
    name: "AdminColorManager",
    components: {
        Loader,
    },
    computed: {
        isLoading() {
            return this.$store.getters["colors/isLoading"];
        },
        hasError() {
            return this.$store.getters["colors/error"];
        },
    },
    mounted() {
        const colors = this.$store.getters["colors/colors"];
        const aspects = this.$store.getters["colors/aspects"];
        const palettes = this.$store.getters["colors/palettes"];
        this.initColorManager({ colors, aspects, palettes });
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        initColorManager(data) {
            const locale = modules.stonejs.getLocale();
            const app = obsidianApp({
                htmlNode: "#obsidian-app",
                appUrl: "/palette-editor/",
                config: {
                    lang: locale,
                    hideNewButton: true,
                    hideOpenButton: false,
                    hideDownloadButton: false,
                    showApiSaveButtonAction: true,
                    saveButtonAction: "api",
                    allowedDomains: this.getAdminExternalDomainWhitelist(),
                },
            });

            app.on("ready", () => {
                app.importFromJsonString(JSON.stringify(data))
                    .catch(() => {
                        const messageError = {
                            title: this._("Erreur"),
                            text: this._("Une erreur est survenue lors de l'import des couleurs du nuancier."),
                            status: "error",
                            type: "ERROR",
                        };
                        EventBus.$emit("toaster-message", messageError);
                    });
            });

            app.on("save-json", () => {
                app.exportAsJsonString()
                    .then((jsonData) => {
                        this.save(jsonData);
                    })
                    .catch(() => {
                        const messageError = {
                            title: this._("Erreur"),
                            text: this._("Une erreur est survenue lors de la sauvegarde de vos modifications."),
                            status: "error",
                            type: "ERROR",
                        };
                        EventBus.$emit("toaster-message", messageError);
                    });
            });
        },
        save(json) {
            const csrfToken = getCookie("XSRF-TOKEN");
            const locale = modules.stonejs.getLocale();

            fetch(`/save/colors?locale=${locale}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": csrfToken,
                },
                body: json,
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Error while saving JSON");
                    } else {
                        return res.json();
                    }
                })
                .then((body) => {
                    if (body.error !== null) {
                        throw new Error("Error while saving JSON");
                    } else {
                        const messageError = {
                            title: this._("Sauvegarde réussie"),
                            text: this._("La sauvegarde de vos modifications s'est déroulée avec succès. Le nuancier a été mis à jour dans l'application."),
                            status: "success",
                            type: "SAVE",
                        };
                        EventBus.$emit("toaster-message", messageError);
                    }
                })
                .catch(() => {
                    const messageError = {
                        title: this._("Erreur"),
                        text: this._("Une erreur est survenue lors de la sauvegarde de vos modifications."),
                        status: "error",
                        type: "ERROR",
                    };
                    EventBus.$emit("toaster-message", messageError);
                });
        },
    },
};
</script>

<style lang="scss">
#obsidian-app {
  width: 100vw;
  height: calc(100vh - 40px);
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>
