var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "navigation-wrapper" }, [
    _c(
      "div",
      { ref: "slider", staticClass: "keen-slider" },
      _vm._l(_vm.imagesSort, function (image, index) {
        return _c(
          "div",
          { key: index, staticClass: "keen-slider__slide" },
          [
            _c("CarouselCanvas", {
              ref: "carouselCanvas",
              refInFor: true,
              attrs: {
                image: image,
                load: _vm.current === index || _vm.current + 1 === index,
              },
            }),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.slider
      ? _c(
          "div",
          {
            class: {
              arrow: true,
              "arrow--left": true,
            },
            on: { click: _vm.previous },
          },
          [
            _c("MIconArrowArrowLeft48", {
              attrs: { fill: _vm.isPrevDisabled ? "#887f87" : "#222020" },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.slider
      ? _c(
          "div",
          {
            class: {
              arrow: true,
              "arrow--right": true,
            },
            on: { click: _vm.next },
          },
          [
            _c("MIconArrowArrowRight48", {
              attrs: { fill: _vm.isNextDisabled ? "#887f87" : "#222020" },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }