/* eslint-disable */
import store from "../store/index";
import config from "../../../config";

import defaultConfig from "defaultConfig";

import { getTmsInstance } from "@lmfr-int-core/js/assets/commons/_tms";

function isMobile() {
    return /android|iphone|kindle|ipad/i.test(navigator.userAgent);
}

function isBot() {
    return /bot|googlebot|crawler|spider|robot|crawling/i.test(
        navigator.userAgent
    );
}

/**
 * Send a virtual page view to Piano Analytics
 *
 * @param {string} pageName
 * @param {string} pageType
 * @param {string} pageSubType
 * @param {string} pageCategory1
 * @param {string} pageCategory2
 * @param {string} pageCategory3
 * @param {string} pageCategory4
 */
function sendPageView(
    pageName,
    pageType,
    pageSubType,
    pageCategory1,
    pageCategory2,
    pageCategory3,
    pageCategory4
) {
    const { hostname } = window.location;
    const extension = hostname.split(".").pop();
    const languageConfig = defaultConfig.topLevelDomainToLanguage[extension];
    const countryAlpha3 = languageConfig && languageConfig.alpha3;

    const fragment_vars = {
        cdl_page_name: pageName,
        cdl_page_type: pageType,
        cdl_page_subtype: pageSubType,
        cdl_page_category1: pageCategory1,
        cdl_page_category2: pageCategory2,
        cdl_page_category3: pageCategory3,
        cdl_page_category4: pageCategory4,
        cdl_environment_work: config.GA_ENV,
        cdl_environment_device: isMobile() ? "mobile" : "desktop",
        cdl_environment_shop: "",
        cdl_environment_country: countryAlpha3, // Code ISO 3166-1 alpha-3
        cdl_environment_google_bot: isBot() ? 1 : 0,
        cdl_user_authentication: "authentified",
        cdl_user_id: "",
        cdl_user_type: "particular",
        cdl_user_localtycard: "false",
    };

    try {
        if(typeof window !== "undefined"){
            getTmsInstance().pushFragment(
                Object.keys(fragment_vars).map((key) => ({
                    name: key,
                    value: fragment_vars[key],
                }))
            );
        }
    } catch (error) {
        console.error(error);
    }
}

/**
 * Send an event to Piano Analytics
 *
 * @param {('button.click'|'element.select'|'list.filter'|'internal_search_result.display'|'link.click')} eventName
 * @param {object} eventData
 */
function sendEvent(eventName, eventData, target) {
    let defaultEventData = {};
    if (eventName === "button.click") {
        const currentRoute = store.getters["history/currentRoute"];
        const meta = currentRoute.meta(currentRoute);

        defaultEventData = {
            button_type: "action",
            button_location: meta ? meta.pageName : "",
        };
    }
    try {
        if(window.tC !== undefined){
            window.tC.event.cdl_event(this, {
                event_name: eventName,
                event_data: {
                    ...defaultEventData,
                    ...eventData,
                },
            });
        }
    } catch (error) {
        console.error(error);
    }
}

export { sendPageView, sendEvent };
