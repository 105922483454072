import { check, oauthCheck } from "../api/auth";

export default async function isAuthenticated() {
    const res = await check();

    return res.status === 200;
}

async function isOAuthenticated() {
    const res = await oauthCheck();

    return res.status === 200;
}

export {
    isAuthenticated,
    isOAuthenticated,
};
