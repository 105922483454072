var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["", _vm.containerClass] },
    _vm._l(_vm.getColorAspects(), function (aspect) {
      return _c(
        "a",
        {
          key: aspect.name,
          staticClass: "main-button",
          attrs: {
            href: _vm.getAspectUrl(aspect),
            target: "_blank",
            type: "button",
            title: aspect.description,
            "data-merc-pos": "simulateur-peinture",
            "data-merch-name": _vm.getButtonLabel(aspect),
            "data-merch-arbo": "simulateur",
          },
        },
        [
          _c("span", { on: { click: _vm.onBuyClick } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.getButtonLabel(aspect)) +
                "\n        "
            ),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }