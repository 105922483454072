<template>
    <router-link
        :to="{ name: link }"
        class="main-card"
        :data-cerberus="dataCerberus"
    >
        <div class="main-card__cover">
            <picture>
                <source :srcset="coverWebp"
                        media="(max-width: 800px)">
                <img :src="coverImg" alt="" />
            </picture>

        </div>

        <div class="main-card__content">
            <h4> {{ _(title) }}</h4>
            <p>{{ _(description) }}</p>
        </div>
    </router-link>
</template>

<script>
import self from "../../../index";

const {
    modules,
} = self.app;

export default {
    name: "MainCard",
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        dataCerberus: {
            type: String,
            required: false,
            default: "main-card",
        },
        coverWebp: {
            type: String,
            required: false,
            default: null,
        },
        coverImg: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/Base/font.scss";

.main-card {
  width: 100%;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 15px rgba(black, 0.3);
  color: black;
  text-decoration: none;
  transition: 0.3s;

  &__cover {
    width: 100%;
    height: 150px;

    picture {
      height: 100%;
      display: block;

      source,
      img {
        transition: 0.3s;
        border-radius: 4px 4px 0 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  h4 {
    transition: 0.3s;
    color: $color-primary-01-500;
  }

  &:hover {
    box-shadow: 0 2px 5px rgba(black, 0.5);

    h4 {
      color: $color-primary-01-700;
    }
  }

  &__content {
    flex: 1;
    padding: 1rem;

    & > * {
      &:nth-child(1) {
        padding-bottom: 0.5rem;
        margin: 0;
        transition: 0.3s;

        @include set-font-scale('06');
      }

      &:nth-child(2) {
        padding: 0;

        @include set-font-scale('03');
      }
    }
  }
}

</style>
