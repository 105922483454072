var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-modal",
    {
      staticClass: "guidelines-modal",
      attrs: { open: _vm.open, "modal-title": "" },
      on: { "update:open": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _vm.slider
                ? _c("div", { staticClass: "guidelines-footer" }, [
                    _c("div", { staticClass: "footer-element" }, [
                      !_vm.isPrevDisabled
                        ? _c(
                            "button",
                            {
                              staticClass: "mc-button mc-button--s",
                              attrs: { id: "btn-previous", type: "button" },
                              on: { click: _vm.previous },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "mc-button__icon" },
                                [
                                  _c("MIconArrowArrowLeft24", {
                                    attrs: { color: "#78be20" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _c("div"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dots footer-element" },
                      _vm._l(_vm.dotHelper, function (idx) {
                        return _c("button", {
                          key: idx,
                          class: { dot: true, active: _vm.current === idx },
                          on: {
                            click: function ($event) {
                              return _vm.slider.moveToIdx(idx)
                            },
                          },
                        })
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "footer-element" }, [
                      !_vm.isNextDisabled
                        ? _c(
                            "button",
                            {
                              staticClass: "mc-button mc-button--s",
                              attrs: { id: "btn-next", type: "button" },
                              on: { click: _vm.next },
                            },
                            [
                              _c("span", { staticClass: "mc-button__label" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm._("Suivant")) +
                                    "\n                    "
                                ),
                              ]),
                            ]
                          )
                        : _c(
                            "button",
                            {
                              staticClass:
                                "mc-button mc-button--solid-neutral mc-button--s",
                              attrs: { id: "btn-close", type: "button" },
                              on: { click: _vm.closeModal },
                            },
                            [
                              _c("span", { staticClass: "mc-button__label" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm._("Fermer")) +
                                    "\n                    "
                                ),
                              ]),
                            ]
                          ),
                    ]),
                  ])
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      [
        _c(
          "button",
          { staticClass: "btn-modal__close", on: { click: _vm.closeModal } },
          [_c("MIconControlCross24")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "navigation-wrapper" }, [
          _c("div", { ref: "slider", staticClass: "keen-slider" }, [
            _c("div", { staticClass: "keen-slider__slide" }, [
              _c("div", { staticClass: "slide" }, [
                _c("p", { staticClass: "guidelines-modal__title" }, [
                  _vm._v(
                    "1. " + _vm._s(_vm._("Vérifier que votre image soit nette"))
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "slide-wrapper style-1" }, [
                  _c("div", { staticClass: "guideline-card" }, [
                    _c("img", {
                      staticClass: "guideline-card__icon",
                      attrs: {
                        src: "/assets/images/icons/good.svg",
                        alt: "icon bonne image",
                      },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "guideline-card__picture",
                      attrs: { src: "/assets/images/guideline/1.jpg", alt: "" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "guideline-card" }, [
                    _c("img", {
                      staticClass: "guideline-card__icon",
                      attrs: {
                        src: "/assets/images/icons/bad.svg",
                        alt: "icon mauvaise image",
                      },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "guideline-card__picture blurry",
                      attrs: { src: "/assets/images/guideline/1.jpg", alt: "" },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "keen-slider__slide" }, [
              _c("div", { staticClass: "slide" }, [
                _c("p", { staticClass: "guidelines-modal__title" }, [
                  _vm._v(
                    "2. " +
                      _vm._s(
                        _vm._(
                          "Assurez-vous d'être dans de bonnes conditions lumineuses"
                        )
                      )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "slide-wrapper style-1 style-2" }, [
                  _c("div", { staticClass: "guideline-card" }, [
                    _c("img", {
                      staticClass: "guideline-card__icon",
                      attrs: {
                        src: "/assets/images/icons/good.svg",
                        alt: "icon bonne image",
                      },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "guideline-card__picture",
                      attrs: { src: "/assets/images/guideline/1.jpg", alt: "" },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "guideline-card__title" }, [
                      _vm._v(_vm._s(_vm._("Normal"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "guideline-card-wrapper" }, [
                    _c("div", { staticClass: "guideline-card" }, [
                      _c("img", {
                        staticClass: "guideline-card__icon",
                        attrs: {
                          src: "/assets/images/icons/bad.svg",
                          alt: "icon mauvaise image",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "guideline-card__picture brightness",
                        attrs: {
                          src: "/assets/images/guideline/1.jpg",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "guideline-card__title" }, [
                        _vm._v(_vm._s(_vm._("Forte"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "guideline-card" }, [
                      _c("img", {
                        staticClass: "guideline-card__icon",
                        attrs: {
                          src: "/assets/images/icons/bad.svg",
                          alt: "icon mauvaise image",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "guideline-card__picture darkness",
                        attrs: {
                          src: "/assets/images/guideline/1.jpg",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "guideline-card__title" }, [
                        _vm._v(_vm._s(_vm._("Faible"))),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "keen-slider__slide" }, [
              _c("div", { staticClass: "slide" }, [
                _c("p", { staticClass: "guidelines-modal__title" }, [
                  _vm._v(
                    "3. " +
                      _vm._s(
                        _vm._(
                          "Évitez les personnes et les animaux sur votre photo"
                        )
                      )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "slide-wrapper style-1" }, [
                  _c("div", { staticClass: "guideline-card--only-icon" }, [
                    _c("img", {
                      attrs: {
                        src: "/assets/images/icons/no-user.svg",
                        alt: "icon bonne image",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "guideline-card--only-icon" }, [
                    _c("img", {
                      attrs: {
                        src: "/assets/images/icons/no-animal.svg",
                        alt: "icon mauvaise image",
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "keen-slider__slide" }, [
              _c("div", { staticClass: "slide" }, [
                _c("p", { staticClass: "guidelines-modal__title" }, [
                  _vm._v(
                    "4. " +
                      _vm._s(
                        _vm._(
                          "Vous obtiendrez un résultat optimal avec un mur clair et uni"
                        )
                      )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "slide-wrapper style-1" }, [
                  _c("div", { staticClass: "guideline-card" }, [
                    _c("img", {
                      staticClass: "guideline-card__icon",
                      attrs: {
                        src: "/assets/images/icons/good.svg",
                        alt: "icon bonne image",
                      },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "guideline-card__picture",
                      attrs: {
                        src: "/assets/images/guideline/mur-uni.jpg",
                        alt: "",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "guideline-card" }, [
                    _c("img", {
                      staticClass: "guideline-card__icon",
                      attrs: {
                        src: "/assets/images/icons/no-good.svg",
                        alt: "icon mauvaise image",
                      },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "guideline-card__picture",
                      attrs: {
                        src: "/assets/images/guideline/mur-charge.jpg",
                        alt: "",
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }