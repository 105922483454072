<template>
    <transition name="fade">
        <div
            v-if="messages.length"
            class="toaster"
        >
            <div
                v-for="(message, index) in messages"
                :key="index"
                class="toaster__item"
                :class="message.status"
            >
                <div class="toaster__icon">
                    <MIconDisplayExternalLink48 v-if="message.type === 'LINK' " />
                    <MIconContactMail48 v-if="message.type === 'MAIL' " />
                    <MIconControlCircleCross48 v-if="message.type === 'ERROR' " />
                    <MIconNotificationWarning48 v-if="message.type === 'INFO' " />
                </div>
                <div>
                    <h3
                        v-show="message.title"
                        class="toaster__title"
                    >
                        {{ message.title }}
                    </h3>
                    <p>
                        {{ message.text }}
                    </p>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import {
    MIconDisplayExternalLink48,
    MIconContactMail48,
    MIconControlCircleCross48,
    MIconNotificationWarning48,
} from "@mozaic-ds/icons/vue/index.vue";
import EventBus from "../../helpers/event-bus";
import self from "../../../index";

const {
    modules,
} = self.app;

const TOASTER_TIME = 6000; // in ms

export default {
    name: "Toaster",
    components: {
        MIconDisplayExternalLink48,
        MIconContactMail48,
        MIconControlCircleCross48,
        MIconNotificationWarning48,
    },
    data() {
        return {
            messages: [],
            recentMessages: [],
        };
    },
    created() {
        EventBus.$on("toaster-message", (messageObject) => {
            this.addMessage(messageObject);
        });
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        addMessage(messageObject) {
            if (this.recentMessages.includes(messageObject.status)) {
                return;
            }
            this.messages.push(messageObject);
            this.recentMessages.push(messageObject.status);
            setTimeout(() => {
                const index = this.messages.findIndex(
                    (mObject) => mObject === messageObject
                );
                this.messages.splice(index, 1);
                this.recentMessages = this.recentMessages.filter((message) => message !== messageObject.status);
            }, TOASTER_TIME);
        },
    },
};
</script>
<style lang="scss">
@import "../../../../../style/index.scss";

.toaster {
  position: fixed;
  right: 50%;
  transform: translateX(50%);
  top: 0;
  transition: 1s;
  z-index: 2000;
  width: 80%;
  min-width: 200px;

  @include set-from-screen(m) {
    width: 50%;
  }

  &__item {
    background: white;
    padding: $mu100;
    border-radius: 4px;
    margin: $mu100;
    opacity: 0;
    animation: 0.3s ease-out 0.2s apear;
    animation-fill-mode: forwards;
    display: flex;
    align-items: flex-start;
    box-shadow: 2px 2px 24px rgba(black, 0.5);

    p {
      margin: 0;

      @include set-font-scale('04');
      @include set-font-weight('semi-bold');
    }

    svg {
      width: 30px;
      height: 30px;
    }

    &.success {
      color: $color-primary-01-600;

      svg {
        fill: $color-primary-01-600;
      }
    }

    &.error {
      color: $color-danger-500;

      svg {
        fill: $color-danger-500;
      }
    }

    &.info {
      color: $color-warning-600;

      svg {
        fill: $color-warning-600;
      }
    }
  }

  &__title {
    margin: $mu025 0;

    @include set-font-scale('07');
  }

  &__icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: $mu100;
  }
}

@keyframes apear {
  100% {
    opacity: 1;
  }
}
</style>
