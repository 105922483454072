<template>
    <div
        v-if="!!lastRoute"
        class="button-back"
    >
        <div
            class="simple-link"
            data-cerberus="back-button"
            @click="goBack"
        >
            <MIconArrowArrowLeft24 color="#78be20" />
            <span>{{ backButtonLabel }}</span>
        </div>
    </div>
</template>

<script>
import { MIconArrowArrowLeft24 } from "@mozaic-ds/icons/vue/index.vue";
import self from "../../../index";

const { modules } = self.app;

export default {
    name: "ButtonBack",
    components: {
        MIconArrowArrowLeft24,
    },
    computed: {
        lastRoute() {
            return this.$store.getters["history/lastRoute"];
        },
        backButtonLabel() {
            if (this.lastRoute) {
                const meta = this.lastRoute.meta(this.lastRoute);

                return meta.btnBackLabel;
            }

            return this._("Retour");
        },
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        goBack() {
            if (this.lastRoute) {
                this.$router.push({ path: this.lastRoute.path });
                this.$store.commit("history/GO_TO_LAST_ROUTE");
            }
        },
    },
};

</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.button-back {
  height: $nav-height;
  padding: $mu100;
  border-bottom: solid 1px $color-grey-200;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;

  .simple-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 0;

    & > * {
      &:nth-child(2) {
        margin-left: 0.5rem;
      }
    }

    svg {
      width: 18px;
      height: 18px;
    }

    span {
      color: $color-grey-800;

      @include set-font-weight('regular');
      @include set-font-scale('03');
    }
  }
}
</style>
