<template>
    <div class="color-modal">
        <div class="color-modal-wrapper">
            <div class="section-top">
                <div
                    v-for="uid in selectedColors"
                    :key="uid"
                    class="section-top__item"
                >
                    <color-card
                        :uid="uid"
                        :color="colors[uid]"
                        show-color-link
                    />
                </div>
            </div>
            <div class="section-bottom">
                <router-link
                    class="section-bottom__item"
                    :to="{ name: 'color_menu' }"
                >
                    <MIconInspiration32 />

                    <p>{{ _("Tester ces couleurs sur une image") }}</p>
                </router-link>
                <router-link
                    class="section-bottom__item"
                    :to="{ name: 'shopping_cart' }"
                    @click.native="onBuyColorsClick"
                >
                    <MIconBasketStandard32 />

                    <p>{{ _("Acheter ces couleurs") }}</p>
                </router-link>
                <div class="section-bottom__item no-cursor">
                    <ButtonShare
                        in-palette
                        mode="SHARE_PALETTE"
                        :label="_('Partager mes couleurs')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    MIconInspiration32,
    MIconBasketStandard32,
} from "@mozaic-ds/icons/vue/index.vue";
import self from "../../../index";
import ColorCard from "../color-card/color-card.vue";
import ButtonShare from "../button-share/button-share.vue";
import EventBus from "../../helpers/event-bus";
import { sendEvent } from "../../services/marketing";

const {
    modules,
} = self.app;

export default {
    name: "ColorModal",
    components: {
        ColorCard,
        ButtonShare,
        MIconInspiration32,
        MIconBasketStandard32,
    },
    data() {
        return {
            notification: null,
            isOpenSahreButton: false,
        };
    },
    computed: {
        colors() {
            return this.$store.getters["colors/colors"];
        },
        isShareButtonOpenedInModal() {
            return this.$store.getters["cartData/isShareButtonOpenedInModal"];
        },
        selectedColors() {
            return this.$store.getters["cartData/colors"];
        },
    },
    mounted() {
        EventBus.$on("share-link-palette", (callback) => {
            const colors = this.selectedColors.join("_");
            const fullPath = `/palette/${colors}`;
            const url = `${window.location.protocol}//${window.location.host}${fullPath}`;
            callback(url, fullPath);
        });
    },
    beforeDestroy() {
        EventBus.$off("share-link-palette");
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        onBuyColorsClick(event) {
            sendEvent("button.click", {
                button_name: "buy my colors",
                feature_name: "color palette",
            }, event ? event.target : null);
        },
        handleNotificationClose() {
            this.notification = null;
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.color-modal {
  transition: 0.5s;
  border-radius: 4px;
  height: 0;
  overflow: hidden;
  position: absolute;
  bottom: calc(100% + #{$mu100});
  right: 0;
  opacity: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  @include set-from-screen(m) {
    width: $palette-width;
  }

  &.open {
    overflow: initial;
    z-index: 20;
    opacity: 1;
    height: auto;
  }

  &-wrapper {
    transition: 0.3s;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(black, 0.2);
    background: white;
    overflow: auto;
  }

  .section-top {
    border-bottom: solid 1px $color-grey-200;
    padding: $mu100;
    display: grid;
    grid-gap: $mu050;
    grid-template-columns: repeat(auto-fit, minmax(32%, 2fr));
  }

  .section-bottom {
    margin: $mu100;

    .divider {
      display: block;
      width: 100%;
      height: 1px;
      background: $color-grey-200;
      margin: $mu100 0;
    }

    &__item {
      transition: 0.3s;
      display: flex;
      align-items: center;
      color: black;
      text-decoration: none;
      cursor: pointer;

      .btn-share-label {
        transition: 0.3s;

        &.hide {
          opacity: 0;
          transform: scale(0);
        }
      }

      .share-container {
        position: relative;

        &.open {
          width: 160px;
        }

        .btn-share {
          box-shadow: none;
          left: 0;
        }

        .share-actions {
          display: flex;
          flex-direction: row-reverse;
        }
      }

      &.no-cursor {
        cursor: initial;
      }

      & > svg {
        transition: 0.3s;
        fill: $color-grey-600;
        width: 26px;
        height: 26px;
        margin-right: $mu075;
      }

      &:hover {
        &,
        p {
          color: $color-primary-01-600;
        }

        svg {
          fill: $color-primary-01-600;
        }

        .share-actions {
          svg {
            fill: $color-grey-600;
          }

          button:last-child {
            svg {
              fill: white;
            }
          }
        }
      }
    }
  }
}
</style>
