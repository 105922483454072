<template>
    <m-modal
        :open="open"
        modal-title=""
        class="guidelines-modal"
        @update:open="closeModal"
    >
        <template>
            <button
                class="btn-modal__close"
                @click="closeModal"
            >
                <MIconControlCross24 />
            </button>

            <div class="navigation-wrapper">
                <div
                    ref="slider"
                    class="keen-slider"
                >
                    <div class="keen-slider__slide">
                        <div class="slide">
                            <p class="guidelines-modal__title">1. {{ _('Vérifier que votre image soit nette') }}</p>
                            <div class="slide-wrapper style-1">
                                <div class="guideline-card">
                                    <img class="guideline-card__icon" src="/assets/images/icons/good.svg" alt="icon bonne image">
                                    <img class="guideline-card__picture" src="/assets/images/guideline/1.jpg" alt="">
                                </div>
                                <div class="guideline-card">
                                    <img class="guideline-card__icon" src="/assets/images/icons/bad.svg" alt="icon mauvaise image">
                                    <img class="guideline-card__picture blurry" src="/assets/images/guideline/1.jpg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="keen-slider__slide">
                        <div class="slide">
                            <p class="guidelines-modal__title">2. {{ _("Assurez-vous d'être dans de bonnes conditions lumineuses") }}</p>
                            <div class="slide-wrapper style-1 style-2">
                                <div class="guideline-card">
                                    <img class="guideline-card__icon" src="/assets/images/icons/good.svg" alt="icon bonne image">
                                    <img class="guideline-card__picture" src="/assets/images/guideline/1.jpg" alt="">
                                    <p class="guideline-card__title">{{ _("Normal") }}</p>
                                </div>
                                <div class="guideline-card-wrapper">
                                    <div class="guideline-card">
                                        <img class="guideline-card__icon" src="/assets/images/icons/bad.svg" alt="icon mauvaise image">
                                        <img class="guideline-card__picture brightness" src="/assets/images/guideline/1.jpg" alt="">
                                        <p class="guideline-card__title">{{ _("Forte") }}</p>
                                    </div>
                                    <div class="guideline-card">
                                        <img class="guideline-card__icon" src="/assets/images/icons/bad.svg" alt="icon mauvaise image">
                                        <img class="guideline-card__picture darkness" src="/assets/images/guideline/1.jpg" alt="">
                                        <p class="guideline-card__title">{{ _("Faible") }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="keen-slider__slide">
                        <div class="slide">
                            <p class="guidelines-modal__title">3. {{ _('Évitez les personnes et les animaux sur votre photo') }}</p>

                            <div class="slide-wrapper style-1">
                                <div class="guideline-card--only-icon">
                                    <img src="/assets/images/icons/no-user.svg" alt="icon bonne image">

                                </div>
                                <div class="guideline-card--only-icon">
                                    <img src="/assets/images/icons/no-animal.svg" alt="icon mauvaise image">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="keen-slider__slide">
                        <div class="slide">
                            <p class="guidelines-modal__title">4. {{ _('Vous obtiendrez un résultat optimal avec un mur clair et uni') }}</p>
                            <div class="slide-wrapper style-1">
                                <div class="guideline-card">
                                    <img class="guideline-card__icon" src="/assets/images/icons/good.svg" alt="icon bonne image">
                                    <img class="guideline-card__picture" src="/assets/images/guideline/mur-uni.jpg" alt="">
                                </div>
                                <div class="guideline-card">
                                    <img class="guideline-card__icon" src="/assets/images/icons/no-good.svg" alt="icon mauvaise image">
                                    <img class="guideline-card__picture" src="/assets/images/guideline/mur-charge.jpg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <div
                v-if="slider"
                class="guidelines-footer"
            >
                <div class="footer-element">
                    <button
                        v-if="!isPrevDisabled"
                        id="btn-previous"
                        type="button"
                        class="mc-button mc-button--s"
                        @click="previous"
                    >
                        <span class="mc-button__icon">
                            <MIconArrowArrowLeft24 color="#78be20" />
                        </span>
                    </button>
                    <div v-else />
                </div>

                <div
                    class="dots footer-element"
                >
                    <button
                        v-for="idx in dotHelper"
                        :key="idx"
                        :class="{ dot: true, active: current === idx }"
                        @click="slider.moveToIdx(idx)"
                    />
                </div>

                <div class="footer-element">
                    <button
                        v-if="!isNextDisabled"
                        id="btn-next"
                        type="button"
                        class="mc-button mc-button--s"
                        @click="next"
                    >
                        <span class="mc-button__label">
                            {{ _("Suivant") }}
                        </span>
                    </button>
                    <button
                        v-else
                        id="btn-close"
                        type="button"
                        class="mc-button mc-button--solid-neutral mc-button--s"
                        @click="closeModal"
                    >
                        <span class="mc-button__label">
                            {{ _("Fermer") }}
                        </span>
                    </button>
                </div>
            </div>
        </template>
    </m-modal>
</template>

<script>
import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";
import { MIconControlCross24, MIconArrowArrowLeft24 } from "@mozaic-ds/icons/vue/index.vue";
import EventBus from "../../helpers/event-bus";
import self from "../../../index";

const {
    modules,
} = self.app;

export default {
    name: "ModalGuidelines",
    components: {
        MIconControlCross24,
        MIconArrowArrowLeft24,
    },
    data() {
        return {
            open: false,
            current: 0,
            slider: null,
        };
    },
    computed: {
        dotHelper() {
            return this.slider ? [...Array(this.slider.details().size).keys()] : [];
        },
        isNextDisabled() {
            return this.current === this.slider.details().size - 1;
        },
        isPrevDisabled() {
            return this.current === 0;
        },
    },
    mounted() {
        this.initSlider();
        EventBus.$on("open-modal-guidelines", this.openModal);
    },
    beforeDestroy() {
        this.destroySlider();
        EventBus.$off("open-modal-guidelines", this.openModal);
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        previous() {
            this.slider.prev();
        },
        next() {
            this.slider.next();
        },
        initSlider() {
            try {
                this.slider = new KeenSlider(this.$refs.slider, {
                    initial: this.current,
                    slideChanged: (s) => {
                        this.current = s.details().relativeSlide;
                    },
                });
            } catch (e) {
                console.error(e);
            }
        },
        destroySlider() {
            if (this.slider) this.slider.destroy();

        },
        openModal() {
            this.initSlider();
            this.open = true;
        },
        closeModal() {
            this.open = false;
            this.destroySlider();
            this.current = 0;
        },
    },
};

</script>

<style lang="scss">
@import "../../../../../style/index.scss";

div.mc-modal {
  &__dialog {
    width: 60vw !important;
    height: 75vh !important;
    max-width: none !important;
    max-height: none !important;
  }
}

.guidelines-modal {
  .btn-modal__close {
    width: 40px;
    height: 40px;
    background: white;
    position: absolute;
    right: 0;
    top: -50px;
    border: none;
    display:none;

    @include set-border-radius('m');

    &:hover {
      cursor: pointer;
    }
  }

  .mc-modal {
    align-items: center;
  }

  .mc-modal__dialog {
    max-height: 700px;
    height: 90%;

    @include set-from-screen(m) {
      max-height: 600px;
      height: 600px;
    }

    @include set-from-screen(xl) {
      max-width: 1000px;
    }

    @include set-from-screen(xxl) {
      max-width: 1200px;
      max-height: 700px;
      height: 700px;
    }
  }

  &__title {
    @include set-font-weight('semi-bold');
  }

  .mc-modal__header {
    display: none;
  }

  .mc-modal__body {
    padding-left: 0;
    padding-right: 0;
  }

  .mc-modal__footer {
    box-shadow: none !important;
  }

  .mc-modal__content {
    &:hover {
      cursor: grab;
    }
  }

  .mc-modal__dialog {
    padding: $mu100;
  }

  .slide-wrapper {
    height: calc(100% - 40px);
    display: flex;
    column-gap: $mu200;
    align-items: center;

    &.style-1 {
      flex-direction: column;
      row-gap: $mu100;
      justify-content: center;

      @include set-from-screen(m) {
        flex-direction: row;
      }

      .guideline-card {
        height: 130px;
        width: 100%;
        max-width: 230px;

        @include set-from-screen(s-xlarge) {
          height: 160px;
          width: 260px;
        }

        @include set-from-screen(l) {
          max-width: 300px;
          height: 200px;
          max-height: 200px;
          width: 50%;
        }

        @include set-from-screen(xl) {
          max-width: 600px;
          height: 300px;
          max-height: 300px;
          width: 50%;
        }

        &--only-icon {
          background: $color-grey-200;
          max-width: 100px;
          height: 100%;
          width: 100%;
          max-height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;

          @include set-from-screen(m) {
            max-width: 150px;
            max-height: 150px;
          }

          img {
            width: 50%;
            height: 50%;
          }
        }
      }
    }

    &.style-2 {
      padding: 0 $mu100 $mu100;

      @include set-from-screen(m) {
        flex-direction: column;
      }

      @include set-from-screen(l) {
        flex-direction: row;
      }

      & > * {
        @include set-from-screen(s-xlarge) {
          width: 300px;
          max-width: 300px !important;
        }

        @include set-from-screen(l) {
          height: 200px;
        }

        @include set-from-screen(l) {
          max-width: 50% !important;
          width: 50%;
        }

        &:nth-child(1) {
          width: 100%;
          max-width: 100%;
        }

        &:nth-child(2) {
          @include set-from-screen(l) {
            max-width: 50% !important;
            width: 50%;
          }

          @include set-from-screen(xl) {
            height: 300px;
            max-height: 300px;
          }

          .guideline-card {
            width: 50%;
            max-width: 50%;
            height: 180px;
            max-height: 180px;

            @include set-from-screen(l) {
              height: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }

    .guideline-card {
      box-shadow: 2px 2px 10px rgba(black, 0.2);
      border-radius: 4px;
      overflow: hidden;
      position: relative;

      &__icon {
        position: absolute;
        top: $mu100;
        left: $mu100;
        z-index: 2;
      }

      &__picture {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.brightness {
          filter: brightness(150%);
        }

        &.darkness {
          filter: brightness(30%);
        }

        &.blurry {
          filter: blur(2px);
        }
      }

      &__title {
        background: white;
        border-radius: 0 0 4px 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
      }

      &-wrapper {
        display: flex;
        column-gap: $mu200;

        .guideline-card {
          max-width: 25%;
        }
      }
    }
  }
}

.slide {
  display: flex;
  flex-direction: column;
  justify-self: space-between;
  align-content: center;
  height: 100%;
  width: 100%;

  p {
    text-align: center;
  }

  .slide-wrapper{
    height: 90%;
    display: flex;
    width: 100%;
    column-gap: 15px;

    @media screen and (max-width: 1024px ){
      flex-direction: column;
      row-gap: 15px;
      overflow-y: scroll;
    }

    .guideline-card{
      position: relative;
      height: 100%;
      width: 50%;

      &__title{
        display: none;
      }

      @media screen and (max-width: 1024px ){
        width: 100%;
      }

        .blurry{
          filter: blur(2px);
        }

        .brightness{
          filter: brightness(150%);
        }

        .darkness{
          filter: brightness(30%);
        }

        & > .guideline-card__icon {
          position: absolute;
          top: 10px;
          z-index: 2;
        }

        .guideline-card__picture {
          width: 100%;
          height: 100%;
          object-fit: fill;
          object-position: center
        }
    }

    .guideline-card-wrapper{
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      width: 50%;
      position: relative;
      @media screen and (max-width: 1024px ){
        width: 100%;
      }

      .guideline-card{
        width: 100%;
        height: 50%;

      }
    }

    .guideline-card--only-icon{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;

      @media screen and (max-width: 1024px ){
        width: 100%;
      }

      img{
        width: 50%;
        height: 50%;
      }
    }
  }
}

.navigation-wrapper {
  position: relative;
}

.guidelines-footer {
  width: 100%;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;

  .footer-element {
    flex: 1;
  }

  .dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;

    .dot {
      border: none;
      width: 10px;
      height: 10px;
      background: $color-grey-200;
      border-radius: 50%;
      margin: 0 5px;
      padding: 5px;

      &:focus {
        outline: none;
      }

      &.active {
        background: $color-primary-01-500;
      }
    }
  }

  #btn-previous {
    transition: 0.3s;
    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
    background: $color-grey-100;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      background: $color-grey-200;
    }

    svg {
      width: 22px;
      height: 22px;
    }
  }

  #btn-next,
  #btn-close {
    border-radius: 20px;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    display: flex;
    margin-left: auto;
    margin-right: 0;

    &:focus {
      box-shadow: none;
    }
  }
}
</style>
