/**
 * colors store module is storing data provided by the back-end :
 * - @property {Object} colors is an object with colors uid as key and color as value.
 * - @property {Object} colorsFiltered is a subset of colors by a search term.
 * - @property {Array} palettes is an array of objects with name, preview_color fields and colors array.
 * - @property {Object} aspects is an object with aspect as key and object as value with a name and a description.
 */

export default {
    namespaced: true,
    state: {
        colors: {},
        colorsFiltered: {},
        palettes: [],
        aspects: null,
        isLoading: false,
        error: null,
    },
    getters: {
        colors(state) {
            return state.colors;
        },
        colorsFiltered(state) {
            return state.colorsFiltered;
        },
        palettes(state) {
            return state.palettes;
        },
        aspects(state) {
            return state.aspects;
        },
        getColorAspects: (state) => (uid) => {
            const color = state.colors[uid];
            const aspectOrder = ["matt", "velor", "satin"];

            return Object.entries(color.aspects)
                .sort((a, b) => aspectOrder.indexOf(a[0]) - aspectOrder.indexOf(b[0]))
                .map(([key, aspect]) => ({
                    ...aspect,
                    ...state.aspects[key],
                }));
        },
        isLoading(state) {
            return state.isLoading;
        },
        error(state) {
            return state.error;
        },
    },
    mutations: {
        "FETCHING_COLORS"(state) {
            state.colors = {};
            state.colorsFiltered = {};
            state.palettes = [];
            state.aspects = null;
            state.isLoading = true;
            state.error = null;
        },
        "FETCHING_COLORS_SUCCESS"(state, data) {
            state.colors = data.colors;
            state.colorsFiltered = data.colors;
            state.palettes = data.palettes;
            state.aspects = data.aspects;
            state.isLoading = false;
            state.error = null;
        },
        "FETCHING_COLORS_ERROR"(state, err) {
            state.colors = {};
            state.colorsFiltered = {};
            state.palettes = [];
            state.aspects = null;
            state.isLoading = false;
            state.error = err;
        },
        "FILTER_COLORS"(state, searchTerm) {
            const colorsFiltered = {};
            Object.entries(state.colors).forEach(([uid, color]) => {
                const colorName = color.name.trim().toLowerCase();
                const colorRefs = Object.values(color.aspects).map((aspect) => aspect.ref);
                const search = searchTerm.trim().toLowerCase();
                if (
                    colorName.startsWith(search)
                    || colorRefs.find((ref) => ref.includes(search))
                ) {
                    colorsFiltered[uid] = color;
                }
            });
            state.colorsFiltered = colorsFiltered;
        },
        "RESET_COLORS"(state) {
            state.colorsFiltered = state.colors;
        },
    },
    actions: {
        async fetchColors({ dispatch, commit }, locale) {
            commit("FETCHING_COLORS");
            try {
                // Fetch /data/palette with locale query parameter
                const res = await fetch(`/data/palette.${locale}.json`);
                const data = await res.json();
                commit("FETCHING_COLORS_SUCCESS", data);
                dispatch("cartData/load", null, { root: true });
            } catch (err) {
                commit("FETCHING_COLORS_ERROR", err);
            }
        },
    },
};
