/**
 * Load an image form the given URL.
 *
 * @param {String} imageUrl - The URL of the image to load.
 * @return {Promise<Image>} The image
 */
export function loadImage(imageUrl) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = reject;
        image.src = imageUrl;
    });
}

/**
 * Convert an image to a canvas.
 *
 * @param {Image} image - The image to convert.
 * @return {HTMLCanvasElement} The image as a canvas element.
 */
export function imageToCanvas(image, width = 0, height = 0) {
    const imageCanvas = document.createElement("canvas");
    imageCanvas.width = width || image.width;
    imageCanvas.height = height || image.height;
    imageCanvas.getContext("2d").drawImage(image, 0, 0, imageCanvas.width, imageCanvas.height);
    return imageCanvas;
}

/**
 * Duplicate the content from a source canvas to a destination canvas.
 *
 * @param {HTMLCanvasElement} souceCanvas - The canvas to copy.
 * @para {HTMLCanvasElement} destCanvas - The destination canvas.
 */
export function copyCanvas(sourceCanvas, destCanvas) {
    destCanvas.width = sourceCanvas.width;
    destCanvas.height = sourceCanvas.height;
    const sourceData = sourceCanvas.getContext("2d").getImageData(0, 0, sourceCanvas.width, sourceCanvas.height);
    destCanvas.getContext("2d").putImageData(sourceData, 0, 0);
}

/**
 * Create and return a canvas with list of painting displayed
 * @param  {Canvas} sourceCanvas initial canvas
 * @param  {Object} paintList    list of paint you want to display as text and circle, format is at least {name, rgbhex}
 * @return {Canvas} Canvas drawn with list of paint on it
 */
export function createCanvasWithPaintList(paintList, sourceCanvas) {
    const heightPaintingList = 120;
    const padding = 10;
    const rectSize = 50;
    const lineHeight = 60;
    const columnSize = 280;
    const imageCanvas = document.createElement("canvas");
    const imageCtx = imageCanvas.getContext("2d");
    let startY = 0;
    if (sourceCanvas) {
        imageCanvas.width = sourceCanvas.width;
        imageCanvas.height = sourceCanvas.height + heightPaintingList;
        startY = sourceCanvas.height;
        imageCtx.drawImage(sourceCanvas, 0, 0);
    } else {
        imageCanvas.width = columnSize * 4;
        imageCanvas.height = heightPaintingList;
    }
    imageCtx.fillStyle = "white";
    imageCtx.fillRect(0, startY, imageCanvas.width, heightPaintingList);
    imageCtx.font = "24px Arial";
    imageCtx.textBaseline = "middle";
    // algo is built to work on 2 lines of 3 colors
    for (let i = 0; i < paintList.length; i++) {
        const column = i % 3;
        const lineNumber = Math.floor(i / 3);
        const yStart = startY + padding + (rectSize / 2) + (lineHeight * lineNumber);
        const xDecal = (columnSize * column);
        imageCtx.beginPath();
        imageCtx.fillStyle = paintList[i].rgbhex;
        imageCtx.strokeStyle = "lightGrey";
        imageCtx.fillRect(xDecal, yStart - rectSize / 2, rectSize, rectSize);
        imageCtx.strokeRect(xDecal, yStart - rectSize / 2, rectSize, rectSize);
        imageCtx.fillStyle = "black";
        imageCtx.fillText(paintList[i].name, xDecal + padding + rectSize, yStart);
        imageCtx.closePath();
    }
    return imageCanvas;
}
