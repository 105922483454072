<template>
    <div
        ref="canvasContainer"
        class="canvas-container"
    >
        <div
            :class="['canvas-loader',!isCanvasReady && 'is-loading']"
        >
            <span>{{ _("Chargement en cours ...") }}</span>
        </div>
        <canvas
            ref="situationAutoCanvas"
            @mousedown.stop.prevent="colorize"
            @mousemove.stop.prevent="colorize"
            @mouseup.stop.prevent="colorize"
            @touchstart.stop.prevent="colorize"
            @touchmove.stop.prevent="colorize"
            @touchend.stop.prevent="colorize"
        />
        <div v-if="isDebug">
            <span>DEBUG</span>
            <span>
                <button @click="showCanvasColorSelected">show canvas</button>
                <button @click="showAllCanvasMasks">show all canvas</button>
            </span>
        </div>
    </div>
</template>

<script>
import pica from "pica";
import * as Sentry from "@sentry/vue";
import EventBus from "../../helpers/event-bus";
import {
    getCoordsFromCanvas,
    showCanvas,
    convertWBMaskToTransparent,
    getHighlightSettings,
} from "../../helpers/imageHelper";
import self from "../../../index";
import { uuidv4 } from "../../helpers/uuid";
import { dataURLtoFile } from "../../helpers/fileHelper";
import { MOBILE_WIDTH } from "../../helpers/constants";

const Amelib = require("../../../../painting-lib/src/amelib").default;

const {
    stonejs,
    paintinglib,
} = self.app.modules;

export default {
    name: "PaintCanvas",
    props: {
        tool: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isDebug: false,
            isMainCanvasLoad: false,
            isCanvasReady: false,
            isDrawing: false,
            masksImage: [],
            masksHistory: [],
            originalPictureData: [],
            amelibInstances: {},
            photoCanvas: document.createElement("canvas"),
            workCanvas: document.createElement("canvas"),
            coloredCanvasList: {},
            maskColorCanvasList: {},
            paintAlpha: 0.77,
            historyLimit: 40,
            isHighLight: false,
            highlightDisplays: getHighlightSettings(),
            lastDate: 0,
        };
    },
    computed: {
        imageCustomer() {
            return this.$store.getters["image/image"];
        },
        imageGallery() {
            return this.$store.getters["imagesGallery/imageSelected"];
        },
        mode() {
            if (this.imageCustomer) {
                return "customer";
            }
            if (this.imageGallery) {
                return "gallery";
            }
            return "";
        },
        colors() {
            return this.$store.getters["colors/colors"];
        },
        cartColors() {
            return this.$store.getters["cartData/colors"];
        },
        selectedColor() {
            return this.$store.getters["cartData/color"];
        },
        paintCanvas() {
            return this.selectedColor ? this.coloredCanvasList[this.selectedColor] : this.coloredCanvasList[0];
        },
        maskCanvas() {
            return this.selectedColor ? this.maskColorCanvasList[this.selectedColor] : this.maskColorCanvasList[0];
        },
        amelib() {
            return this.selectedColor ? this.amelibInstances[this.selectedColor] : this.amelibInstances[0];
        },
        isMobile() {
            return window.screen.availWidth <= MOBILE_WIDTH;
        },
    },
    watch: {
        async cartColors(newCart, oldCart) {
            const newColor = newCart.find((color) => !oldCart.includes(color));
            if (newColor !== undefined) {
                // Create canvas for the new color
                const mainCanvas = this.$refs.situationAutoCanvas;
                const mainCanvasImage = mainCanvas.getContext("2d").getImageData(0, 0, mainCanvas.width, mainCanvas.height);
                await this.createColorMask(mainCanvas, mainCanvasImage, newColor);
                this.createAmelibInstanceColor(newColor);
            } else {
                // Remove unused canvas
                const oldColor = oldCart.find((color) => !newCart.includes(color));
                delete this.maskColorCanvasList[oldColor];
                delete this.coloredCanvasList[oldColor];
                delete this.amelibInstances[oldColor];
                this.draw();
            }
        },
        isMainCanvasLoad(loaded) {
            if (loaded) {
                this.isCanvasReady = false;
                // Allow to set the variable above before loading (the variable is set after otherwise)
                setTimeout(() => {
                    if (this.selectedColor) {
                        const mainCanvas = this.$refs.situationAutoCanvas;
                        const mainCanvasImage = mainCanvas.getContext("2d", {
                            willReadFrequently: true,
                        }).getImageData(0, 0, mainCanvas.width, mainCanvas.height);
                        this.createColorMask(mainCanvas, mainCanvasImage, this.selectedColor).then(() => {
                            this.isCanvasReady = true;
                        });
                        this.createAmelibInstanceColor(this.selectedColor);
                    } else {
                        this.isCanvasReady = true;
                    }
                    this.initHistory();
                });
            }
        },
        selectedColor(color) {
            if (color) {
                const mainColorMask = this.maskColorCanvasList[color];
                delete this.maskColorCanvasList[color];
                this.maskColorCanvasList[color] = mainColorMask;
            }
        },
    },
    mounted() {
        this.lastDate = Date.now();
        EventBus.$on("share-link", (callback) => {
            const imageDataUrl = this.$refs.situationAutoCanvas.toDataURL();
            const uuid = uuidv4();
            const fileName = `${uuid}.png`;
            const image = dataURLtoFile(imageDataUrl, fileName);

            const form = new FormData();
            form.append("file", image, fileName);

            fetch("/share/upload", {
                method: "POST",
                body: form,
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Erreur pendant l'upload du fichier sur le serveur");
                    } else {
                        return res.json();
                    }
                })
                .then((body) => {
                    if (body.path === null) {
                        throw new Error("Erreur pendant la sauvegarde du fichier sur le serveur");
                    } else {
                        const colorListUsed = this.getColorListUsed();
                        callback(`${window.location.protocol}//${window.location.host}${body.path}/${colorListUsed.join("_")}`, uuid);
                    }
                })
                .catch((error) => {
                    const messageError = {
                        title: this._("Erreur"),
                        text: this._("Impossible d'upload l'image pour le partage"),
                        status: "error",
                        type: "ERROR",
                    };
                    console.error(error.message);
                    EventBus.$emit("toaster-message", messageError);
                    throw new Error("Error while uploading file");
                });
        });
        EventBus.$on("share-link-mail", (callback) => {
            const imageDataUrl = this.getAugmentedCanvas().toDataURL();
            const uuid = uuidv4();
            const fileName = `${uuid}.png`;
            const image = dataURLtoFile(imageDataUrl, fileName);

            const form = new FormData();
            form.append("file", image, fileName);

            fetch("/share/upload", {
                method: "POST",
                body: form,
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Erreur pendant l'upload du fichier sur le serveur");
                    } else {
                        return res.json();
                    }
                })
                .then((body) => {
                    if (body.path === null) {
                        throw new Error("Erreur pendant la sauvegarde du fichier sur le serveur");
                    } else {
                        const colorListUsed = this.getColorListUsed();
                        callback(`${window.location.protocol}//${window.location.host}${body.path}/${colorListUsed.join("_")}`, uuid);
                    }
                })
                .catch((error) => {
                    const messageError = {
                        title: this._("Erreur"),
                        text: this._("Impossible d'upload l'image pour le partage"),
                        status: "error",
                        type: "ERROR",
                    };
                    console.error(error.message);
                    EventBus.$emit("toaster-message", messageError);
                    throw new Error("Error while uploading file");
                });
        });
        EventBus.$on("cancel-action-canvas", () => {
            this.goBack();
        });
        EventBus.$on("download-action", () => {
            this.download();
        });
        EventBus.$on("highlight-area-canvas", () => {
            this.toggleMaskHighlight();
        });
        this.initMainCanvas().then(async () => {
            this.isMainCanvasLoad = true;
            this._pictureLuminosityAverage = null;
            if (this.mode === "gallery") {
                this.masksImage = await this.initFillingMasks();
                this.mergedMaskList();
                this._pictureLuminosityAverage = paintinglib.computeAverageLuminosityImageFromMask(
                    this.photoCanvas, this.mergedMasksImage
                );
            }

            this.initPaintAndMaskColorsCanvas();
            this.initAmelib();
            if (this.mode === "gallery") {
                this.toggleMaskHighlight();
                // >> This is a workaround to prevent white canvas when we click on the canvas the first time (double draw)
                this.toggleMaskHighlight();
                this.toggleMaskHighlight();
                // <<
            }
        });
    },
    beforeDestroy() {
        EventBus.$off("share-link");
        EventBus.$off("share-link-mail");
        EventBus.$off("download-action");
        this.clearCanvas(this.photoCanvas);
        this.clearCanvas(this.workCanvas);
        this.clearCanvas(this.$refs.situationAutoCanvas);
        this.clearObjectArray(this.coloredCanvasList);
        this.clearObjectArray(this.maskColorCanvasList);
        Object.keys(this.amelibInstances).forEach((key) => {
            delete this.amelibInstances[key];
        });
        this.clearObjectArray(this.amelibInstances);
        this.masksHistory.forEach((history) => {
            this.clearObjectArray(history);
        });
        this.masksImage.length = 0;
        this.originalPictureData.length = 0;

        this.amelibInstances = null;
        this.photoCanvas = null;
        this.workCanvas = null;
        this.$refs.situationAutoCanvas = null;
        this.coloredCanvasList = null;
        this.maskColorCanvasList = null;
        this.masksHistory = null;
    },
    methods: {
        _(...args) {
            return stonejs.gettext(...args);
        },
        initMainCanvas() {
            return new Promise((resolve) => {
                const image = new Image();
                image.src = this.mode === "gallery" ? this.imageGallery.image : this.imageCustomer;
                image.onload = () => {
                    // Create a canvas width raw image
                    const imageCanvas = document.createElement("canvas");
                    imageCanvas.width = image.width;
                    imageCanvas.height = image.height;
                    const imageCanvasCtx = imageCanvas.getContext("2d");
                    imageCanvasCtx.drawImage(image, 0, 0, imageCanvas.width, imageCanvas.height);

                    const canvasHandler = this.$refs.canvasContainer;
                    const mainCanvas = this.$refs.situationAutoCanvas;
                    const maxHeight = canvasHandler.clientHeight;
                    let canvasHeight = image.height;
                    let canvasWidth = image.width;

                    if (canvasHeight > maxHeight) {
                        const aspectRatio = (canvasWidth / canvasHeight);
                        canvasWidth = maxHeight * aspectRatio;
                        canvasHeight = maxHeight;
                    }
                    if (canvasWidth > canvasHandler.clientWidth) {
                        const aspectRatio = (canvasHeight / canvasWidth);
                        canvasWidth = canvasHandler.clientWidth;
                        canvasHeight = canvasHandler.clientWidth * aspectRatio;
                        canvasHandler.height = canvasHeight;
                    }

                    if (this.isMobile) {
                        mainCanvas.width = canvasWidth * 2;
                        mainCanvas.height = canvasHeight * 2;
                        mainCanvas.style.width = `${canvasWidth}px`;
                        mainCanvas.style.height = `${canvasHeight}px`;
                    } else {
                        mainCanvas.width = canvasWidth;
                        mainCanvas.height = canvasHeight;
                    }

                    this.photoCanvas.width = mainCanvas.width;
                    this.photoCanvas.height = mainCanvas.height;

                    pica().resize(imageCanvas, mainCanvas, {
                        alpha: true,
                        unsharpAmount: 150,
                        transferable: true,
                    })
                        .then(() => {
                            const mainCvansCtx = mainCanvas.getContext("2d");
                            this.originalPictureData = mainCvansCtx.getImageData(0, 0, mainCanvas.width, mainCanvas.height);

                            const photoCanvas = this.photoCanvas.getContext("2d");
                            photoCanvas.putImageData(this.originalPictureData, 0, 0);

                            this.workCanvas.width = mainCanvas.width;
                            this.workCanvas.height = mainCanvas.height;

                            resolve();
                        });

                };
            });
        },
        initPaintAndMaskColorsCanvas() {
            return new Promise((resolve) => {
                const mainCanvas = this.$refs.situationAutoCanvas;
                const mainCanvasImage = mainCanvas.getContext("2d").getImageData(0, 0, mainCanvas.width, mainCanvas.height);
                const allPromises = [];
                this.cartColors.forEach((color) => {
                    if (this.isColorNotInitialized(color)) {
                        allPromises.push(this.createColorMask(mainCanvas, mainCanvasImage, color));
                    }
                });

                Promise.all(allPromises.slice(0, 3))
                    .then(() => {
                        if (allPromises.length > 3) {
                            Promise.all(allPromises.slice(3, 6)).then(() => resolve());
                        } else {
                            resolve();
                        }
                    });
            });
        },
        initAmelib() {
            return new Promise((resolve) => {
                this.cartColors.forEach(async (color) => {
                    if (this.isColorNotInitialized(color)) {
                        this.createAmelibInstanceColor(color);
                    }
                });
                resolve();
            });
        },
        initHistory() {
            return this.snapshotForHistory();
        },
        initFillingMasks() {
            const promises = this.imageGallery.masks.map((path) => new Promise((resolve) => {
                const imageMask = new Image();
                imageMask.src = `/${path}`;
                imageMask.onload = () => {
                    resolve(imageMask);
                };
            }));
            return Promise.all(promises);
        },
        mergedMaskList() {
            if (Array.isArray(this.masksImage)) {
                this.mergedMasksImage = document.createElement("canvas");
                this.mergedMasksImage.width = this.photoCanvas.width;
                this.mergedMasksImage.height = this.photoCanvas.height;
                const maskCtx = this.mergedMasksImage.getContext("2d");
                this.masksImage.forEach((mask) => {
                    maskCtx.drawImage(mask, 0, 0, this.photoCanvas.width, this.photoCanvas.height);
                });
            }
        },
        isColorNotInitialized(color) {
            return color !== null && !Object.keys(this.coloredCanvasList).includes(color);
        },
        async createColorMask(canvas, canvasImage, color) {
            const canvasPhotoImage = this.photoCanvas.getContext("2d").getImageData(0, 0, this.photoCanvas.width, this.photoCanvas.height);

            // Mask Canvas
            const maskCanvas = document.createElement("canvas");
            maskCanvas.width = this.photoCanvas.width;
            maskCanvas.height = this.photoCanvas.height;

            this.maskColorCanvasList[color] = maskCanvas;

            // Paint Canvas
            const paintCanvas = document.createElement("canvas");
            paintCanvas.width = this.photoCanvas.width;
            paintCanvas.height = this.photoCanvas.height;
            paintCanvas.getContext("2d").putImageData(canvasPhotoImage, 0, 0);

            const colorHexa = this.colors[color].rgbhex;
            await paintinglib.colorizeFull(paintCanvas, [colorHexa], this._pictureLuminosityAverage);

            this.coloredCanvasList[color] = paintCanvas;
        },
        async createAmelibInstanceColor(color) {
            this.amelibInstances[color] = new Amelib(this.photoCanvas, this.maskColorCanvasList[color]);
            this.amelibInstances[color].brushShape = Amelib.BRUSH_SHAPE_CIRCLE;
            this.amelibInstances[color].brushSize = 32;
            this.amelibInstances[color].magicBrushThreshold = 8;
        },
        // Create an object of the masks list (ImageData) as value and color as key.
        // Then push it to the maskHistory array
        snapshotForHistory() {
            return new Promise((resolve) => {
                const imageDataCanvasList = {};
                Object.keys(this.maskColorCanvasList).forEach((key) => {
                    const canvas = this.maskColorCanvasList[key];
                    const ctx = canvas.getContext("2d", {
                        willReadFrequently: true,
                    });
                    imageDataCanvasList[key] = ctx.getImageData(0, 0, canvas.width, canvas.height);
                });
                // The + 1 is for original image to keep in history
                if (this.masksHistory.length >= this.historyLimit + 1) {
                    this.masksHistory.splice(1, 1);
                }
                this.masksHistory.push(imageDataCanvasList);
                resolve();
            });
        },
        showCanvasColorSelected() {
            showCanvas(this.maskColorCanvasList[this.selectedColor]);
        },
        showAllCanvasMasks() {
            Object.keys(this.maskColorCanvasList).forEach((color) => {
                showCanvas(this.maskColorCanvasList[color]);
            });
        },
        async colorize(event) {
            if ((event.type === "mousedown" || event.type === "touchstart") && this.isHighLight === true) {
                EventBus.$emit("disable-highlight");
                this.isHighLight = false;
                this.draw();
            }
            // >> This is a workaround to prevent white canvas when we click on the canvas the first time (double draw)
            if (event.type === "mousedown" || event.type === "touchstart") {
                this.draw();
            }
            // <<
            switch (this.tool) {
                case "FILL": {
                    switch (event.type) {
                        case "mousedown":
                        case "touchstart": {
                            if (this.selectedColor !== null) {
                                await this.snapshotForHistory();
                                this.fill(event);
                                this.draw();
                            }
                            break;
                        }
                        default:
                            break;
                    }
                    break;
                }
                case "ERASE": {
                    switch (event.type) {
                        case "mousedown":
                        case "touchstart": {
                            this.isDrawing = true;
                            await this.snapshotForHistory();

                            const canvas = this.$refs.situationAutoCanvas;
                            const coords = this.getCoordsFromCanvas(canvas, event);

                            Object.keys(this.amelibInstances).forEach((color) => {
                                this.amelibInstances[color].beginBrushstrokeXY(coords.x, coords.y);
                                this.amelibInstances[color].eraserXY(coords.x, coords.y);
                            });
                            this.draw();
                            break;
                        }
                        case "mousemove":
                        case "touchmove": {
                            if (this.isDrawing) {
                                const canvas = this.$refs.situationAutoCanvas;
                                const coords = this.getCoordsFromCanvas(canvas, event);

                                Object.keys(this.amelibInstances).forEach((color) => {
                                    this.amelibInstances[color].eraserXY(coords.x, coords.y);
                                });
                                this.draw(true);
                            }
                            break;
                        }
                        case "mouseup":
                        case "touchend": {
                            this.isDrawing = false;
                            break;
                        }
                        default:
                            break;
                    }
                    break;
                }
                case "PAINT": {
                    if (this.selectedColor !== null) {
                        switch (event.type) {
                            case "mousedown":
                            case "touchstart": {
                                this.isDrawing = true;
                                await this.snapshotForHistory();

                                const canvas = this.$refs.situationAutoCanvas;
                                const { x, y } = this.getCoordsFromCanvas(canvas, event);

                                this.amelib.beginBrushstrokeXY(x, y);
                                this.amelib.magicBrushXY(x, y);

                                this.draw();
                                break;
                            }
                            case "mousemove":
                            case "touchmove": {
                                if (this.isDrawing) {
                                    const canvas = this.$refs.situationAutoCanvas;
                                    const { x, y } = this.getCoordsFromCanvas(canvas, event);

                                    this.amelib.magicBrushXY(x, y);
                                    this.draw(true);
                                }
                                break;
                            }
                            case "mouseup":
                            case "touchend": {
                                this.isDrawing = false;

                                const canvasArea = this.getCanvasDiffBeforePaint();
                                this.cleanAreaBeforeFill(canvasArea, true);
                                const maskCtx = this.maskCanvas.getContext("2d");
                                maskCtx.drawImage(canvasArea, 0, 0);

                                this.draw();

                                break;
                            }
                            default:
                                break;
                        }
                    }
                    break;
                }
                default:
                    Sentry.captureMessage(`[VUE.JS] Unexpected tool : ${this.tool}`);

                    break;
            }
        },
        async fill(event) {
            const canvas = this.$refs.situationAutoCanvas;
            const coords = this.getCoordsFromCanvas(canvas, event);
            if (this.mode === "gallery") {
                const fillMask = paintinglib.getMask(canvas, coords, this.masksImage);

                if (fillMask) {
                    this.cleanAreaBeforeFill(fillMask.canvas);
                    const maskCtx = this.maskCanvas.getContext("2d");
                    maskCtx.drawImage(fillMask.canvas, 0, 0);
                }
            } else {
                let isOverMask = false;
                let canvasMaskFound;
                let colorFound;
                // We want to know if we just click on a mask from another color than selectedColor
                Object.keys(this.maskColorCanvasList).forEach((color) => {
                    const canvasMask = this.maskColorCanvasList[color];
                    const isMaskUnderResult = paintinglib.isMaskUnder(canvasMask, coords);
                    if (isMaskUnderResult) {
                        isOverMask = true;
                        canvasMaskFound = canvasMask;
                        colorFound = color;
                    }
                });
                if (isOverMask && colorFound !== this.selectedColor) {
                    // In that case we want to reuse the region of the mask used in other color and set it to our selectedColor
                    let zoneMaskDetected = paintinglib.detectInMask(canvasMaskFound, coords, 30, 0.66);
                    zoneMaskDetected = paintinglib.refineMask(zoneMaskDetected); // only to be sure
                    zoneMaskDetected = convertWBMaskToTransparent(zoneMaskDetected);

                    // we remove this region on old color mask
                    let oldMaskColorWithRegionDiffed = paintinglib.diffMasks(
                        this.maskColorCanvasList[colorFound],
                        zoneMaskDetected
                    );
                    oldMaskColorWithRegionDiffed = paintinglib.morphologicalOpeningCanvas(oldMaskColorWithRegionDiffed);
                    const colorFoundCtx = this.maskColorCanvasList[colorFound].getContext("2d");
                    colorFoundCtx.clearRect(0, 0, canvasMaskFound.width, canvasMaskFound.height);
                    colorFoundCtx.drawImage(oldMaskColorWithRegionDiffed, 0, 0);

                    // We add detected region on current color mask
                    let selectedColorMask = paintinglib.fuseMasks(
                        this.maskColorCanvasList[this.selectedColor],
                        zoneMaskDetected
                    );
                    selectedColorMask = convertWBMaskToTransparent(selectedColorMask); // mandatory
                    const selectedColorCtx = this.maskColorCanvasList[this.selectedColor].getContext("2d");
                    selectedColorCtx.drawImage(selectedColorMask, 0, 0);
                } else {
                    // Classic case
                    // set a working canvas to detect on original picture
                    const workCanvas = document.createElement("canvas");
                    const workCtx = workCanvas.getContext("2d");
                    workCanvas.width = this.maskCanvas.width;
                    workCanvas.height = this.maskCanvas.height;
                    workCtx.putImageData(this.originalPictureData, 0, 0);
                    const sensibility = 0.66;
                    const tolerance = 30;

                    // Detect region and eventually merge with current mask region
                    const newMaskDetected = paintinglib.detectRegionPerso(
                        coords,
                        workCanvas,
                        sensibility,
                        tolerance,
                        [this.maskCanvas]
                    );
                    workCtx.clearRect(0, 0, 0, 0);
                    const newTransparentMaskDetected = convertWBMaskToTransparent(newMaskDetected);

                    this.cleanAreaBeforeFill(newTransparentMaskDetected);
                    const maskCtx = this.maskCanvas.getContext("2d");
                    maskCtx.drawImage(newMaskDetected, 0, 0);
                }
            }
        },
        cleanAreaBeforeFill(canvasArea, skipMainColor = false) {
            Object.keys(this.maskColorCanvasList).forEach((color) => {
                const isMainColor = color === this.selectedColor;
                if (!(skipMainColor && isMainColor)) {
                    const canvasMask = this.maskColorCanvasList[color];
                    const canvasMaskCtx = canvasMask.getContext("2d");
                    canvasMaskCtx.globalCompositeOperation = "destination-out";
                    canvasMaskCtx.drawImage(canvasArea, 0, 0);
                    canvasMaskCtx.globalCompositeOperation = "source-over";
                }
            });
        },
        getCanvasDiffBeforePaint() {
            const previousMask = this.masksHistory.slice(-1)[0][this.selectedColor];
            const workCanvas = document.createElement("canvas");
            workCanvas.width = this.maskCanvas.width;
            workCanvas.height = this.maskCanvas.height;

            const workCtx = workCanvas.getContext("2d");
            workCtx.putImageData(previousMask, 0, 0);

            return paintinglib.diffMasks(workCanvas, this.maskCanvas);
        },
        draw(preventFastDrawing = false) {
            const currentDate = Date.now();
            if (preventFastDrawing && (currentDate - this.lastDate) < 60) {
                return;
            }
            this.lastDate = currentDate;
            const mainCanvas = this.$refs.situationAutoCanvas;
            // >> Unexpected error : Prevent undefined main canvas
            if (!mainCanvas) {
                return;
            }
            // <<
            const mainCtx = mainCanvas.getContext("2d");
            const workCtx = this.workCanvas.getContext("2d");
            mainCtx.drawImage(this.photoCanvas, 0, 0);

            Object.keys(this.maskColorCanvasList).forEach((color) => {
                const paintCanvas = this.coloredCanvasList[color];
                const maskCanvas = this.maskColorCanvasList[color];

                workCtx.clearRect(0, 0, mainCanvas.width, mainCanvas.height);
                workCtx.save();
                workCtx.globalCompositeOperation = "source-over";
                if (paintCanvas) {
                    workCtx.drawImage(paintCanvas, 0, 0);
                }

                workCtx.globalCompositeOperation = "destination-in";
                workCtx.drawImage(maskCanvas, 0, 0);
                workCtx.restore();

                mainCtx.drawImage(this.workCanvas, 0, 0);
            });
        },
        goBack() {
            if (!this.masksHistory) {
                return;
            }
            if (this.masksHistory.length > 0) {
                let masksImageData = {};
                if (this.masksHistory.length === 1) {
                    masksImageData = { ...this.masksHistory[0] };
                } else {
                    masksImageData = { ...this.masksHistory.pop() };
                }

                Object.keys(this.maskColorCanvasList).forEach((key) => {
                    const canvas = this.maskColorCanvasList[key];
                    const previousPicture = masksImageData[key];
                    canvas.getContext("2d").putImageData(previousPicture, 0, 0);
                });

                this.draw();
            }
        },
        getAugmentedCanvas() {
            const mainCanvas = this.$refs.situationAutoCanvas;

            // Get list of color used with color infos
            const colorListUsed = this.getColorListUsed();
            const colorList = [];
            colorListUsed.forEach((color) => {
                colorList.push({
                    name: this.colors[color].name,
                    rgbhex: this.colors[color].rgbhex,
                });
            });

            return paintinglib.helpers.createCanvasWithPaintList(colorList, mainCanvas);
        },
        download() {
            const aLink = document.createElement("a");
            let imageName = `${this._("Ma photo Leroy Merlin")}.png`;
            if (this.mode === "gallery") {
                imageName = `${this.imageGallery.title}.png`;
            }
            aLink.download = imageName;

            const augmentedCanvas = this.getAugmentedCanvas();
            aLink.href = augmentedCanvas.toDataURL();
            aLink.click();
        },
        toggleMaskHighlight() {
            if (this.isHighLight) {
                this.draw();
                this.isHighLight = false;
            } else if (!this.isDrawing) {
                const mainCanvas = this.$refs.situationAutoCanvas;
                // >> Unexpected error : Prevent undefined main canvas
                if (!mainCanvas) {
                    return;
                }
                // <<
                const mainCtx = mainCanvas.getContext("2d");
                this.masksImage.forEach((image, index) => {
                    const maskCanvas = document.createElement("canvas");
                    maskCanvas.width = mainCanvas.width;
                    maskCanvas.height = mainCanvas.height;

                    const maskCtx = maskCanvas.getContext("2d");
                    maskCtx.drawImage(image, 0, 0, maskCanvas.width, maskCanvas.height);

                    const amelib = new Amelib(this.photoCanvas, maskCanvas);
                    const highlightSettings = this.highlightDisplays[index];
                    const hlCanvas = amelib.generateMaskHighlight(
                        highlightSettings.color,
                        highlightSettings.lineWidth,
                        highlightSettings.usePattern,
                        highlightSettings.patternSize,
                        highlightSettings.patternAlpha,
                    );
                    mainCtx.drawImage(hlCanvas, 0, 0, mainCanvas.width, mainCanvas.height);
                });
                this.isHighLight = true;
            }
        },
        getCanvasImage() {
            return new Promise((resolve) => {
                this.$refs.situationAutoCanvas.toDataURL((blob) => {
                    resolve(blob);
                });
            });
        },
        getColorListUsed() {
            const colorListUsed = [];
            Object.keys(this.maskColorCanvasList).forEach((key) => {
                const canvas = this.maskColorCanvasList[key];
                const pixelData = canvas.getContext("2d").getImageData(0, 0, canvas.width, canvas.height);
                for (let i = 0, c = pixelData.data.length; i < c; i += 4) {
                    if (pixelData.data[i + 3] !== 0) {
                        colorListUsed.push(key);
                        break;
                    }
                }
            });
            return colorListUsed;
        },
        getCoordsFromCanvas(canvas, event) {
            let { x, y } = getCoordsFromCanvas(canvas, event);
            if (this.isMobile) {
                x *= 2;
                y *= 2;
            }
            return { x, y };
        },
        clearCanvas(canvas) {
            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, 0, 0);
        },
        clearObjectArray(array) {
            Object.keys(array).forEach((index) => {
                delete array[index];
            });
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.wrap {
  width: 100%;
}

.canvas-loader {
  transition: 0.3s;
  background: rgba(white, 0.8);
  width: 50%;
  height: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  justify-content: center;
  align-items: center;
  color: $color-grey-900;
  text-transform: uppercase;

  @include set-border-radius('m');

  display: none;

  &.is-loading {
    display: flex;
  }
}

</style>
