<template>
    <div class="loader">
        <img :src="loader">
    </div>
</template>

<script>
import loader from "../../../../../assets/images/icons/loader.svg";

export default {
    name: "Loader",
    data() {
        return {
            loader,
        };
    },
};
</script>

<style lang="scss">
.loader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 100px;
  }
}
</style>
