var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "legal-notices" }, [
    _c("h4", [_vm._v(_vm._s(_vm._("Mentions légales")))]),
    _vm._v(" "),
    _c("a", { attrs: { href: _vm.cguUrl, target: "_blank" } }, [
      _vm._v(_vm._s(_vm._("Crédits et mentions légales"))),
    ]),
    _vm._v(" "),
    _c("a", { attrs: { href: _vm.privacyUrl, target: "_blank" } }, [
      _vm._v(_vm._s(_vm._("Données personnelles"))),
    ]),
    _vm._v(" "),
    _c("a", { attrs: { href: "/CREDITS.txt", target: "_blank" } }, [
      _vm._v(_vm._s(_vm._("Licenses"))),
    ]),
    _vm._v(" "),
    _c("span", [
      _vm._v(
        "© " +
          _vm._s(_vm._("Mon Coach Déco Leroy Merlin")) +
          " " +
          _vm._s(new Date().getFullYear())
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }