var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.color
    ? _c(
        "div",
        {
          class: {
            "color-card": true,
            "with-interaction": _vm.canInteract,
          },
          attrs: { title: _vm._("Voir la fiche couleur") },
        },
        [
          _c(
            "div",
            {
              class: {
                color: true,
                selected: _vm.isColorSelected(),
              },
              style: {
                backgroundColor: _vm.color.rgbhex,
                borderColor: _vm.isColorLight(_vm.color) && "#bab6bc",
              },
              on: { click: _vm.onColorClick },
            },
            [
              !_vm.isColorSelected()
                ? _c("MIconControlCircleMore64", {
                    staticClass: "add",
                    style: {
                      fill: _vm.isColorLight(_vm.color) ? "black" : "white",
                    },
                  })
                : _c("MIconNotificationCircleAvailable64", {
                    style: {
                      fill: _vm.isColorLight(_vm.color) ? "black" : "white",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "font-size--01" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.getDisplayName(_vm.color)) + "\n    "
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }