<template>
    <div>
        <div>
            <h1>
                {{ _("Espace administration") }}
            </h1>

            <Loader />
        </div>
    </div>
</template>

<script>
import self from "../../../../index";

import Loader from "../../../components/loader/loader.vue";

const {
    modules,
} = self.app;

export default {
    name: "AdminStandBy",
    components: {
        Loader,
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
    },
};
</script>
