var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticClass: "main-card",
      attrs: { to: { name: _vm.link }, "data-cerberus": _vm.dataCerberus },
    },
    [
      _c("div", { staticClass: "main-card__cover" }, [
        _c("picture", [
          _c("source", {
            attrs: { srcset: _vm.coverWebp, media: "(max-width: 800px)" },
          }),
          _vm._v(" "),
          _c("img", { attrs: { src: _vm.coverImg, alt: "" } }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "main-card__content" }, [
        _c("h4", [_vm._v(" " + _vm._s(_vm._(_vm.title)))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm._(_vm.description)))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }