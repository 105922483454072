var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "colorize" }, [
    _c(
      "div",
      { staticClass: "colorize-picture" },
      [_c("PaintCanvas", { attrs: { tool: _vm.tool } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "colorize-info" }, [
      _c("div", { staticClass: "colorize-actions" }, [
        _c("p", [_vm._v(_vm._s(_vm._(_vm.toolMessage)))]),
        _vm._v(" "),
        _c("div", { staticClass: "colorize-actions__buttons" }, [
          _c(
            "button",
            {
              class: _vm.tool === "FILL" && "active",
              attrs: {
                title: _vm._(_vm.PAINTING_TOOL_FILL_MESSAGE),
                "data-cerberus": "remplissage",
              },
              on: {
                click: function ($event) {
                  return _vm.switchTool("FILL", $event)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: _vm.colorizeIcon, alt: _vm._("icon coloriser") },
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm._("Remplissage")))]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: _vm.tool === "PAINT" && "active",
              attrs: {
                title: _vm._(_vm.PAINTING_TOOL_PAINT_MESSAGE),
                "data-cerberus": "paint-tool",
              },
              on: {
                click: function ($event) {
                  return _vm.switchTool("PAINT", $event)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: _vm.penIcon, alt: _vm._("icon stylo") },
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm._("Pinceau")))]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: _vm.tool === "ERASE" && "active",
              attrs: {
                title: _vm._(_vm.PAINTING_TOOL_ERASE_MESSAGE),
                "data-cerberus": "erase-tool",
              },
              on: {
                click: function ($event) {
                  return _vm.switchTool("ERASE", $event)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: _vm.eraseIcon, alt: _vm._("icon effacer") },
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm._("Gomme")))]),
            ]
          ),
          _vm._v(" "),
          _vm.mode === "gallery"
            ? _c(
                "button",
                {
                  class: _vm.highlight && "active",
                  attrs: {
                    title: _vm._(_vm.PAINTING_TOOL_HIGHLIGHT_MESSAGE),
                    "data-cerberus": "zone-tool",
                  },
                  on: { click: _vm.highlightArea },
                },
                [
                  _c("img", {
                    attrs: { src: _vm.frameIcon, alt: _vm._("icon zone") },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm._("Zones")))]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: {
                title: _vm._(_vm.PAINTING_TOOL_BACK_MESSAGE),
                "data-cerberus": "back-tool",
              },
              on: { click: _vm.goBack },
            },
            [
              _c("img", {
                attrs: {
                  src: _vm.returnIcon,
                  alt: _vm._("icon retour arrière"),
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm._("Retour")))]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.mode === "gallery" && _vm.imageGallery.colors.length
        ? _c(
            "div",
            { staticClass: "suggested-colors" },
            [
              _c(
                "button",
                {
                  staticClass: "btn-liner",
                  attrs: { "data-cerberus": "style-suggestion" },
                  on: { click: _vm.onShowSuggestedColorsClick },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._(
                          "Afficher les couleurs suggérées par nos stylistes"
                        )
                      ) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("colorize-suggestion", {
                class: _vm.isModalOpen && "open",
                attrs: { "colors-object": _vm.imageGallery.colors },
                on: { "close-modal": _vm.closeModal },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "color-palette-wrapper" },
        [_c("color-palette", { attrs: { "open-by-default": "" } })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }