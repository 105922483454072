export default {

    name: "internationalization-utility",
    requires: ["stonejs"],

    load() {
        const InternationalizationController = require("./src/internationalization-controller").default;
        return {
            controller: new InternationalizationController(),
        };
    },

    unload() {},

};
