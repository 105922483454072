var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "admin-picture-container" },
    _vm._l(_vm.picturesForm, function (category) {
      return _c(
        "div",
        { key: category.categoryName },
        [
          _c("h4", [
            _vm._v(
              _vm._s(_vm._(_vm.PICTURE_CATEGORIES[category.categoryName]))
            ),
          ]),
          _vm._v(" "),
          _c(
            "draggable",
            {
              staticClass: "sort-pictures",
              attrs: { animation: 200, group: "rooms" },
              on: { change: _vm.updatePictures },
              model: {
                value: category.rooms,
                callback: function ($$v) {
                  _vm.$set(category, "rooms", $$v)
                },
                expression: "category.rooms",
              },
            },
            _vm._l(category.rooms, function (picture) {
              return _c("PictureCard", {
                key: picture.uuid,
                attrs: {
                  "is-generic": _vm.isFirstPicture(
                    picture.uuid,
                    category.categoryName
                  ),
                  picture: picture,
                  "category-name": category.categoryName,
                },
                on: { "edit-picture": _vm.editPicture },
              })
            }),
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }