<template>
    <router-link
        :to="{ name: 'color_show', params: { uid: uid } }"
        class="color-info"
    >
        <div class="color-info_palette">
            <div
                class="color-info_palette-color"
                :style="{backgroundColor: color.rgbhex, borderColor: isColorLight(color) && '#bab6bc'}"
            />

            <p class="color-info_palette-name">
                {{ getDisplayName(color) }}
            </p>
        </div>
        <button
            class="color-info_show"
        >
            <MIconDisplayView32 />
            <span>{{ _("Voir la fiche couleur") }}</span>
        </button>
    </router-link>
</template>

<script>
import { MIconDisplayView32 } from "@mozaic-ds/icons/vue/index.vue";
import self from "../../../index";
import { convertToRGB, getDisplayName, isLight } from "../../helpers/colorHelper";

const {
    modules,
} = self.app;

export default {
    name: "ColorInfo",
    components: {
        MIconDisplayView32,
    },
    props: {
        uid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            getDisplayName,
            color: {},
        };
    },
    computed: {
        colors() {
            return this.$store.getters["colors/colors"];
        },
    },
    created() {
        this.color = this.colors[this.uid];
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        isColorLight(color) {
            const rgb = convertToRGB(color.rgbhex);

            return isLight(rgb);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/Base/font.scss";

.color-info {
  text-decoration: none;
  margin-bottom: $mu050;
  display: flex;
  align-items: flex-start;

  &_palette {
    display: flex;
    flex-direction: column;
    align-items: start;

    &-color {
      width: 150px;
      height: 60px;
      border-radius: 5px;
    }

    &-name {
      @include set-font-scale('01');

      margin: 5px 0 0 0;
      color: $color-grey-800;
    }
  }

  &_show {
    display: flex;
    align-items: center;
    height: 60px;
    margin-left: $mu100;

    &:hover {
      cursor: pointer;
    }

    svg {
      margin-right: $mu050;
      height: 24px;
      width: 24px;
    }

    span {
      @include set-font-scale('03');
    }
  }
}
</style>
