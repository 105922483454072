var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "color-modal" }, [
    _c("div", { staticClass: "color-modal-wrapper" }, [
      _c(
        "div",
        { staticClass: "section-top" },
        _vm._l(_vm.selectedColors, function (uid) {
          return _c(
            "div",
            { key: uid, staticClass: "section-top__item" },
            [
              _c("color-card", {
                attrs: {
                  uid: uid,
                  color: _vm.colors[uid],
                  "show-color-link": "",
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "section-bottom" },
        [
          _c(
            "router-link",
            {
              staticClass: "section-bottom__item",
              attrs: { to: { name: "color_menu" } },
            },
            [
              _c("MIconInspiration32"),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm._("Tester ces couleurs sur une image"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "section-bottom__item",
              attrs: { to: { name: "shopping_cart" } },
              nativeOn: {
                click: function ($event) {
                  return _vm.onBuyColorsClick.apply(null, arguments)
                },
              },
            },
            [
              _c("MIconBasketStandard32"),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm._("Acheter ces couleurs")))]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "section-bottom__item no-cursor" },
            [
              _c("ButtonShare", {
                attrs: {
                  "in-palette": "",
                  mode: "SHARE_PALETTE",
                  label: _vm._("Partager mes couleurs"),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }