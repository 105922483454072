import Vue from "vue";
import Vuex from "vuex";
import CartDataModule from "./cartData";
import ImageModule from "./image";
import ImagesGalleryModule from "./imagesGallery";
import ColorsModule from "./colors";
import HistoryModule from "./history";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        cartData: CartDataModule,
        image: ImageModule,
        imagesGallery: ImagesGalleryModule,
        colors: ColorsModule,
        history: HistoryModule,
    },
});
