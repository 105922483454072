var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "situation-auto-room" },
    [
      _c("ButtonBack"),
      _vm._v(" "),
      _c("div", { staticClass: "situation-perso__header" }, [
        _c("p", [_vm._v(_vm._s(_vm._("Choisissez votre photo d'ambiance")))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cards-wrapper" },
        [
          _c(
            "div",
            _vm._l(_vm.pictures, function (picture, index) {
              return _c("card-style", {
                key: index,
                attrs: {
                  "data-cerberus": _vm.PICTURE_ATMOSPHERES[picture.atmosphere],
                  title: _vm._(_vm.PICTURE_ATMOSPHERES[picture.atmosphere]),
                  "title-prefix": _vm._(
                    _vm.PICTURE_ATMOSPHERES["PICTURE_ATMOSPHERE_PREFIX"]
                  ),
                  image: picture.image,
                },
                on: {
                  click: function ($event) {
                    return _vm.selectAtmosphere(picture)
                  },
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("Footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }