/**
 * history store module is storing navigation history :
 * - @property {Array} previousRoutes is an array of the previous navigation routes.
 */
export default {
    namespaced: true,
    state: {
        previousRoutes: [],
    },
    getters: {
        lastRoute(state) {
            const idx = state.previousRoutes.length - 2;
            if (idx >= 0) {
                return state.previousRoutes[idx];
            }

            return undefined;
        },
        currentRoute(state) {
            const idx = state.previousRoutes.length - 1;
            if (idx >= 0) {
                return state.previousRoutes[idx];
            }

            return undefined;
        },
    },
    mutations: {
        "PUSH_ROUTE"(state, route) {
            state.previousRoutes.push(route);
        },
        "GO_TO_LAST_ROUTE"(state) {
            state.previousRoutes.pop();
            state.previousRoutes.pop();
        },
    },
};
