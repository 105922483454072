var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shop", attrs: { id: "shop" } },
    [
      _c("div", { staticClass: "shop-header" }, [
        _c("div", [_c("ButtonBack")], 1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "shop-header__bottom" },
          [
            _c("p", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._("Ma liste de couleurs")) +
                  "\n                "
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectedColors.length > 0,
                      expression: "selectedColors.length > 0 ",
                    },
                  ],
                },
                [_vm._v("(" + _vm._s(_vm.selectedColors.length) + ")")]
              ),
            ]),
            _vm._v(" "),
            _c("ButtonShare", {
              attrs: { mode: "SHARE_PALETTE", label: _vm._("Partager") },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shop-body" }, [
        _vm.selectedColors.length > 0
          ? _c(
              "div",
              _vm._l(_vm.selectedColors, function (uid) {
                return _c(
                  "div",
                  { key: uid },
                  [
                    _c("ShoppingCartItem", {
                      attrs: { uid: uid, color: _vm.colors[uid] },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _c("div", { staticClass: "shop-body--empty" }, [
              _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm._(
                          "Vous n'avez pas encore de couleur dans votre liste"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "color_chart" } } },
                    [
                      _c("m-button", {
                        attrs: { label: _vm._("Ajouter une couleur") },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
      ]),
      _vm._v(" "),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }