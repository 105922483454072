<template>
    <div :class="containerClass">
        <div class="link-container">
            <button
                class="simple-link"
                @click="onShowMoreClick"
            >
                {{ _("En savoir plus sur l'aspect") }}
            </button>
        </div>

        <div :class="['aspect-info', {open: show}]">
            <p
                v-for="aspect in getColorAspects()"
                :key="aspect.name"
            >
                {{ aspect.name }} : {{ aspect.description }}
            </p>

            <button
                class="btn-close"
                @click="close"
            >
                <MIconControlCross24 />
            </button>
        </div>
    </div>
</template>

<script>
import { MIconControlCross24 } from "@mozaic-ds/icons/vue/index.vue";
import self from "../../../index";
import { sendEvent } from "../../services/marketing";

const {
    modules,
} = self.app;

export default {
    name: "AspectInfo",
    components: {
        MIconControlCross24,
    },
    props: {
        uid: {
            type: String,
            required: true,
        },
        containerClass: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            show: false,
        };
    },
    computed: {
        colors() {
            return this.$store.getters["colors/colors"];
        },
    },
    methods: {
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
        getColorAspects() {
            return this.$store.getters["colors/getColorAspects"](this.uid);
        },
        onShowMoreClick() {
            const color = this.colors[this.uid];
            this.show = !this.show;

            sendEvent("button.click", {
                button_name: "detail appearance",
                cdl_color_name: color.name,
            });
        },
        close() {
            this.show = false;
        },

    },
};

</script>

<style lang="scss">
@import "../../../../../style/index.scss";

.aspect-info {
  transition: 0.3s;
  padding: 0 $mu100;
  position: relative;
  height: 0;
  overflow: hidden;
  border: solid 1px transparent;

  @include set-border-radius("m");

  p {
    @include set-font-scale('04');
  }

  &.open {
    border-color: $color-grey-200;
    padding: $mu150 $mu100 $mu100;
    height: auto;
    max-height: 999px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: transparent;
    padding: $mu050;

    svg {
      width: 20px;
      height: 20px;
      fill: $color-grey-500;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

</style>
